import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ITenantUser from '../model/ITenantUser'

type UserState = {
  users: ITenantUser[]
  loadingUsers: boolean
  rowsPerPage: number
}

const initState: UserState = {
  users: new Array<ITenantUser>(),
  loadingUsers: false,
  rowsPerPage: 25
}

const usersSlice = createSlice({
  name: 'users',
  initialState: initState as UserState,
  reducers: {
    setLoadingUsers: (
      state,
      action: PayloadAction<{ loadingUsers: boolean }>
    ): UserState => {
      return {
        ...state,
        loadingUsers: action.payload.loadingUsers
      }
    },
    setUsers: (
      state,
      action: PayloadAction<{ users: ITenantUser[] }>
    ): UserState => {
      return {
        ...state,
        users: action.payload.users
      }
    },
    setUsersRowsPerPage: (
      state,
      action: PayloadAction<{ rowsPerPage: number }>
    ): UserState => {
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage
      }
    }
  }
})

export const {
  setLoadingUsers,
  setUsers,
  setUsersRowsPerPage
} = usersSlice.actions

export default usersSlice.reducer
