import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Snackbar, SnackbarContent } from '@material-ui/core'

const useStyles = makeStyles(theme =>
  createStyles({
    snackbar: {
      backgroundColor: theme.palette.ultimate.main,
      color: theme.palette.ultimate.contrastText
    }
  })
)

const AppNotificationSnack = ({ open, message }) => {
  const classes = useStyles()
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
    >
      <SnackbarContent className={classes.snackbar} message={message} />
    </Snackbar>
  )
}
AppNotificationSnack.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string
}

export default AppNotificationSnack
