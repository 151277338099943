import React, { useCallback, useEffect, useState } from 'react'
import { useTypedSelector } from '../../reducers'
import {
  deleteGroups,
  createGroup,
  updateGroup,
  loadGroups
} from '../../actions/groups'
import { useDispatch } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import SearchInput from '../SearchInput'
import { defineMessages, useIntl } from 'react-intl'
import RefreshButton from '../buttons/RefreshButton'
import DeleteButton from '../buttons/DeleteButton'
import DataTable from '../DataTable'
import IconButton from '../buttons/IconButton'
import { MikeSlidingPanel } from '@mike/mike-shared-frontend'
import { StateEnum } from '../../model/StateEnum'
import LongName from '../DataTable/renders/LongName'
import Renders from '../DataTable/renders'
import messages from '../../shared/messages'
import { css } from 'emotion'
import ConfirmationDialog from '../Dialog/ConfirmationDialog'
import { ReactComponent as Users } from '@mike/mike-shared-frontend/media/icons/Users'

import UserGroupForm from '../UserGroupForm/UserGroupForm'
import ITenantGroup from '../../model/ITenantGroup'

const localMessages = defineMessages({
  title: {
    id: 'screens.userGroups.title'
  },
  confirmationDialogTitle: {
    id: 'screens.userGroups.confirmationDialog.title'
  },
  confirmationDialogMessage: {
    id: 'screens.userGroups.confirmationDialog.message'
  },
  searchPlaceholder: {
    id: 'screens.userGroups.input.search.placeholder'
  },
  numberOfUsers: {
    id: 'screens.userGroups.numberOfUsers'
  },
  accessLevel: {
    id: 'screens.userGroups.accessLevel'
  }
})

const hoverStyle = () => {
  return css`
    &:hover {
      cursor: pointer;
    }
  `
}

const useStyles = makeStyles(theme => ({
  tableTopHeader: {
    position: 'sticky',
    top: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    zIndex: 1,
    backgroundColor: '#f2f5f7'
  },
  tableTopActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  leftActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  rightActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  nameRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  },
  groupRow: {
    paddingLeft: '20px'
  },
  dialogTitle: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  dialogContent: {
    maxWidth: 378,
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  }
}))

const FIRST_PAGE = 0

const UserGroups = props => {
  const tenantId = useTypedSelector(state => state.auth?.user?.tenantId) || ''

  const loadingGroups =
    useTypedSelector(state => state.groups.loadingState) === StateEnum.LOADING
  const dispatch = useDispatch()
  const intl = useIntl()
  const [searchText, setSearchText] = useState<string>('')
  const classes = useStyles(props)
  const [selectedRows, setSelectedRows] = useState<ITenantGroup[]>([])
  const [sortOrder, setSortOrder] = useState<string[]>(['asc'])
  const [sortOrderBy, setSortOrderBy] = useState<string[]>(['name'])
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(
    false
  )
  const [itemsToBeDeleted, setItemsToBeDeleted] = useState<ITenantGroup[]>([])
  const [currentEditingUserGroup, setCurrentEditingUserGroup] = useState<
    ITenantGroup
  >({
    name: '',
    id: ''
  })
  const [openGroupEditor, setOpenGroupEditor] = useState(false)

  const groups = useTypedSelector(state => state.groups.groups)

  useEffect(() => {
    if (tenantId) {
      dispatch(loadGroups(tenantId))
    }
  }, [dispatch, tenantId])

  const groupNameRender = (value, item: ITenantGroup) => {
    if (item && item.name) {
      return (
        <div className={classes.nameRow}>
          <LongName longName={value} />
        </div>
      )
    }
  }

  const numberofUserRender = (value, item: ITenantGroup) => {
    if (item && item.members) {
      return <div>{item.members.length}</div>
    }
  }

  useEffect(() => {
    dispatch(loadGroups(tenantId))
  }, [dispatch, tenantId])

  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height + 8)
    }
  }, [])

  const onRefresh = () => {
    dispatch(loadGroups(tenantId))
  }

  const onDelete = item => {
    setItemsToBeDeleted([item])
    setOpenDeleteConfirmation(true)
  }

  const onDeleteItems = () => {
    setItemsToBeDeleted(selectedRows)
    setOpenDeleteConfirmation(true)
  }

  const onColumnClick = column => {
    if (column && column.key) {
      const group = groups.find(item => item.name === column.name)
      group && setCurrentEditingUserGroup(group)
      setCurrentEditingUserGroup(column)
      setOpenGroupEditor(true)
    } else {
      setCurrentEditingUserGroup(column)
      setOpenGroupEditor(true)
    }
  }

  const onHandleRequestSort = (orderBy: string, order: string) => {
    setSortOrder([order])
    setSortOrderBy([orderBy])
  }

  const onOkDeleteGroups = () => {
    dispatch(deleteGroups(itemsToBeDeleted, tenantId))
    setSelectedRows([])
    setOpenDeleteConfirmation(false)
  }

  const onCancelDeleteGroups = () => {
    setOpenDeleteConfirmation(false)
  }

  return (
    <>
      <div className={classes.tableTopHeader} ref={measuredRef}>
        <Typography variant={'h4'}>
          {intl.formatMessage(localMessages.title)}
        </Typography>
        <div className={classes.tableTopActionButtons}>
          <div className={classes.leftActionButtons}>
            <SearchInput
              id="user-groups-search-input"
              placeholder={intl.formatMessage(localMessages.searchPlaceholder)}
              autoFocus
              text={searchText}
              onTextChange={setSearchText}
            />

            <RefreshButton onClick={onRefresh} />

            {selectedRows.length > 0 && (
              <DeleteButton onClick={onDeleteItems} />
            )}
          </div>
          <div className={classes.rightActionButtons}>
            <IconButton
              icon={<Users />}
              messageId="screens.userGroups.newGroup"
              onClick={() => {
                setCurrentEditingUserGroup({
                  name: '',
                  id: ''
                })
                setOpenGroupEditor(true)
              }}
            />
          </div>
        </div>
      </div>

      <DataTable
        actions={[
          {
            name: 'Edit',
            callBack: onColumnClick,
            render: Renders.renderEdit,
            disableGutters: true
          },
          {
            name: 'Delete',
            callBack: onDelete,
            render: Renders.renderDelete,
            disableGutters: true
          }
        ]}
        loading={loadingGroups}
        columns={[
          {
            field: 'name',
            label: intl.formatMessage(messages.name),
            render: groupNameRender,
            className: hoverStyle
          },
          {
            field: 'numberOfUsers',
            label: intl.formatMessage(localMessages.numberOfUsers),
            render: numberofUserRender,
            className: hoverStyle
          }
        ]}
        data={groups}
        idField={'id'}
        onColumnClick={onColumnClick}
        onHandleRequestSort={onHandleRequestSort}
        onSelectionChange={setSelectedRows}
        selectedRows={selectedRows}
        isSelected={(item: ITenantGroup) => {
          const found = selectedRows.find(row => {
            return row.id === item.id
          })
          return found ? true : false
        }}
        topOffset={topOffset}
        totalCount={groups.length}
        page={FIRST_PAGE}
        rowsPerPage={groups.length}
        _order={sortOrder}
        _orderBy={sortOrderBy}
      />

      <MikeSlidingPanel
        position="right"
        isOpen={openGroupEditor}
        onClose={() => {
          setOpenGroupEditor(false)
        }}
        titleArea={
          currentEditingUserGroup.name
            ? intl.formatMessage({
                id: 'screens.userGroups.updateGroup'
              })
            : intl.formatMessage({
                id: 'screens.userGroups.createGroup'
              })
        }
        contentArea={
          <UserGroupForm
            initialValues={currentEditingUserGroup}
            handleSubmit={(data: ITenantGroup) => {
              if (currentEditingUserGroup.name) {
                dispatch(updateGroup(tenantId, data))
              } else {
                dispatch(createGroup(tenantId, data))
              }
              setOpenGroupEditor(false)
            }}
          />
        }
        actionsArea={null}
        noGrayOverlay={false}
      />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        title={intl.formatMessage(localMessages.confirmationDialogTitle, {
          itemCount: itemsToBeDeleted.length
        })}
        message={intl.formatMessage(localMessages.confirmationDialogMessage, {
          itemCount: itemsToBeDeleted.length
        })}
        ok={intl.formatMessage(messages.yesDelete)}
        onOk={onOkDeleteGroups}
        onCancel={onCancelDeleteGroups}
      />
      <MikeSlidingPanel
        position="right"
        isOpen={openGroupEditor}
        onClose={() => {
          setOpenGroupEditor(false)
        }}
        titleArea={
          currentEditingUserGroup.name
            ? intl.formatMessage({
                id: 'screens.userGroups.updateGroup'
              })
            : intl.formatMessage({
                id: 'screens.userGroups.createGroup'
              })
        }
        contentArea={
          <UserGroupForm
            initialValues={currentEditingUserGroup}
            handleSubmit={(data: ITenantGroup) => {
              if (currentEditingUserGroup.name) {
                dispatch(updateGroup(tenantId, data))
              } else {
                dispatch(createGroup(tenantId, data))
              }
              setOpenGroupEditor(false)
            }}
          />
        }
        actionsArea={null}
        noGrayOverlay={false}
      />
    </>
  )
}

export default UserGroups
