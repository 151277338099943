import React, { useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import MikeButton from '@mike/mike-shared-frontend/mike-button'
import { ReactComponent as Plus } from '@mike/mike-shared-frontend/media/icons/Plus'
import TextField from '../formik/TextField'
import ProjectionSelect from '../formik/ProjectionSelect'
import IProjection from '../../model/IProjection'
import classNames from 'classnames'
import { useConversionStyles } from './styles/useConversionStyles'
import { iconStyle } from './styles/iconStyles'
import readerWriterTranslation from './translations/readerWriterTranslations'
import ContextHelpContainer from '../ContextHelp'
import parameterInputFieldTranslations from './translations/parameterInputFieldTranslations'
import DatasetXYZParameters from './DatasetXYZParameters'
import {
  SRID_PARAM,
  ZCOLUMNINDEX_PARAM,
  CONNECTCOLUMNINDEX_PARAM,
  XYZCOLUMNSSEQUENCE
} from '../../helpers/import'
import { IParameterErrors } from './DatasetConversion'
import { insertSpaces } from './support'

interface IParameter {
  name: string
  description: string
  dataType: string
  required: boolean
}

interface IProps {
  parameter: IParameter
  key: string
  disabled?: boolean
  reset: boolean
  handleReset: () => void
  parameterErrors?: IParameterErrors
  projections: IProjection[]
  projectionsLoading: boolean
  onProjectionSearchTextChanged: (searchText: string) => void
  searchCoordinateSystemsById: boolean
}

/**
 * @name ParameterInputField
 * @summary The paramter input field for reader specific input parameters.
 */
export const ParameterInputField = (props: IProps) => {
  const classes = useConversionStyles()
  const [expandSRID, setExpandSRID] = useState(false)
  const [expandNumericField, setExpandNumericField] = useState(false)
  const [expandParameter, setExpandParameter] = useState(false)

  const {
    parameter,
    disabled,
    reset,
    handleReset,
    parameterErrors,
    projections,
    projectionsLoading,
    onProjectionSearchTextChanged,
    searchCoordinateSystemsById
  } = props

  useEffect(() => {
    if (reset) {
      setExpandSRID(false)
      setExpandNumericField(false)
      setExpandParameter(false)
      handleReset()
    }
  }, [reset, handleReset])

  const formatParameterName = inputParameter => {
    const id = 'import.readers.inputParameters.' + inputParameter
    return readerWriterTranslation[id]
      ? readerWriterTranslation[id]
      : insertSpaces(inputParameter)
  }

  let inputField
  switch (parameter.dataType) {
    case 'int':
      inputField =
        parameter.name === CONNECTCOLUMNINDEX_PARAM ? (
          ''
        ) : parameter.name === SRID_PARAM ? (
          <Grid container alignItems="flex-end">
            <Grid item xs={12} key={parameter.name}>
              <Collapse
                in={parameter.required ? false : !expandSRID}
                timeout="auto"
                unmountOnExit
              >
                <Grid
                  container
                  alignItems="center"
                  className={classes.marginTop}
                >
                  <Grid item>
                    <MikeButton
                      onClick={() => {
                        !disabled && setExpandSRID(true)
                      }}
                      buttontype="text"
                      className={classNames(
                        classes.plusButton,
                        disabled && classes.plusButtonDisabled
                      )}
                    >
                      <Plus className={iconStyle(disabled)} />
                      {parameterInputFieldTranslations.coordinateSystem}
                    </MikeButton>
                  </Grid>
                  <Grid item>
                    <ContextHelpContainer
                      disabled={disabled}
                      helpTexts={[
                        parameter.description,
                        parameterInputFieldTranslations.coordinateSystemHelp
                      ]}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse
                in={parameter.required ? true : expandSRID}
                timeout="auto"
                unmountOnExit
              >
                <Grid
                  container
                  alignItems="flex-end"
                  className={classNames(classes.marginTop)}
                >
                  <Grid item xs={11}>
                    <ProjectionSelect
                      name={'coordinateSystemId'}
                      projections={projections}
                      projectionsLoading={projectionsLoading}
                      onProjectionSearchTextChanged={
                        onProjectionSearchTextChanged
                      }
                      searchById={searchCoordinateSystemsById}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <ContextHelpContainer
                      disabled={disabled}
                      helpTexts={[parameter.description]}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        ) : parameter.name === ZCOLUMNINDEX_PARAM ? (
          <Grid container alignItems="flex-end">
            <Grid item xs={12} className={classes.marginTop}>
              <DatasetXYZParameters
                name={XYZCOLUMNSSEQUENCE}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="flex-end">
            <Grid item xs={12} key={parameter.name}>
              <Collapse
                in={parameter.required ? false : !expandNumericField}
                timeout="auto"
                unmountOnExit
              >
                <Grid
                  container
                  alignItems="center"
                  className={classes.marginTop}
                >
                  <Grid item>
                    <MikeButton
                      onClick={() => {
                        !disabled && setExpandNumericField(true)
                      }}
                      buttontype="text"
                      className={classNames(
                        classes.plusButton,
                        disabled && classes.plusButtonDisabled
                      )}
                    >
                      <Plus className={iconStyle(disabled)} />
                      {formatParameterName(parameter.name)}
                    </MikeButton>
                  </Grid>
                  <Grid item>
                    <ContextHelpContainer
                      disabled={disabled}
                      helpTexts={[parameter.description]}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse
                in={parameter.required ? true : expandNumericField}
                timeout="auto"
                unmountOnExit
              >
                <Grid
                  container
                  alignItems="flex-end"
                  className={classes.marginTop}
                >
                  <Grid item xs={11}>
                    <TextField
                      name={`readerParameters.${parameter.name}`}
                      error={
                        parameterErrors &&
                        parameterErrors.readerParameters &&
                        parameterErrors.readerParameters.includes(
                          parameter.name
                        )
                      }
                      helperText={
                        parameterErrors &&
                        parameterErrors.readerParameters &&
                        parameterErrors.readerParameters.includes(
                          parameter.name
                        )
                          ? 'Please add a numeric value'
                          : ''
                      }
                      label={formatParameterName(parameter.name)}
                      type="number"
                      disabled={disabled}
                      className={classes.textField}
                      required={parameter.required}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <ContextHelpContainer
                      disabled={disabled}
                      helpTexts={[parameter.description]}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        )
      break
    default:
      inputField = (
        <Grid container alignItems="flex-end">
          <Grid item xs={11} key={parameter.name}>
            <Collapse
              in={parameter.required ? false : !expandParameter}
              timeout="auto"
              unmountOnExit
            >
              <Grid container alignItems="center" className={classes.marginTop}>
                <Grid item>
                  <MikeButton
                    onClick={() => {
                      !disabled && setExpandParameter(true)
                    }}
                    buttontype="text"
                    className={classNames(
                      classes.plusButton,
                      disabled && classes.plusButtonDisabled
                    )}
                  >
                    <Plus className={iconStyle(disabled)} />
                    {formatParameterName(parameter.name)}
                  </MikeButton>
                </Grid>
                <Grid item>
                  <ContextHelpContainer helpTexts={[parameter.description]} />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse
              in={parameter.required ? true : expandParameter}
              timeout="auto"
              unmountOnExit
            >
              <Grid
                container
                alignItems="flex-end"
                className={classes.marginTop}
              >
                <Grid item xs={11}>
                  <TextField
                    disabled={disabled}
                    error={
                      parameterErrors &&
                      parameterErrors.readerParameters &&
                      parameterErrors.readerParameters.includes(parameter.name)
                    }
                    fullWidth
                    helperText={
                      parameterErrors &&
                      parameterErrors.readerParameters &&
                      parameterErrors.readerParameters.includes(parameter.name)
                        ? 'Please add a value'
                        : ''
                    }
                    name={`readerParameters.${parameter.name}`}
                    label={formatParameterName(parameter.name)}
                    required={parameter.required}
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContextHelpContainer helpTexts={[parameter.description]} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      )
  }

  return inputField
}

export default ParameterInputField
