import React, { useCallback, useEffect, useState } from 'react'
import { css } from 'emotion'
import Typography from '@material-ui/core/Typography'
import theme from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import SiteInfoNav from '../SiteInfoNav'
import { useTypedSelector } from '../../reducers'
import { makeStyles } from '@material-ui/core'
import DataTable from '../DataTable'
import { useIntl } from 'react-intl'
import { StateEnum } from '../../model/StateEnum'
import { SiteStorageUsageState } from '../../reducers/siteStorageUsage'
import { useDispatch } from 'react-redux'
import { loadSiteStorageUsage } from '../../actions/siteStorageUsage'
import { MikeTooltip } from '@mike/mike-shared-frontend'
import LongName from '../DataTable/renders/LongName'
import ContextHelpContainer from '../ContextHelp'
import { loadProjectNames } from '../../actions/projectNames'

const paddingStyle = css`
  padding: ${theme.spacing(1)}px;
`

const useStyles = makeStyles(theme => ({
  tableTopHeader: {
    display: 'flex',
    flexDirectoin: 'row',
    position: 'sticky',
    top: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    zIndex: 1,
    backgroundColor: '#f2f5f7'
  }
}))

const hoverStyle = () => css`
  &:hover {
    cursor: pointer;
  }
`

const SiteStorageUsage = props => {
  const intl = useIntl()
  const classes = useStyles(props)
  const tenantId = useTypedSelector(state => state.auth?.user?.tenantId)
  const tenantName = useTypedSelector(state => state.auth?.user?.tenantName)
  const siteStorageUsage = useTypedSelector<SiteStorageUsageState>(
    state => state.siteStorageUsage as SiteStorageUsageState
  )
  const dispatch = useDispatch()
  const projectNames = useTypedSelector(state => state.projectNames)

  useEffect(() => {
    if (tenantId && siteStorageUsage.state === StateEnum.INIT) {
      dispatch(loadSiteStorageUsage(tenantId, siteStorageUsage.params))
    }
    if (tenantId && projectNames.state === StateEnum.INIT) {
      dispatch(loadProjectNames(tenantId))
    }
  }, [tenantId, siteStorageUsage, dispatch, projectNames.state])

  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height + 8)
    }
  }, [])

  const projectNameRender = value => {
    if (value === tenantId) {
      return <LongName longName={tenantName || ''} />
    }
    if (projectNames.projects[value]) {
      return <LongName longName={projectNames.projects[value]} />
    }
    return (
      <MikeTooltip
        title={intl.formatMessage({
          id: 'screens.siteStorageUsage.unknownProject'
        })}
      >
        <div>{value}</div>
      </MikeTooltip>
    )
  }

  const getResourceName = value => {
    switch (value) {
      case 'storage.azure.tiles.hot':
        return intl.formatMessage({
          id: 'screens.siteStorageUsage.type.tiles'
        })
      case 'storage.azure.multidimensional.hot':
        return intl.formatMessage({
          id: 'screens.siteStorageUsage.type.multidimensional'
        })
      case 'storage.azure.file.hot':
        return intl.formatMessage({
          id: 'screens.siteStorageUsage.type.file'
        })
      case 'storage.azure.timeseries.hot':
        return intl.formatMessage({
          id: 'screens.siteStorageUsage.type.timeseries'
        })
      case 'storage.azure.gisvectordata.hot':
        return intl.formatMessage({
          id: 'screens.siteStorageUsage.type.gisvectordata'
        })
      default:
        return intl.formatMessage(
          {
            id: 'screens.siteStorageUsage.type.unknown'
          },
          { value }
        )
    }
  }

  const resourceRender = value => {
    return <div>{getResourceName(value)}</div>
  }

  const projectMiBRender = value => {
    return (
      <MikeTooltip title={value / 1024}>
        <span>
          {new Intl.NumberFormat(navigator.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(value / 1024)}
        </span>
      </MikeTooltip>
    )
  }

  const onHandleRequestSort = (orderBy: string, sortOrder: string) => {
    dispatch(
      loadSiteStorageUsage(tenantId, {
        ...siteStorageUsage.params,
        page: 0,
        orderBy,
        sortOrder
      })
    )
  }

  const onChangePage = page => {
    dispatch(
      loadSiteStorageUsage(tenantId, {
        ...siteStorageUsage.params,
        page
      })
    )
  }

  const onChangeRowsPerPage = rowsPerPage => {
    dispatch(
      loadSiteStorageUsage(tenantId, {
        ...siteStorageUsage.params,
        page: Math.floor(
          (siteStorageUsage.params.page * siteStorageUsage.params.rowsPerPage) /
            rowsPerPage
        ),
        rowsPerPage
      })
    )
  }

  return (
    <>
      <SiteInfoNav />
      <div className={classes.tableTopHeader} ref={measuredRef}>
        <Typography className={paddingStyle} variant="h4" color="textPrimary">
          {intl.formatMessage({
            id: 'screens.siteStorageUsage.cloudStorageUsage'
          })}
        </Typography>
        <ContextHelpContainer
          helpTexts={[
            intl.formatMessage({
              id: 'screens.siteStorageUsage.updateIntervalInfo'
            })
          ]}
        />
      </div>

      <DataTable
        loading={siteStorageUsage.state === StateEnum.LOADING}
        columns={[
          {
            field: 'projectId',
            label: intl.formatMessage({ id: 'common.project' }),
            render: projectNameRender,
            className: hoverStyle
          },
          {
            field: 'resourceId',
            label: intl.formatMessage({
              id: 'screens.siteStorageUsage.resource'
            }),
            render: resourceRender,
            className: hoverStyle
          },
          {
            field: 'value',
            label: 'MiB',
            render: projectMiBRender,
            className: hoverStyle
          }
        ]}
        notSortableColumns={['projectId', 'resourceId']}
        idField={item =>
          `${item.projectId}-${item.resourceId}-${item.validFrom}`
        }
        data={siteStorageUsage.storageUsage}
        onHandleRequestSort={onHandleRequestSort}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        hasSelection={false}
        topOffset={topOffset}
        totalCount={siteStorageUsage.totalCount}
        hasMorePages={true}
        page={siteStorageUsage.params.page}
        rowsPerPage={siteStorageUsage.params.rowsPerPage}
        _order={[siteStorageUsage.params.sortOrder]}
        _orderBy={[siteStorageUsage.params.orderBy]}
      />
    </>
  )
}

export default SiteStorageUsage
