import { SiteStorageUsageQueryParams } from '../reducers/siteStorageUsage'

export const LOAD_SITE_STORAGE_USAGE = 'STORAGE-USAGE/LOAD'
export type LoadSiteStorageUsageType = {
  type: string
  tenantId: string
  params: SiteStorageUsageQueryParams
}
export const loadSiteStorageUsage = (tenantId, params) =>
  ({
    type: LOAD_SITE_STORAGE_USAGE,
    tenantId,
    params
  } as LoadSiteStorageUsageType)
