import find from 'lodash/fp/find'

import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  select
} from 'redux-saga/effects'

import {
  getRecycleBinItemsDeletedByMe as getRecycleBinItemsDeletedByMeState,
  getRecycleBinItemsDeletedByMeOffset,
  getRecycleBinItemsDeletedByMeLimit,
  getRecycleBinItemsDeletedByMeSortBy,
  getRecycleBinItemsDeletedByMeSortOrder
} from '../state'

import {
  restoreRecycleBinProject,
  restoreRecycleBinDataset,
  getRecycleBinItemsDeletedByMe
} from '../apis/recycleBin'

import { getProjectPath } from '../apis/project'

import {
  LOAD_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
  loadingRecycleBinItemsDeletedByMe,
  setRecycleBinItemsDeletedByMe,
  restoringRecycleBinItemsDeletedByMe,
  RESTORE_RECYCLE_BIN_ITEMS_DELETED_BY_ME
} from '../actions/recycleBin'

import { addError } from '../actions/errors'

import { notify } from '../helpers/saga'

import { PROJECT_TYPE } from '../shared/constants'

export default function* watchRecycleBin() {
  yield takeEvery(
    RESTORE_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
    restoreRecycleBinItemsDeletedByMe
  )

  yield takeLatest(
    LOAD_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
    loadRecycleBinItemsDeletedByMe
  )
}

function* loadRecycleBinItemsDeletedByMe(action) {
  try {
    yield put(loadingRecycleBinItemsDeletedByMe())

    // The endpoint supports server side pagination and sorting
    // Server side filtering might be added later
    const { data, totalCount, limit, offset } = yield call(
      getRecycleBinItemsDeletedByMe,
      action.offset,
      action.limit,
      action.sortBy,
      action.sortOrder
    )

    // To get the item path we need to call another endpoint for each item
    const projectIds = data.map(item => {
      return item.itemType === PROJECT_TYPE ? item.id : item.projectId
    })
    const responses = yield all(
      projectIds.map(projectId => call(getProjectPath, projectId))
    )
    data.map((item, index) => {
      return (item.path = responses[index])
    })
    // Datasets can only be restored when containing project.capabilites.canDeleteContent
    // (Sub)Projects) can only  be restored when project.capabilites.canDelete
    // https://dhigroup.visualstudio.com/MIKE/_git/mike-platform?path=%2FDocumentation%2Fdocs%2FAPI%2FRecycle-bin.md&_a=preview&anchor=restoring-data
    data.map(item => {
      return item.itemType === PROJECT_TYPE
        ? (item.canRestore =
            item.path[item.path.length - 1].capabilities.canDelete)
        : (item.canRestore =
            item.path[item.path.length - 1].capabilities.canDeleteContent)
    })

    yield put(
      setRecycleBinItemsDeletedByMe({ data, totalCount, limit, offset })
    )
  } catch (error) {
    yield put(addError(LOAD_RECYCLE_BIN_ITEMS_DELETED_BY_ME, error))
  } finally {
    yield put(loadingRecycleBinItemsDeletedByMe(false))
  }
}

function* restoreRecycleBinItemsDeletedByMe(action) {
  const { ids } = action
  const offset = yield select(getRecycleBinItemsDeletedByMeOffset)
  const limit = yield select(getRecycleBinItemsDeletedByMeLimit)
  const sortBy = yield select(getRecycleBinItemsDeletedByMeSortBy)
  const sortOrder = yield select(getRecycleBinItemsDeletedByMeSortOrder)

  try {
    yield put(restoringRecycleBinItemsDeletedByMe())

    yield all(ids.map(id => call(restoreItemDeletedByMe, id)))

    yield put(restoringRecycleBinItemsDeletedByMe(false))

    yield call(
      notify,
      ids.length > 1
        ? `${ids.length} items were restored!`
        : 'The item was restored!'
    )
  } catch (error) {
    yield put(addError(RESTORE_RECYCLE_BIN_ITEMS_DELETED_BY_ME, error))
    yield put(restoringRecycleBinItemsDeletedByMe(false))
  } finally {
    yield call(loadRecycleBinItemsDeletedByMe, {
      offset,
      limit,
      sortBy,
      sortOrder
    })
  }
}

function* restoreItemDeletedByMe(id) {
  const data = yield select(getRecycleBinItemsDeletedByMeState)
  const item = find({ id })(data)

  if (item.itemType === PROJECT_TYPE) {
    yield call(restoreRecycleBinProject, id)
  } else {
    yield call(restoreRecycleBinDataset, id)
  }
}
