import { call, put, takeLatest } from 'redux-saga/effects'

import { LOAD_APPS, loadingApps, setApps } from '../actions/applications'

import { addError } from '../actions/errors'
import { MikeCloudApps } from '../apis/cloudApps'

export default function* watchFetchApps() {
  yield takeLatest(LOAD_APPS, fetchAllApps)
}

export function* fetchAllApps() {
  try {
    yield put(loadingApps())

    const response = yield call(MikeCloudApps.getAllApplications)

    if (response) {
      yield put(setApps(response))
    }
  } catch (error) {
    yield put(addError(LOAD_APPS, error))
  } finally {
    yield put(loadingApps(false))
  }
}
