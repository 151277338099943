export const DATASETTYPE = 'datasetType'
export const DATASETFORMAT = 'datasetFormat'

export const isDataset = item => {
  return DATASETFORMAT in item
}

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}
