import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { injectIntl, defineMessages } from 'react-intl'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AppPropTypes from '../../shared/appPropTypes'
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { ReactComponent as Folder } from '@mike/mike-shared-frontend/media/icons/Folder'
import { css } from 'emotion'

const localMessages = defineMessages({
  createSubprojectButton: {
    id: 'createProjectForm.createSubprojectButton'
  }
})

const styles = () => ({
  root: {
    textTransform: 'none'
  },
  button: {
    padding: 0,
    paddingRight: 12,
    width: 150,
    border: '2px solid ' + mikePalette.secondary.main,
    color: mikePalette.secondary.main,
    '&[disabled]': {
      color: mikePalette.secondary.light,
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  }
})

const iconStyle = css`
  path {
    fill: ${mikePalette.secondary.main};
  }
`

const CreateSubprojectButton = ({ classes, intl, size, ...buttonProps }) => (
  <Button className={classes.button} size={size} {...buttonProps}>
    <Folder className={iconStyle} />
    {intl.formatMessage(localMessages.createSubprojectButton)}
  </Button>
)
CreateSubprojectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  size: AppPropTypes.mduiSizes,
  intl: PropTypes.object.isRequired
}

const enhance = compose(injectIntl, withStyles(styles))

export default enhance(CreateSubprojectButton)
