import {
  LOADING_ADMIN_CREDITS,
  SET_ADMIN_CREDITS,
  SET_ADMIN_USERROLE,
  SET_BACKOFFICE_USERROLE,
  SET_ROOT_CONTRIBUTOR_USERROLE
} from '../actions/admin'

export enum userRole {
  ADMIN = 'Admin',
  OWNER = 'Owner',
  CONTRIBUTOR = 'Contributor',
  READER = 'Reader'
}

const initState = {
  credits: 0.0,
  loadingCredits: false,
  isAdmin: false,
  isBackOffice: false,
  isRootContributor: false
}

const admin = (state = initState, action) => {
  switch (action.type) {
    case LOADING_ADMIN_CREDITS:
      return { ...state, loadingCredits: action.loadingCredits }
    case SET_ADMIN_CREDITS:
      return { ...state, credits: action.credits }
    case SET_ADMIN_USERROLE:
      return { ...state, isAdmin: action.isAdmin }
    case SET_ROOT_CONTRIBUTOR_USERROLE:
      return { ...state, isRootContributor: action.isRootContributor }
    case SET_BACKOFFICE_USERROLE:
      return { ...state, isBackOffice: action.isBackOffice }
    default:
      return state
  }
}

export default admin
