const uploadManyDialogTranslations = {
  message:
    'Files will be uploaded without any conversion (as-is). In order to convert files, select only one file at a time when uploading.',
  additionalMessageForZippedFiles:
    'Uploading a .zip file as-is, only serves as storage, you will not be able to change or convert the file at a later stage.',
  cancel: 'Cancel',
  ok: 'Upload',
  titlePart1: 'Upload',
  titlePart2: 'selected files'
}

export default uploadManyDialogTranslations
