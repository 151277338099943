import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IConsumptionEntry } from '../model/IConsumptionEntry'
import { StateEnum } from '../model/StateEnum'

export type SiteStorageUsageQueryParams = {
  page: number
  rowsPerPage: number
  sortOrder: string
  orderBy: string
}

export type SiteStorageUsageState = {
  storageUsage: IConsumptionEntry[]
  params: SiteStorageUsageQueryParams
  totalCount: number
  state: StateEnum
}

const initState = {
  storageUsage: [],
  params: {
    page: 0,
    rowsPerPage: 10,
    sortOrder: 'desc',
    orderBy: 'value'
  },
  totalCount: -1,
  state: StateEnum.INIT
} as SiteStorageUsageState

const siteStorageUsageSlice = createSlice({
  name: 'siteStorageUsage',
  initialState: initState as SiteStorageUsageState,
  reducers: {
    setStorageUsageLoadingState: (
      state,
      action: PayloadAction<{ loadingState: boolean }>
    ): SiteStorageUsageState => {
      return {
        ...state,
        state: action.payload.loadingState
          ? StateEnum.LOADING
          : StateEnum.LOADED
      }
    },
    setTotalCount: (state, action: PayloadAction<{ totalCount: number }>) => {
      return {
        ...state,
        totalCount: action.payload.totalCount
      }
    },
    setCpuQueryParams: (
      state,
      action: PayloadAction<SiteStorageUsageQueryParams>
    ) => {
      return {
        ...state,
        params: action.payload
      }
    },
    setStorageUsageData: (
      state,
      action: PayloadAction<IConsumptionEntry[]>
    ) => {
      return {
        ...state,
        storageUsage: action.payload
      }
    },
    setStorageUsageState: (
      state,
      action: PayloadAction<SiteStorageUsageState>
    ): SiteStorageUsageState => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export const {
  setStorageUsageLoadingState,
  setStorageUsageState,
  setTotalCount,
  setCpuQueryParams,
  setStorageUsageData
} = siteStorageUsageSlice.actions

export default siteStorageUsageSlice.reducer
