import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useIntl, defineMessages } from 'react-intl'
import Button from '@material-ui/core/Button'
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { ReactComponent as Upload } from '@mike/mike-shared-frontend/media/icons/Upload'
import { css } from 'emotion'

interface IProps {
  /**
   * File select callback.
   */
  onFileSelect: () => void
  /**
   * Disabled style.
   */
  disabled: boolean
}

const localMessages = defineMessages({
  uploadContentButton: {
    id: 'projectContent.uploadContentButton'
  }
})

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    button: {
      padding: 0,
      paddingRight: theme.spacing(1.5),
      width: 150,
      border: '2px solid ' + theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      cursor: 'pointer'
    },
    buttonDisabled: {
      padding: 0,
      paddingRight: theme.spacing(1.5),
      width: 150,
      border: '2px solid ' + theme.palette.secondary.main,
      color: theme.palette.secondary.light,
      opacity: 0.5,
      cursor: 'not-allowed'
    },
    input: {
      display: 'none'
    }
  })
})

const iconStyle = (disabled: boolean) => {
  const color = disabled
    ? mikePalette.secondary.light
    : mikePalette.secondary.main
  return css`
    path {
      fill: ${color};
    }
  `
}

const disabledButton = (classes, intl) => (
  <Button className={classes.buttonDisabled}>
    <Upload className={iconStyle(true)} />
    {intl.formatMessage(localMessages.uploadContentButton)}
  </Button>
)

const enabledButton = (classes, intl, onFileSelect) => (
  <>
    <input
      className={classes.input}
      id="upload-file-button"
      type="file"
      multiple
      onChange={onFileSelect}
    />

    <label htmlFor="upload-file-button">
      <Button className={classes.button} component="div" fullWidth>
        <Upload className={iconStyle(false)} />
        {intl.formatMessage(localMessages.uploadContentButton)}
      </Button>
    </label>
  </>
)

const FileChooserButton = (props: IProps) => {
  const { disabled, onFileSelect } = props
  const intl = useIntl()
  const classes = useStyles()

  return disabled
    ? disabledButton(classes, intl)
    : enabledButton(classes, intl, onFileSelect)
}

export default FileChooserButton
