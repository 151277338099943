import React from 'react'
import PropTypes from 'prop-types'
import { compose, withProps, withPropsOnChange } from 'recompose'
import { injectIntl } from 'react-intl'
import map from 'lodash/fp/map'

import messages from '../../shared/messages'

import DataTable, { columnPropType } from '../DataTable'
import Renders from '../DataTable/renders'
import DateTime from '../DataTable/renders/DateTime'

import { ReactComponent as DataSet } from '@mike/mike-shared-frontend/media/icons/DataSet'
import { ReactComponent as Folder } from '@mike/mike-shared-frontend/media/icons/Folder'
import { ReactComponent as Project } from '@mike/mike-shared-frontend/media/icons/Project'
import Text from '../Text'

import { PROJECT_TYPE, FOLDER_TYPE, DATASET_TYPE } from '../../shared/constants'

const RecycleBinItemsDeletedByMeTable = ({
  _columns,
  data,
  loading,
  selection,
  totalCount,
  onChangePage,
  onChangeRowsPerPage,
  onHandleRequestSort,
  onRestoreOneItem,
  onSelectionChange,
  page,
  topOffset
}) => (
  <DataTable
    actions={[
      {
        name: 'Restore',
        callBack: onRestoreOneItem,
        render: Renders.renderPath,
        disableGutters: true
      }
    ]}
    loading={loading}
    columns={_columns}
    idField="id"
    data={data}
    notSortableColumns={['datasetType', 'sizeKB']}
    selectedRows={selection}
    totalCount={totalCount}
    onChangePage={onChangePage}
    onChangeRowsPerPage={onChangeRowsPerPage}
    onHandleRequestSort={onHandleRequestSort}
    onSelectionChange={onSelectionChange}
    page={page}
    rowsPerPage={10}
    topOffset={topOffset}
  />
)
RecycleBinItemsDeletedByMeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchText: PropTypes.string,
  loading: PropTypes.bool,
  selection: PropTypes.arrayOf(PropTypes.object),
  totalCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onHandleRequestSort: PropTypes.func.isRequired,
  onRestoreOneItem: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  _columns: PropTypes.arrayOf(columnPropType).isRequired,
  page: PropTypes.number.isRequired,
  topOffset: PropTypes.number
}

export const isProject = item => item.$$type === PROJECT_TYPE
export const isFolder = item => item.$$type === FOLDER_TYPE
export const isDataset = item => item.$$type === DATASET_TYPE

export const canRestoreItem = item => item.canRestore

export const enhanceProjects = map(p =>
  p.path.length > 1
    ? { ...p, $$type: FOLDER_TYPE }
    : { ...p, $$type: PROJECT_TYPE }
)
const enhanceDatasets = map(p => ({ ...p, $$type: DATASET_TYPE }))

const nameRender = (name, item) => (
  <Text icon={isDataset(item) ? DataSet : isProject(item) ? Project : Folder}>
    {name}
  </Text>
)

const typeRender = (datasetType, item) =>
  isDataset(item)
    ? `Dataset / ${datasetType}`
    : isProject(item)
    ? 'Project'
    : 'Folder'

/* const typeComparator = (_, __, A, B) => {
  const aProj = isProject(A)
  const bProj = isProject(B)
  return aProj && !bProj ? 1 : !aProj && bProj ? -1 : 0
} */

const dateTimeRender = value => <DateTime jsonTime={value} />

const enhance = compose(
  injectIntl,

  withProps(({ intl }) => ({
    _columns: [
      {
        field: 'name',
        label: intl.formatMessage(messages.name),
        render: nameRender
      },
      {
        field: 'datasetType',
        label: intl.formatMessage(messages.type),
        render: typeRender
        // comparator: typeComparator
      },
      {
        field: 'sizeKB',
        label: intl.formatMessage(messages.size),
        render: Renders.size(1024)
      },
      {
        field: 'deletedAt',
        label: intl.formatMessage(messages.deletedAt),
        render: dateTimeRender
      }
    ]
  })),

  withPropsOnChange(['content', 'searchText'], props => {
    const { content } = props

    const projects = content.filter(item => item.itemType === PROJECT_TYPE)
    const datasets = content.filter(item => item.itemType === DATASET_TYPE)

    const data = [...enhanceProjects(projects), ...enhanceDatasets(datasets)]

    return {
      data
    }
  })
)

export default enhance(RecycleBinItemsDeletedByMeTable)
