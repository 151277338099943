import identity from 'lodash/identity'

export const SET_PROJECTS_SEARCHTEXT = 'PROJECTS/SET_SEARCHTEXT'
export const setProjectsSearchText = text => ({
  type: SET_PROJECTS_SEARCHTEXT,
  text
})

export const SET_PROJECTS_PAGINATION = 'PROJECTS/SET_PAGINATION'
export const setProjectsPagination = page => ({
  type: SET_PROJECTS_PAGINATION,
  page
})

export const SET_PROJECTS_ROWSPERPAGE = 'PROJECTS/SET_ROWSPERPAGE'
export const setProjectsRowsPerPage = rowsPerPage => ({
  type: SET_PROJECTS_ROWSPERPAGE,
  rowsPerPage
})

export const SET_PROJECTS_NAMEPREFIX = 'PROJECTS/SET_NAMEPREFIX'
export const setProjectsNamePrefix = namePrefix => ({
  type: SET_PROJECTS_NAMEPREFIX,
  namePrefix
})

export const DELETE_PROJECTS = 'PROJECTS/DELETE'
export const deleteProjects = (ids, projectsDidDelete = () => {}) => ({
  type: DELETE_PROJECTS,
  ids,
  projectsDidDelete
})

export const DELETING_PROJECTS = 'PROJECTS/DELETING'
export const deletingProjects = (deleting = true) => ({
  type: DELETING_PROJECTS,
  deleting
})

export const UPDATE_PROJECT = 'PROJECTS/UPDATE'
export const updateProject = (data, projectDidUpdate = identity) => ({
  type: UPDATE_PROJECT,
  data,
  projectDidUpdate
})

export const UPDATING_PROJECT = 'PROJECTS/UPDATING'
export const updatingProject = (updating = true) => ({
  type: UPDATING_PROJECT,
  updating
})

export const CREATE_PROJECT = 'PROJECTS/CREATE'
export const createProject = (data, projectDidCreate = identity) => ({
  type: CREATE_PROJECT,
  data,
  projectDidCreate
})

export const CREATE_SUBPROJECT = 'PROJECTS/CREATEFOLDER'
export const createNewFolder = (data, projectDidCreate = identity) => ({
  type: CREATE_SUBPROJECT,
  data,
  projectDidCreate
})

export const CREATING_PROJECT = 'PROJECTS/CREATING'
export const creatingProject = (creating = true) => ({
  type: CREATING_PROJECT,
  creating
})

export const LOAD_PROJECTS = 'PROJECTS/LOAD'
export const loadProjects = (
  my = false,
  page = 0,
  rowsPerPage = 25,
  sortBy = 'UpdatedAt',
  sortOrder = 'Desc',
  namePrefix = ''
) => ({
  type: LOAD_PROJECTS,
  my,
  page,
  rowsPerPage,
  sortBy,
  sortOrder,
  namePrefix
})

export const LOADING_PROJECTS = 'PROJECTS/LOADING'
export const loadingProjects = (my = false, loading = true) => ({
  type: LOADING_PROJECTS,
  my,
  loading
})

export const SET_PROJECTS = 'PROJECTS/SET'
export const setProjects = projects => ({ type: SET_PROJECTS, projects })
