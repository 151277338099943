/* eslint-disable no-console */

import { delay, call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_EXPORT_INFO_DELAY,
  EXPORT_REMOVE_ON_DONE_DELAY
} from '../shared/constants'

import {
  TRANSFER_STATUSES,
  getTransfer,
  transferDownloadConvert
} from '../apis/transfer'
//import { exportProjectDataset } from '../apis/project'
import { mapUserDownloadDataToConvertApiBody } from '../helpers/import'

import {
  EXPORT_DATASET,
  exportStart,
  updateExportProgress,
  exportComplete,
  exportFail,
  removeExport
} from '../actions/exports'

const LOG_TAG = 'saga.exports'

export default function* watchExports() {
  yield takeEvery(EXPORT_DATASET, exportFile)
}

export function* exportFile(action) {
  const { id, datasetId, data, fileName } = action
  const title = fileName ? 'Downloading (' + fileName + ')' : ''
  try {
    yield put(exportStart(id, title))

    const result = yield call(
      transferDownloadConvert,
      datasetId,
      mapUserDownloadDataToConvertApiBody(data)
    )

    let info
    while (true) {
      info = yield call(getTransfer, result.id)

      if (info.status === TRANSFER_STATUSES.IN_PROGRESS) {
        yield put(updateExportProgress(id, 50, title))
      } else if (info.status === TRANSFER_STATUSES.ERROR) {
        throw new Error(info.errorMessage)
      } else if (info.status === TRANSFER_STATUSES.COMPLETED) {
        break
      }

      yield delay(GET_EXPORT_INFO_DELAY)
    }

    console.log(LOG_TAG, id, 'Export completed!')

    yield put(exportComplete(id))
    yield delay(EXPORT_REMOVE_ON_DONE_DELAY)
    yield put(removeExport(id))

    yield call(window.open, info.downloadPath)
  } catch (error) {
    console.error(LOG_TAG, id, 'Export failed!', error)
    yield put(exportFail(id, error))
  }
}
