export const mockDedicatedReaders = [
  {
    writers: ['GISWriter', 'ShpWriter', 'GeoJsonWriter'],
    name: 'GISReader',
    description: 'Reads data from GIS storage',
    datasetFormat: 'GIS',
    parameters: []
  },
  {
    writers: ['TimeSeriesCsvWriter', 'TimeSeriesDfs0Writer'],
    name: 'TimeSeriesReader',
    description: 'Reads data from time series storage',
    datasetFormat: 'TsReader',
    parameters: []
  },
  {
    writers: ['NetcdfWriter'],
    name: 'MDReader',
    description: 'Reads data from multidimensional storage',
    datasetFormat: 'MD',
    parameters: []
  }
]
