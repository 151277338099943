import produce from 'immer'
import find from 'lodash/fp/find'
import findIndex from 'lodash/fp/findIndex'

import {
  EXPORT_DATASET,
  EXPORT_START,
  UPDATE_EXPORT_PROGRESS,
  EXPORT_COMPLETE,
  EXPORT_FAIL,
  REMOVE_EXPORT
} from '../actions/exports'

import { EXPORT_STATES } from '../helpers/export'

export default produce((draft, action) => {
  const { type, ...payload } = action

  const exportItem = findExport(payload.id)(draft)

  switch (type) {
    case EXPORT_DATASET:
      draft.push({
        ...payload,
        state: EXPORT_STATES.PENDING
      })
      return

    case EXPORT_START:
      exportItem.state = EXPORT_STATES.IN_PROGRESS
      exportItem.progress = 0
      return

    case UPDATE_EXPORT_PROGRESS:
      exportItem.progress = payload.progress
      return

    case EXPORT_COMPLETE:
      exportItem.state = EXPORT_STATES.DONE
      return

    case EXPORT_FAIL:
      exportItem.state = EXPORT_STATES.FAILED
      exportItem.error = payload.error
      return

    case REMOVE_EXPORT:
      removeExport(action.id)(draft)
      return

    default:
      return
  }
}, [])

const findExport = id => find({ id })
const removeExport = id => draft => draft.splice(findIndex({ id })(draft), 1)
