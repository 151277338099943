import { postConsumptionRequest } from './support'
import {
  getCurrentConsumptionLogUrl,
  getSASToken,
  SASTokenReturnType
} from './admin'

export type ConsumptionLogQueryType = {
  projectId: string
  metricType?: 'ComputeTime' | 'StorageUsage'
  offset: number
  limit: number
  orderBy: string
  sortOrder: string
  from: string
  to: string
}

export const postConsumptionMyList = (params: ConsumptionLogQueryType) =>
  getCurrentConsumptionLogUrl().then(({ data: consumptionUrl }) => {
    return getSASToken(params.projectId).then((token: SASTokenReturnType) => {
      return postConsumptionRequest(
        `${consumptionUrl}consumption/log/my-list`,
        {
          metricType: params.metricType,
          aggregateBy: {
            ProjectId: ''
          },
          from: params.from,
          to: params.to,
          sortBy: params.orderBy,
          sortOrder: params.sortOrder,
          offset: params.offset,
          limit: params.limit
        },
        3,
        {
          'dhi-sas-token': token.data
        }
      )
    })
  })
