import React, { ChangeEvent, useRef } from 'react'
import debounce from 'lodash/debounce'
import size from 'lodash/size'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, CircularProgress, ListItemText } from '@material-ui/core'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import IProjection from './model/IProjection'
import { useMounted } from '../../../helpers/hooks'
import ProjectionDetails from './ProjectionDetails'
import { useStyles } from './styles'
import { Field } from 'formik'

export interface ITextResources {
  code: string
  label: string
  loading: string
  noData: string
  placeholder: string
}

interface IProps {
  /**
   * Name property to be used by the formik field
   */
  name: string
  /**
   * Text input change callback (to fetch respective projections)
   */
  onProjectionSearchTextChanged: (searchText: string) => void
  /**
   * Projections matching search string (to be updated by onProjectionSearchTextChanged)
   */
  projections: IProjection[]
  /**
   * Loading state
   */
  projectionsLoading: boolean
  /**
   * Set to true to use projection id in getOptionLabel (otherwise projection name is taken)
   */
  searchById?: boolean
  /**
   * To overwrite text resources like e.g. input field label
   */
  textResources?: ITextResources
}

const COORDINATE_SYSTEM_SEARCH_TEXT_MIN_LEN = 3
//const COORDINATE_SYSTEM_MAX_DESCRIPTION_LEN = 30

/**
 * An autocomplete component to select projection systems by id or name wrapped into a formik field
 */
const ProjectionSelect = (props: IProps) => {
  const classes = useStyles()
  const {
    name,
    onProjectionSearchTextChanged,
    projectionsLoading,
    projections,
    searchById,
    textResources = {
      code: 'Authority code',
      label: 'Current projection system',
      loading: 'Loading...',
      noData: 'No projection found',
      placeholder: 'Type at least 3 chars of code or name'
    }
  } = props

  const isMounted = useMounted()

  const debouncedSave = useRef(
    debounce(newSearchText => onProjectionSearchTextChanged(newSearchText), 500)
  ).current

  /**
   * Callback for when the projection search input has changed.
   * @param _event
   * @param newSearchText
   */
  const onProjectionInputChange = (_event, newSearchText) => {
    if (!isMounted) {
      return
    }

    if (size(newSearchText) >= COORDINATE_SYSTEM_SEARCH_TEXT_MIN_LEN) {
      debouncedSave(newSearchText)
    }
  }

  return (
    <Field name={name}>
      {({ form: { setFieldValue } }) => (
        <Autocomplete
          classes={{ option: classes.option, listbox: classes.listbox }}
          autoComplete={false}
          id={'project-select'}
          disablePortal={false}
          loading={projectionsLoading}
          options={projections}
          getOptionLabel={option =>
            searchById ? option.id.toString() : option.name
          }
          getOptionSelected={(option, value) => {
            return option.id === value.id
          }}
          onChange={(
            _event: ChangeEvent<{}>,
            newProjection: IProjection | null
          ) => {
            setFieldValue(name, newProjection && newProjection.id)
          }}
          onInputChange={onProjectionInputChange}
          noOptionsText={textResources.noData}
          loadingText={textResources.loading}
          selectOnFocus={true}
          forcePopupIcon={false}
          clearOnEscape={true}
          disableListWrap={true}
          renderOption={params => (
            <ListItemText
              classes={{ primary: classes.primary, secondary: classes.primary }}
              disableTypography={true}
              primary={params.name}
              // secondary={textResources.code + ': ' + params.id}
              secondary={<ProjectionDetails item={params} />}
            />
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={textResources.label}
              margin="dense"
              placeholder={textResources.placeholder}
              variant="filled"
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <SearchOutlinedIcon />
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    {projectionsLoading ? (
                      <CircularProgress size={'1rem'} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
              InputLabelProps={{
                ...params.InputLabelProps
              }}
            />
          )}
        />
      )}
    </Field>
  )
}

export default ProjectionSelect
