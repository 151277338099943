import React from 'react'
import { css } from 'emotion'
import Typography from '@material-ui/core/Typography'
import theme from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'

const paddingStyle = css`
  padding: ${theme.spacing(1)}px;
`

const PageNotFound = () => (
  <Typography className={paddingStyle} variant="h4" color="textPrimary">
    Page Not Found!
  </Typography>
)

export default PageNotFound
