import { css } from 'emotion'
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'

export const iconStyle = (disabled = false) => {
  const color = disabled
    ? mikePalette.secondary.light
    : mikePalette.secondary.main
  return css`
    path {
      fill: ${color};
    }
  `
}
