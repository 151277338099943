import orderBy from 'lodash/orderBy'
import {
  LOADING_TENANTS,
  SET_TENANTS,
  SET_CURRENT_TENANT_NAME
} from '../actions/tenant'
import ITenant from '../model/ITenant'

export const TENANT = 'dhi.tenant'

type TentState = {
  tenants: ITenant[]
  loadingTenants: boolean
}

const initState: TentState = {
  tenants: new Array<ITenant>(),
  loadingTenants: false
}

const tenant = (state = initState, action): TentState => {
  switch (action.type) {
    case LOADING_TENANTS:
      return { ...state, loadingTenants: action.loadingTenants }
    case SET_TENANTS: {
      const sortedTenants = orderBy(action.tenants, [
        (tenant: ITenant) => tenant.name.toLowerCase()
      ])
      return {
        ...state,
        tenants: sortedTenants
      }
    }
    case SET_CURRENT_TENANT_NAME:
      return {
        ...state,
        tenants: state.tenants.map(tenant => {
          if (tenant.id === action.id) {
            return { ...tenant, name: action.name }
          }
          return tenant
        })
      }

    default:
      return state
  }
}

export default tenant
