import writerFileExtension from '../configuration/writerFileExtension'

function getNameWithoutExtension(name: string): string {
  const pos = name.lastIndexOf('.')
  return name.substr(0, pos < 0 ? name.length : pos)
}

function getNameExtension(name: string): string {
  const extension = name.split('.').pop()
  return extension ? '.' + extension : ''
}

export function adaptedFileName(initialName: string, writer: string): string {
  let adaptedName: string = initialName
  if (writer in writerFileExtension) {
    const targetFileExtension = writerFileExtension[writer]
    const origFileExtension = initialName.split('.').pop()
    if (targetFileExtension !== origFileExtension) {
      const nameWithoutExtension = getNameWithoutExtension(initialName)
      const targetExtension = targetFileExtension
        ? '.' + targetFileExtension
        : ''
      adaptedName = nameWithoutExtension + targetExtension
    }
  }
  return adaptedName
}

export function resetFileExtension(
  initialName: string,
  newName: string
): string {
  return getNameWithoutExtension(newName) + getNameExtension(initialName)
}

export function insertSpaces(string) {
  string = string.replace(/([a-z])([A-Z])/g, '$1 $2')
  string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  return string
}
