import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'
import SiteInfoNav from '../../SiteInfoNav'
import { useTypedSelector } from '../../../reducers'
import { Paper, Typography } from '@material-ui/core'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { CreditsStateEnum } from '../../../model/CreditsStateEnum'
import CreditsNav from './CreditsNav'
import { callCreditDetailsExtendedPost } from '../../../actions/credits'
import DateTime from '../../DataTable/renders/DateTime'
import { useSavedDataGridState } from '../../../hooks/use-saved-data-grid-state.hook'

const hoverStyle = () => css`
  &:hover {
    cursor: pointer;
  }
`

const creditsRender = ({ value }) => {
  return (
    <span>
      {new Intl.NumberFormat('fr', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value)}
    </span>
  )
}
const dateTimeRender = ({ value }) =>
  value ? (
    <DateTime jsonTime={value} />
  ) : (
    <Typography
      variant={'body2'}
      style={{ fontWeight: '200', fontStyle: 'italic' }}
      color="textPrimary"
    >
      {'Execution removed'}
    </Typography>
  )
const projectNameRender = ({ value }) =>
  value ? (
    value
  ) : (
    <Typography
      variant={'body2'}
      style={{ fontWeight: '200', fontStyle: 'italic' }}
      color="textPrimary"
    >
      {'Project removed'}
    </Typography>
  )

const machineRender = ({ value }) =>
  value ? (
    value
  ) : (
    <Typography
      variant={'body2'}
      style={{ fontWeight: '200', fontStyle: 'italic' }}
      color="textPrimary"
    >
      {'Execution removed'}
    </Typography>
  )

const salesOrderIdRender = ({ value }) => <span>{value}</span>

const CreditsReserved = () => {
  const intl = useIntl()
  const tenantId = useTypedSelector(state => state['auth']?.user?.tenantId)
  const creditLoading = useTypedSelector(state => state['credits'].loadingState)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [currentRows, setCurrentRows] = useState<number>(25)
  const [entries, setEntries] = useState<any>()
  const creditDetailsExt = useTypedSelector(
    state => state['credits'].creditDetailsExt
  )
  const defaultColumns: GridColDef[] = [
    {
      field: 'credits',
      headerName: intl.formatMessage({
        id: 'credits.columns.amount'
      }),
      cellClassName: hoverStyle,
      renderCell: params => creditsRender(params),
      width: 300
    },
    {
      field: 'createdAt',
      headerName: intl.formatMessage({
        id: 'credits.columns.reserved'
      }),
      hide: true,
      cellClassName: hoverStyle,
      renderCell: params => dateTimeRender(params),
      width: 300
    },
    {
      field: 'operationId',
      headerName: intl.formatMessage({
        id: 'credits.columns.executionId'
      }),
      cellClassName: hoverStyle,
      renderCell: params => params.value ?? '--',
      width: 300
    },
    {
      field: 'projectName',
      headerName: intl.formatMessage({
        id: 'credits.columns.projectName'
      }),
      cellClassName: hoverStyle,
      renderCell: params => projectNameRender(params),
      width: 300
    },
    {
      field: 'projectId',
      headerName: intl.formatMessage({
        id: 'credits.columns.projectNo'
      }),
      hide: true,
      cellClassName: hoverStyle,
      renderCell: params => params.value ?? '--',
      width: 300
    },
    {
      field: 'vm',
      headerName: intl.formatMessage({
        id: 'credits.columns.machine'
      }),
      cellClassName: hoverStyle,
      renderCell: params => machineRender(params),
      width: 300
    },
    {
      field: 'poolType',
      headerName: intl.formatMessage({
        id: 'credits.columns.poolType'
      }),
      cellClassName: hoverStyle,
      renderCell: params => params.value ?? '--',
      width: 300
    },
    {
      field: 'subscriptionOrderId',
      headerName: intl.formatMessage({
        id: 'credits.columns.salesOrderId'
      }),
      hide: false,
      cellClassName: hoverStyle,
      renderCell: params => salesOrderIdRender(params),
      width: 300
    }
  ]

  const {
    apiRef,
    debounceStateSave,
    columnsArray,
    setColumnsArray
  } = useSavedDataGridState({
    tableKey: 'credits-reserved-settings',
    defaultColumns
  })

  const dispatch = useDispatch()

  useEffect(() => {
    const data = {
      statusFilter: ['Reserved'], //, 'Spent', 'Reserved', 'Repaid'
      offset: 0,
      limit: 25,
      tenantId: tenantId ?? '--'
    }
    dispatch(callCreditDetailsExtendedPost(data))
  }, [dispatch, tenantId])

  const onChangePage = page => {
    const properPage = Math.floor(page)

    const dataOffset = properPage * currentRows
    setCurrentPage(properPage)

    const data = {
      statusFilter: ['Reserved'], //, 'Spent', 'Reserved', 'Repaid'
      offset: dataOffset,
      limit: currentRows,
      tenantId: tenantId ?? '--'
    }
    dispatch(callCreditDetailsExtendedPost(data))
  }

  const onChangeRowsPerPage = rowsPerPage => {
    setCurrentRows(rowsPerPage)
    const dataOffset = currentPage * currentRows
    setCurrentPage(dataOffset / rowsPerPage)
    const data = {
      statusFilter: ['Reserved'], //, 'Spent', 'Reserved', 'Repaid'
      offset: dataOffset,
      limit: rowsPerPage,
      tenantId: tenantId ?? '--'
    }
    dispatch(callCreditDetailsExtendedPost(data))
  }

  useEffect(() => {
    setEntries(cloneDeep(creditDetailsExt))
  }, [creditDetailsExt])

  return (
    <>
      <SiteInfoNav />

      <CreditsNav
        displayState={CreditsStateEnum.RESERVED}
        columns={columnsArray}
        setColumns={setColumnsArray}
        onCreditsChange={() => {}}
      />
      <div style={{ marginTop: '1em' }} />
      <Paper>
        <DataGridPro
          apiRef={apiRef}
          autoHeight
          rows={entries?.data?.map(item => ({ ...item, id: uuidv4() })) ?? []}
          columns={columnsArray}
          pagination
          page={currentPage}
          pageSize={creditDetailsExt?.limit ?? 0}
          rowCount={creditDetailsExt?.totalCount ?? 0}
          paginationMode="server"
          getRowId={item => item.id}
          loading={creditLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageSizeChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          onStateChange={debounceStateSave}
        />
      </Paper>
    </>
  )
}

export default CreditsReserved
