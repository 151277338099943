/* eslint-disable react/prop-types */
import React from 'react'

import ButtonBase from '@material-ui/core/ButtonBase'
import { ReactComponent as ConvertTo } from '../icons/ConvertTo'

export const ConvertToIcon = () => (
  <ButtonBase>
    <ConvertTo />
  </ButtonBase>
)
