import React, { useEffect, useState, useCallback } from 'react'
import toLower from 'lodash/fp/toLower'
import PropTypes from 'prop-types'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { compose } from 'recompose'
import size from 'lodash/fp/size'
import get from 'lodash/get'
import map from 'lodash/map'
import every from 'lodash/every'
import xor from 'lodash/xor'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import MikeSlidingPanel from '@mike/mike-shared-frontend/mike-sliding-panel'
import AppPropTypes from '../../shared/appPropTypes'
import messages from '../../shared/messages'
import { enhanceProjectMembers } from '../../helpers/project'
import RefreshButton from '../buttons/RefreshButton'
import DownloadButton from '../buttons/DownloadButton'
import EditProjectButton from '../buttons/EditProjectButton'
import DeleteButton from '../buttons/DeleteButton'
import MoveToButton from '../buttons/MoveToButton'
import CreateSubprojectButton from '../buttons/CreateSubprojectButton'
import FileChooserButton from '../buttons/FileChooserButton'
import SearchInput from '../SearchInput'
import ConfirmationDialog from '../Dialog/ConfirmationDialog'
import EditDatasetForm from '../EditDatasetForm'
import CreateProjectForm from '../CreateProjectForm'
import ConnectedProjectContentTable from '../../containers/ConnectedProjectContentTable'
import ProjectsTreeDialog from '../ProjectsTreeDialog'
import AppNav from '../AppNav'
import DatasetConversionDialog from '../DatasetConversionDialog'
import DatasetUploadManyDialog from '../DatasetConversionDialog/DatasetUploadManyDialog'
import DatasetDownloadManyDialog from '../DatasetConversionDialog/DatasetDownloadManyDialog'
import { UPLOAD_TYPE } from '../../helpers/upload'
import { CONVERT_TYPE } from '../../helpers/convert'
import { DOWNLOAD_TYPE } from '../../helpers/export'
import { useTypedSelector } from '../../reducers'
import UserInviteForm from '../UserInviteForm/UserInviteForm'
import { inviteUser } from '../../actions/users'
import AppBreadcrumbNav from '../AppBreadcrumpsNav'

import asIfReaderWriter, {
  FILEWRITER
} from '../DatasetConversionDialog/model/asIfReaderWriter'
import { isDataset, DATASETFORMAT } from '../../helpers/projectContent'

const localMessages = defineMessages({
  projectTableHeader: {
    id: 'projectContent.table.header'
  },
  confirmationDeleteTitle: {
    id: 'projectContent.confirmation.deleteItems.title'
  },
  searchPlaceholder: {
    id: 'projectDetails.input.search.placeholder'
  },
  editProjectButtonTipFolder: {
    id: 'projectDetails.button.editProject.tipFolder'
  },
  descriptionLabel: {
    id: 'projectContent.descriptionLabel'
  },
  membersLabel: {
    id: 'projectContent.membersLabel'
  },
  accessLevelLabel: {
    id: 'projectContent.accessLevelLabel'
  },
  noWriterToDownload: {
    id: 'projectContent.noWriterToDownload'
  },
  noWriterToConvert: {
    id: 'projectContent.noWriterToConvert'
  },
  editProjectTitle: {
    id: 'createProjectForm.heading.label'
  },
  createFolderTitle: {
    id: 'createProjectForm.headingFolder.title'
  },
  editDatasetTitle: {
    id: 'editDatasetForm.heading.label.dataset'
  },
  editFileTitle: {
    id: 'editDatasetForm.heading.label.file'
  },
  inviteUserTitle: {
    id: 'projectContent.inviteUserTitle'
  },
  inviteUser: {
    id: 'projectContent.confirmationDialog.message.inviteUser'
  },
  confirmationInviteTitle: {
    id: 'projectContent.confirmationDialog.title.inviteUser'
  }
})

const styles = theme => ({
  projectDetails: {
    display: 'flex',
    paddingLeft: '40px',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    position: 'sticky',
    zIndex: 1
  },

  tableTopHeader: {
    position: 'sticky',
    top: theme.spacing(3),
    paddingRight: theme.spacing(4),
    zIndex: 1,
    backgroundColor: theme.palette.lightGrey.main
  },
  tableTopHeaderTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5)
  },
  title: {
    paddingBottom: theme.spacing(0.5)
  },
  tableTopActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(-1),
    paddingLeft: theme.spacing(5)
  },
  leftActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  rightActionButtons: {
    display: 'flex',
    justifyContent: 'center'
  },
  createSubprojectButton: {
    marginLeft: 20
  },
  accessContainer: {
    paddingTop: theme.spacing(2)
  },
  members: {
    paddingTop: theme.spacing(2)
  },
  inviteUserForm: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.mediumGrey.main}`
  }
})
const ProjectContent = ({
  classes,
  createSubProject,
  // customerId,eslint-disable-next-line react/prop-types
  customerUsers,
  editableDataset,
  editingCurrentProject,
  intl,
  loading,
  loadingInitialTree,
  onChangePage,
  onChangeRowsPerPage,
  onCloseTreeDialog,
  onCreateProject,
  onCreateProjectCloseClick,
  onCreateProjectOpenClick,
  onDelete,
  onDownloadMany,
  onEdit,
  onHandleRequestSort,
  onError,
  onEditCurrentProject,
  onExpand,
  onImportDialogDone,
  onMoveToClick,
  onMoveTo,
  onRefreshClick,
  onSearchTextChange,
  onSelectionChange,
  onUploadMany,
  onUpdateDataset,
  openCreateProject,
  openDeleteConfirmation,
  openDownloadManyDialog,
  openEditDataset,
  openUploadManyDialog,
  openProjectTreeDialog,
  project,
  projectTree,
  searchText,
  selectedItems,
  setOpenDeleteConfirmation,
  setOpenDownloadManyDialog,
  setOpenEditDataset,
  setOpenUploadManyDialog,
  user,
  datasetReaders,
  datasetFileReaders,
  showDatasetReadersLoader,
  datasetWriters,
  dedicatedWriters,
  showDatasetWritersLoader,
  loadingCoordinateSystems,
  selectedCoordinateSystems,
  onSearchProjection,
  searchCoordinateSystemsById,
  totalCount
}) => {
  const tenantId = useTypedSelector(state => state['auth'].user?.tenantId)
  const dispatch = useDispatch()
  const selectionSize = size(selectedItems)
  const selectedFolders = selectedItems.filter(item => !isDataset(item))
  const selectedDatasets = xor(selectedItems, selectedFolders)
  const selectionOfDatasetsSize = size(selectedDatasets)

  const {
    canEdit,
    canEditAccessLevel,
    canGrantAccess,
    canCreateContent,
    canDeleteContent,
    canReadContent,
    canUpdateContent
  } = get(project, 'capabilities', {})

  // FIXME: use the intl message format for plurals (https://github.com/yahoo/react-intl/wiki/API#message-syntax)
  const messageSingleDataset = intl.formatMessage({
    id: 'projectContent.confirmationDialog.message.singleItem'
  })
  const messageMultipleDatasets = intl.formatMessage({
    id: 'projectContent.confirmationDialog.message.multipleItems'
  })

  const canDownload = canReadContent && selectionOfDatasetsSize >= 1
  // canReadContent && selectionOfDatasetsSize === 1 && selectionSize === 1
  const canEditProject = canEdit || canEditAccessLevel || canGrantAccess
  // For deleting, either the parent projects needs to can delete content or
  // In case only folders are selected, they can also be deleted when each folder allows it
  const canDelete =
    (selectionSize > 0 && canDeleteContent) ||
    (selectionSize > 0 &&
      selectionOfDatasetsSize === 0 &&
      every(selectedFolders, 'capabilities.canDelete'))
  // For moving same access rights are needed than for deleting
  const canMove = canDelete

  const members = enhanceProjectMembers(get(project, 'members'), customerUsers)

  const editableProject = {
    ...project,
    members
  }

  const subprojectIds = map(selectedFolders, 'id')

  const disabledMovingTargetIds = () => {
    let cannotCreateContentIds = []

    projectTree &&
      Object.values(projectTree).forEach(subprojects => {
        const ids = map(
          subprojects.filter(
            subproject =>
              !subproject.capabilities ||
              !subproject.capabilities.canCreateContent
          ),
          'id'
        )
        cannotCreateContentIds = cannotCreateContentIds.concat(ids)
      })
    return cannotCreateContentIds
  }
  const [
    selectedDatasetsNotInDedicatedStorage,
    setSelectedDatasetsNotInDedicatedStorage
  ] = useState([])
  const [conversionName, setConversionName] = useState('')
  const [conversionDatasetId, setConversionDatasetId] = useState()
  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height)
    }
  }, [])

  const [conversionDialogMode, setConversionDialogMode] = useState(UPLOAD_TYPE)
  const [initialConversionStep, setInitialConversionStep] = useState(1)
  const [fileToUpload, setFileToUpload] = useState(null)
  const [manyFilesToUpload, setManyFilesToUpload] = useState(null)
  const [conversionDialogIsOpen, setConversionDialogIsOpen] = useState(false)
  const [suitableReaders, setSuitableReaders] = useState(null)
  const [itemsToBeDeleted, setItemsToBeDeleted] = useState(null)
  const [disableConvert, setDisableConvert] = useState(false)
  const [isOpenInvite, setIsOpenInvite] = useState(false)
  const [inviteEmail, setInviteEmail] = useState(null)
  const [inviteRole, setInviteRole] = useState(null)
  const [inviteUrl, setInviteUrl] = useState(null)

  const openUploadDialog = useCallback(
    open => {
      setConversionDialogMode(UPLOAD_TYPE)
      setSuitableReaders(datasetFileReaders)
      setConversionDialogIsOpen(open)
    },
    [datasetFileReaders]
  )

  const processFiles = useCallback(
    files => {
      const selectedFileCount = files ? files.length : 0
      setDisableConvert(false)
      if (selectedFileCount === 1) {
        setFileToUpload(files[0])
        setConversionName(files[0].name)
        openUploadDialog(true)
      } else if (selectedFileCount > 1) {
        let selectedFiles = []
        for (var i = 0; i < selectedFileCount; i++) {
          selectedFiles.push(files[i])
        }
        setManyFilesToUpload(selectedFiles)
        setOpenUploadManyDialog(true)
      }
    },
    [
      openUploadDialog,
      setDisableConvert,
      setFileToUpload,
      setConversionName,
      setManyFilesToUpload,
      setOpenUploadManyDialog
    ]
  )

  const onFileDropped = useCallback(
    files => {
      processFiles(files)
    },
    [processFiles]
  )

  const onFileSelect = useCallback(
    event => {
      event.target && event.target.files && processFiles(event.target.files)
      event.target.value = null
    },
    [processFiles]
  )

  const handleCloseConfirmConvertManyDialog = useCallback(() => {
    setManyFilesToUpload(null)
    setOpenUploadManyDialog(false)
  }, [setOpenUploadManyDialog, setManyFilesToUpload])

  const handleUploadManyFiles = useCallback(() => {
    onUploadMany(manyFilesToUpload)
    setOpenUploadManyDialog(false)
  }, [onUploadMany, manyFilesToUpload, setOpenUploadManyDialog])

  const handleCloseConfirmDownloadManyDialog = useCallback(() => {
    setManyFilesToUpload(null)
    setOpenDownloadManyDialog(false)
  }, [setOpenDownloadManyDialog, setManyFilesToUpload])

  const handleDownloadManyFiles = useCallback(() => {
    onDownloadMany(selectedDatasetsNotInDedicatedStorage)
    setOpenDownloadManyDialog(false)
    onSelectionChange([])
  }, [
    onDownloadMany,
    selectedDatasetsNotInDedicatedStorage,
    setOpenDownloadManyDialog,
    onSelectionChange
  ])

  const handleCloseConversionDialog = useCallback(() => {
    setDisableConvert(false)
    setInitialConversionStep(1)
    setConversionDialogIsOpen(false)
    setFileToUpload(null)
    setConversionName('')
    setConversionDatasetId(null)
  }, [
    setInitialConversionStep,
    setConversionDialogIsOpen,
    setFileToUpload,
    setConversionName,
    setConversionDatasetId
  ])

  const onUpload = useCallback(
    (mode, conversionValues, datasetId, fileName) => {
      const name =
        mode === UPLOAD_TYPE
          ? fileToUpload.name
          : fileName
          ? fileName
          : 'Export'
      const data = conversionValues
        ? { ...conversionValues }
        : {
            name: name,
            reader: asIfReaderWriter.reader,
            writer: asIfReaderWriter.writer
          }
      const payload =
        mode === UPLOAD_TYPE
          ? {
              file: fileToUpload,
              importData: data
            }
          : {
              importData: data
            }
      onImportDialogDone(payload, mode, datasetId)
      setConversionDialogIsOpen(false)
      setFileToUpload(null)
      setConversionName('')
      setConversionDatasetId(null)
      onSelectionChange([])
    },
    [fileToUpload, onImportDialogDone, onSelectionChange]
  )

  useEffect(() => {
    setSuitableReaders(datasetReaders)
  }, [datasetReaders])

  const onDeleteOneItem = useCallback(
    item => {
      setItemsToBeDeleted([item])
      setOpenDeleteConfirmation(true)
    },
    [setOpenDeleteConfirmation, setItemsToBeDeleted]
  )
  const onDeleteItems = useCallback(() => {
    setItemsToBeDeleted(selectedItems)
    setOpenDeleteConfirmation(true)
  }, [setOpenDeleteConfirmation, setItemsToBeDeleted, selectedItems])

  const onOkDeleteItems = useCallback(() => {
    onDelete(itemsToBeDeleted)
  }, [onDelete, itemsToBeDeleted])

  const onOkInviteExternal = useCallback(() => {
    if (project && project.id) {
      dispatch(inviteUser(inviteEmail, inviteRole, inviteUrl, project.id))
      setIsOpenInvite(false)
      onCreateProjectCloseClick()
    }
  }, [
    project,
    dispatch,
    inviteUrl,
    inviteEmail,
    inviteRole,
    onCreateProjectCloseClick
  ])

  const onEditOneItem = useCallback(
    item => {
      onEdit(item)
    },
    [onEdit]
  )

  const onConvertDataset = useCallback(
    (item, name) => {
      if (DATASETFORMAT in item) {
        setConversionName(item.name)
        setConversionDatasetId(item.id)
        const readersForDatasetFormat = datasetReaders.filter(reader => {
          return toLower(reader.datasetFormat) === toLower(item.datasetFormat)
        })
        setSuitableReaders(readersForDatasetFormat)
        setConversionDialogMode(
          name === CONVERT_TYPE ? CONVERT_TYPE : DOWNLOAD_TYPE
        )
        const writers =
          readersForDatasetFormat &&
          readersForDatasetFormat.length > 0 &&
          readersForDatasetFormat[0].writers

        if (!writers) {
          // There might be dataset formats without writer support
          onError(
            'noWriter',
            intl.formatMessage(
              name === CONVERT_TYPE
                ? localMessages.noWriterToConvert
                : localMessages.noWriterToDownload
            )
          )
        } else {
          // For converts of existing datasets and
          // downloads without FileWriter available
          // we want to hide save as-if option
          setInitialConversionStep(
            name === CONVERT_TYPE
              ? 2
              : writers && writers.includes(FILEWRITER)
              ? 1
              : 2
          )
          // For downloads with only FileWriter available
          // we want to disable conversion option
          setDisableConvert(
            name === CONVERT_TYPE
              ? false
              : writers && writers.length === 1 && writers.includes(FILEWRITER)
          )
          setConversionDialogIsOpen(true)
        }
      }
    },
    [datasetReaders, onError, intl]
  )

  const onCancelDeleteItems = useCallback(() => {
    setItemsToBeDeleted(null)
    setOpenDeleteConfirmation(false)
  }, [setOpenDeleteConfirmation, setItemsToBeDeleted])

  const onCancelInviteExternal = useCallback(() => {
    setIsOpenInvite(false)
  }, [])

  const onDownloadClick = useCallback(() => {
    if (!canDownload) return

    if (selectedDatasets.length === 1) {
      onConvertDataset(selectedDatasets[0], DOWNLOAD_TYPE)
    } else {
      const dedicatedStorage =
        dedicatedWriters &&
        dedicatedWriters.map(dedicatedWriter => dedicatedWriter.datasetFormat)
      if (dedicatedStorage && dedicatedStorage.length) {
        const filterDatasets = selectedDatasets
          ? selectedDatasets.filter(
              selectedDataset =>
                !dedicatedStorage.includes(selectedDataset.datasetFormat)
            )
          : []
        setSelectedDatasetsNotInDedicatedStorage(filterDatasets)
        setOpenDownloadManyDialog(true)
      }
    }
  }, [
    onConvertDataset,
    selectedDatasets,
    canDownload,
    dedicatedWriters,
    setOpenDownloadManyDialog
  ])

  const onUserInviteClick = (email, role, redirectUri) => {
    setInviteEmail(email)
    setInviteRole(role)
    setInviteUrl(redirectUri)
    setIsOpenInvite(true)
  }

  return (
    <>
      <AppNav tenantId={tenantId} />
      <ProjectsTreeDialog
        open={openProjectTreeDialog}
        onClose={onCloseTreeDialog}
        onExpand={onExpand}
        onMove={onMoveTo}
        parentId={project && project.id}
        projectTree={projectTree}
        selectedIds={subprojectIds}
        disabledIds={disabledMovingTargetIds()}
        loadingInitialTree={loadingInitialTree}
      />

      <ConfirmationDialog
        open={openDeleteConfirmation}
        title={intl.formatMessage(localMessages.confirmationDeleteTitle)}
        message={
          selectionSize > 1 ? messageMultipleDatasets : messageSingleDataset
        }
        ok={intl.formatMessage(messages.yesDelete)}
        onOk={onOkDeleteItems}
        onCancel={onCancelDeleteItems}
      />
      <ConfirmationDialog
        open={isOpenInvite}
        title={intl.formatMessage(localMessages.confirmationInviteTitle)}
        message={intl.formatMessage(localMessages.inviteUser)}
        ok={intl.formatMessage(messages.yes)}
        onOk={onOkInviteExternal}
        onCancel={onCancelInviteExternal}
      />

      <DatasetUploadManyDialog
        onOk={handleUploadManyFiles}
        onCancel={handleCloseConfirmConvertManyDialog}
        open={openUploadManyDialog}
        includesZippedFiles={
          manyFilesToUpload &&
          manyFilesToUpload.filter(file => file.name.endsWith('.zip')).length >
            0
        }
        selectedFilesCount={manyFilesToUpload ? manyFilesToUpload.length : 0}
      />

      <DatasetDownloadManyDialog
        onOk={handleDownloadManyFiles}
        onCancel={handleCloseConfirmDownloadManyDialog}
        open={openDownloadManyDialog}
        selectedFilesCount={
          selectedDatasetsNotInDedicatedStorage
            ? selectedDatasetsNotInDedicatedStorage.length
            : 0
        }
      />

      {!loading && !project && (
        <Typography variant="h4" color="error">
          <FormattedMessage id="projectDetails.error" />
        </Typography>
      )}

      <DatasetConversionDialog
        datasetId={conversionDatasetId}
        datasetReaders={suitableReaders}
        datasetWriters={datasetWriters}
        dedicatedWriters={
          dedicatedWriters && dedicatedWriters.length > 0
            ? dedicatedWriters.map(dedicated => dedicated.name)
            : []
        }
        disableConvert={disableConvert}
        loadingDatasetReaders={
          showDatasetReadersLoader || showDatasetWritersLoader
        }
        fileName={conversionName}
        mode={conversionDialogMode}
        open={conversionDialogIsOpen}
        handleUpload={onUpload}
        initialStep={initialConversionStep}
        handleClose={handleCloseConversionDialog}
        projections={selectedCoordinateSystems}
        projectionsLoading={loadingCoordinateSystems}
        onProjectionSearchTextChanged={onSearchProjection}
        searchCoordinateSystemsById={searchCoordinateSystemsById}
      />
      <div className={classes.tableTopHeader} ref={measuredRef}>
        <div className={classes.projectDetails}>
          <AppBreadcrumbNav />
          {project && project.id !== tenantId && (
            <EditProjectButton
              tip={intl.formatMessage(localMessages.editProjectButtonTipFolder)}
              label={intl.formatMessage(
                localMessages.editProjectButtonTipFolder
              )}
              disabled={!canEditProject}
              onClick={onEditCurrentProject}
            />
          )}
        </div>
        <div className={classes.tableTopActionButtons}>
          <div className={classes.leftActionButtons}>
            <SearchInput
              id="project-details-search-input"
              placeholder={intl.formatMessage(localMessages.searchPlaceholder)}
              autoFocus
              text={searchText}
              onTextChange={onSearchTextChange}
            />

            <RefreshButton onClick={onRefreshClick} />

            {selectionSize > 0 && (
              <DownloadButton
                disabled={!canDownload}
                onClick={onDownloadClick}
              />
            )}
            {selectionSize > 0 && (
              <DeleteButton disabled={!canDelete} onClick={onDeleteItems} />
            )}
            {selectionSize > 0 && (
              <MoveToButton disabled={!canMove} onClick={onMoveToClick} />
            )}
          </div>
          <div className={classes.rightActionButtons}>
            <FileChooserButton
              disabled={!canCreateContent}
              onFileSelect={onFileSelect}
            />
            {/* {showFolderUI && ( */}
            <div className={classes.createSubprojectButton}>
              <CreateSubprojectButton
                disabled={!canCreateContent}
                onClick={onCreateProjectOpenClick}
              />
            </div>
            {/* )} */}
          </div>
        </div>
      </div>

      <ConnectedProjectContentTable
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        searchText={searchText}
        selectedItems={selectedItems}
        onSelectionChange={onSelectionChange}
        onDelete={canUpdateContent ? onDeleteOneItem : () => {}}
        onEdit={canUpdateContent ? onEditOneItem : () => {}}
        onDownload={onConvertDataset}
        onConvert={canUpdateContent ? onConvertDataset : () => {}}
        topOffset={topOffset}
        parentCapabilities={{
          canDeleteContent: canDeleteContent,
          canUpdateContent: canUpdateContent,
          canCreateContent: canCreateContent,
          canReadContent: canReadContent
        }}
        processDroppedFiles={canCreateContent ? onFileDropped : () => {}}
        onHandleRequestSort={onHandleRequestSort}
      />

      <MikeSlidingPanel
        position="right"
        isOpen={openEditDataset}
        onClose={() => setOpenEditDataset(false)}
        titleArea={
          editableDataset && editableDataset.datasetType === 'file'
            ? intl.formatMessage(localMessages.editFileTitle)
            : intl.formatMessage(localMessages.editDatasetTitle)
        }
        contentArea={
          <EditDatasetForm
            initialValues={editableDataset}
            onSubmit={onUpdateDataset}
          />
        }
        actionsArea={null}
        noGrayOverlay={false}
      />
      <MikeSlidingPanel
        position="right"
        isOpen={openCreateProject}
        onClose={onCreateProjectCloseClick}
        titleArea={
          editingCurrentProject
            ? intl.formatMessage(localMessages.editProjectTitle)
            : intl.formatMessage(localMessages.createFolderTitle)
        }
        contentArea={
          <>
            <CreateProjectForm
              user={user}
              editing={editingCurrentProject}
              initialValues={editingCurrentProject ? editableProject : null}
              onSubmit={onCreateProject}
              isSubProject={
                createSubProject || 'parentProjectId' in editableProject
              }
              totalCount={totalCount}
            />
            {editingCurrentProject && canGrantAccess && (
              <div className={classes.inviteUserForm}>
                <Typography variant="h4">
                  {intl.formatMessage(localMessages.inviteUserTitle)}
                </Typography>
                <UserInviteForm
                  isEditMode
                  shouldAutoFocus={false}
                  handleSubmit={data => {
                    onUserInviteClick(data.email, data.role, data.url)
                  }}
                />
              </div>
            )}
          </>
        }
        actionsArea={null}
        noGrayOverlay={false}
      />
    </>
  )
}
ProjectContent.propTypes = {
  classes: PropTypes.object.isRequired,
  createSubProject: PropTypes.bool,
  customerUsers: PropTypes.objectOf(AppPropTypes.customerUser).isRequired,
  datasetToBeConverted: AppPropTypes.dataset,
  editableDataset: AppPropTypes.dataset,
  editingCurrentProject: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingInitialTree: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onCloseTreeDialog: PropTypes.func.isRequired,
  onCreateProject: PropTypes.func.isRequired,
  onCreateProjectCloseClick: PropTypes.func.isRequired,
  onCreateProjectOpenClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownloadMany: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onHandleRequestSort: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onEditCurrentProject: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onImportDialogDone: PropTypes.func.isRequired,
  onMoveToClick: PropTypes.func.isRequired,
  onMoveTo: PropTypes.func.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onSearchTextChange: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onUploadMany: PropTypes.func.isRequired,
  onUpdateDataset: PropTypes.func.isRequired,
  openDeleteConfirmation: PropTypes.bool.isRequired,
  openCreateProject: PropTypes.bool.isRequired,
  openDownloadManyDialog: PropTypes.bool.isRequired,
  openEditDataset: PropTypes.bool.isRequired,
  openUploadManyDialog: PropTypes.bool.isRequired,
  openProjectTreeDialog: PropTypes.bool.isRequired,
  path: PropTypes.array,
  project: AppPropTypes.project,
  projectTree: PropTypes.object,
  searchText: PropTypes.string.isRequired,
  selectedItems: PropTypes.arrayOf(AppPropTypes.dataset).isRequired,
  setOpenDeleteConfirmation: PropTypes.func.isRequired,
  setOpenDownloadManyDialog: PropTypes.func.isRequired,
  setOpenEditDataset: PropTypes.func.isRequired,
  setOpenUploadManyDialog: PropTypes.func.isRequired,
  setOpenProjectTreeDialog: PropTypes.func.isRequired,
  user: AppPropTypes.user,
  datasetReaders: PropTypes.arrayOf(AppPropTypes.datasetReader).isRequired,
  datasetFileReaders: PropTypes.arrayOf(AppPropTypes.datasetReader),
  showDatasetReadersLoader: PropTypes.bool.isRequired,
  datasetWriters: PropTypes.arrayOf(AppPropTypes.datasetWriter),
  dedicatedWriters: PropTypes.arrayOf(AppPropTypes.datasetWriter),
  showDatasetWritersLoader: PropTypes.bool.isRequired,
  loadingApps: PropTypes.bool,
  apps: PropTypes.object,
  loadingCoordinateSystems: PropTypes.bool,
  selectedCoordinateSystems: PropTypes.array,
  onSearchProjection: PropTypes.func.isRequired,
  searchCoordinateSystemsById: PropTypes.bool,
  totalCount: PropTypes.number,
  features: PropTypes.array
}

const enhance = compose(injectIntl, withStyles(styles))

export default enhance(ProjectContent)
