// import proxy from './proxy'
import { cloudHomeBackend } from './support'

/* let apiUrl: string = ''

const init = (url: string) => {
  apiUrl = url
} */

const getAllApplications = async () => {
  return await cloudHomeBackend.getJson('api/AppData/applicationslist')
  // return await proxy.http.get(apiUrl + '/api/AppData/applicationslist')
}

export const MikeCloudApps = {
  // init,
  getAllApplications
}
