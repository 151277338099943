import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'

interface PrivateRouteProps {
  to: string
  // tslint:disable-next-line:no-any
  location: any
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { to, location, ...rest } = props

  return (
    <Route
      {...rest}
      render={() => (
        <Redirect
          to={{
            pathname: to,
            state: { from: location }
          }}
        />
      )}
    />
  )
}

export default PrivateRoute
