import { call, put, takeEvery } from 'redux-saga/effects'

import { getDatasetWriters } from '../apis/datasetWriters'
/* import {
  getMockFileDatasetWriters,
  getMockDedicatedDatasetWriters
} from '../apis/datasetWriters' */

import {
  LOAD_DATASET_WRITERS,
  loadingDatasetWriters,
  setDatasetWriters
} from '../actions/datasetWriters'

import { addError } from '../actions/errors'

export default function* watchDatasetWriters() {
  yield takeEvery(LOAD_DATASET_WRITERS, loadDatasetWriters)
}

export function* loadDatasetWriters() {
  try {
    yield put(loadingDatasetWriters())
    const dedicatedWriters = yield call(getDatasetWriters, 'Dedicated')
    const fileWriters = yield call(getDatasetWriters, 'File')

    /* const dedicatedWriters = yield call(getMockDedicatedDatasetWriters)
    const fileWriters = yield call(getMockFileDatasetWriters) */

    yield put(setDatasetWriters(fileWriters, dedicatedWriters))
  } catch (error) {
    yield put(addError(LOAD_DATASET_WRITERS, error))
  } finally {
    yield put(loadingDatasetWriters(false))
  }
}
