import produce from 'immer'

import {
  LOADING_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
  SET_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
  RESTORING_RECYCLE_BIN_ITEMS_DELETED_BY_ME
} from '../actions/recycleBin'

const initState = {
  itemsDeletedByMe: {
    loading: false,
    restoring: false,
    offset: 0,
    limit: 10,
    sortBy: 'Name',
    sortOrder: 'Asc',
    totalCount: 0,
    data: []
  }
  /* content: {
    loading: false,
    deleting: false,
    restoring: false,
    data: {
      projects: [],
      datasets: [],
      datasetProjects: {}
    }
  },
  project: {
    deleting: false,
    details: {
      loading: false,
      data: null
    },
    datasets: {
      loading: false,
      data: []
    }
  } */
}

const TAG = 'recycleBin'

const reducer = produce((draft, action) => {
  switch (action.type) {
    case LOADING_RECYCLE_BIN_ITEMS_DELETED_BY_ME:
      draft.itemsDeletedByMe.loading = action.loading
      return

    case SET_RECYCLE_BIN_ITEMS_DELETED_BY_ME:
      draft.itemsDeletedByMe.data = action.itemsDeletedByMe.data
      draft.itemsDeletedByMe.offset = action.itemsDeletedByMe.offset
      draft.itemsDeletedByMe.limit = action.itemsDeletedByMe.limit
      if (typeof action.itemsDeletedByMe.sortBy !== 'undefined') {
        draft.itemsDeletedByMe.sortBy = action.itemsDeletedByMe.sortBy
      }
      if (typeof action.itemsDeletedByMe.sortOrder !== 'undefined') {
        draft.itemsDeletedByMe.sortOrder = action.itemsDeletedByMe.sortOrder
      }
      draft.itemsDeletedByMe.totalCount = action.itemsDeletedByMe.totalCount
      return

    case RESTORING_RECYCLE_BIN_ITEMS_DELETED_BY_ME:
      draft.itemsDeletedByMe.restoring = action.restoring
      return

    default:
      return
  }
}, initState)

export default reducer
