import produce from 'immer'

import {
  LOADING_DATASET_READERS,
  SET_DATASET_READERS
} from '../actions/datasetReaders'

export default produce(
  (draft, action) => {
    switch (action.type) {
      case LOADING_DATASET_READERS:
        draft.loading = action.loading
        return

      case SET_DATASET_READERS:
        draft.data = action.datasetFileReaders.concat(
          action.datasetDedicatedReaders
        )
        draft.dedicated = action.datasetDedicatedReaders
        draft.fileReaders = action.datasetFileReaders
        return

      default:
        return
    }
  },
  { loading: false, data: [], dedicated: [], fileReaders: [] }
)
