import { IBillingInformation } from '../apis/admin'
import {
  IAddCreditsData,
  IBillingReferenceCreditsDetailsInput,
  IMoveCreditsInput,
  IReserveOrSpendCreditsInput,
  ITenantCreditsDetailsInput
} from '../apis/credits'
import {
  ICreditsUnassignedResponse,
  ITenantCreditsDetailsExtResponse,
  ITenantCreditsDetailsResponse
} from '../model/ICredits'

//TADY NOVE API CALL//
export const CALL_CREDIT_POST = 'CREDIT/POST'
export const callCreditPost = (data: IAddCreditsData) => ({
  type: CALL_CREDIT_POST,
  data
})

export const CALL_CREDIT_DETAILS_POST = 'CREDIT_DETAILS/POST'
export const callCreditDetailsPost = (data: ITenantCreditsDetailsInput) => ({
  type: CALL_CREDIT_DETAILS_POST,
  data
})

export const CALL_CREDIT_DETAILS_EXTENDED_POST = 'CREDIT_DETAILS_EXTENDED/POST'
export const callCreditDetailsExtendedPost = (
  data: ITenantCreditsDetailsInput
) => ({
  type: CALL_CREDIT_DETAILS_EXTENDED_POST,
  data
})

export const CALL_CREDIT_MOVE_PUT = 'CREDIT_MOVE/PUT'
export const callCreditMovePut = (data: IMoveCreditsInput) => ({
  type: CALL_CREDIT_MOVE_PUT,
  data
})

export const CALL_CREDIT_REPORT_POST = 'CREDIT_REPORT/POST'
export const callCreditReportPost = (
  tenantId: string,
  reportType: string,
  accessToken: string
) => ({
  type: CALL_CREDIT_REPORT_POST,
  tenantId,
  reportType,
  accessToken
})

export const CALL_CREDIT_RESERVE_PUT = 'CREDIT_RESERVE/PUT'
export const callCreditReservePut = (data: IReserveOrSpendCreditsInput) => ({
  type: CALL_CREDIT_RESERVE_PUT,
  data
})

export const CALL_CREDIT_SPEND_PUT = 'CREDIT_SPEND/PUT'
export const callCreditSpendPut = (data: IReserveOrSpendCreditsInput) => ({
  type: CALL_CREDIT_SPEND_PUT,
  data
})

export const CALL_CREDIT_UNASSIGNED_POST = 'CREDIT_UNASSIGNED/POST'
export const callCreditUnassignedPost = (
  data: IBillingInformation,
  filter?: string[] | null
) => ({
  type: CALL_CREDIT_UNASSIGNED_POST,
  data,
  filter
})

export const CALL_CREDIT_UNASSIGNED_DETAILS_POST =
  'CREDIT_UNASSIGNED_DETAILS/POST'
export const callCreditUnassignedDetailsPost = (
  data: IBillingReferenceCreditsDetailsInput
) => ({
  type: CALL_CREDIT_UNASSIGNED_DETAILS_POST,
  data
})

export const CALL_CREDIT_TENANT_GET = 'CREDIT_TENANT/GET'
export const callCreditTenantGet = (
  tenantId: string,
  filter?: string[] | null
) => ({
  type: CALL_CREDIT_TENANT_GET,
  tenantId,
  filter
})

export const CALL_CREDIT_AVAILABLE_GET = 'CREDIT_AVAILABLE/GET'
export const callCreditAvailableGet = (tenantId: string) => ({
  type: CALL_CREDIT_AVAILABLE_GET,
  tenantId
})
//TADY NOVE REDUCER SETY//

export const SET_CREDIT_DETAILS = 'CREDIT_DETAILS/SET'
export const setCreditDetails = (data: ITenantCreditsDetailsResponse) => ({
  type: SET_CREDIT_DETAILS,
  data
})
export const SET_CREDIT_DETAILS_EXT = 'CREDIT_DETAILS_EXT/SET'
export const setCreditDetailsExt = (
  data: ITenantCreditsDetailsExtResponse
) => ({
  type: SET_CREDIT_DETAILS_EXT,
  data
})
export const SET_CREDIT_UNASSIGNED = 'CREDIT_UNASSIGNED/SET'
export const setCreditUnassigned = (data: ICreditsUnassignedResponse) => ({
  type: SET_CREDIT_UNASSIGNED,
  data
})
export const SET_CREDIT_UNASSIGNED_EXT = 'CREDIT_UNASSIGNED_EXT/SET'
export const setCreditUnassignedExt = (
  data: ITenantCreditsDetailsResponse
) => ({
  type: SET_CREDIT_UNASSIGNED_EXT,
  data
})
export const SET_CREDIT_TENANT = 'CREDIT_TENANT/SET'
export const setCreditTenant = (data: ICreditsUnassignedResponse) => ({
  type: SET_CREDIT_TENANT,
  data
})

export const SET_CREDIT_LOADING = 'CREDIT_LOADING/SET'
export const setCreditLoading = (data: boolean) => ({
  type: SET_CREDIT_LOADING,
  data
})

export const SET_CREDIT_AVAILABLE = 'CREDIT_AVAILABLE/SET'
export const setCreditAvailable = (data: any) => ({
  //TODO: typing
  type: SET_CREDIT_AVAILABLE,
  data
})

export const SET_CREDIT_AVAILABLE_INFO_DATA = 'CREDIT_AVAILABLE_INFO_DATA/SET'
export const setCreditAvailableInfoData = (data: any) => ({
  //TODO: typing
  type: SET_CREDIT_AVAILABLE_INFO_DATA,
  data
})
