import IProjection from '../model/IProjection'

import {
  LOADING_COORDINATE_SYSTEMS,
  SET_COORDINATE_SYSTEMS,
  FILTER_COORDINATE_SYSTEMS,
  GET_COORDINATE_SYSTEMS_BY_ID
} from '../actions/coordinateSystems'

const initState = {
  allCoordinateSystems: [],
  loadingCoordinateSystems: false,
  selectedCoordinateSystems: [],
  searchById: false
}

const coordinateSystems = (state = initState, action) => {
  switch (action.type) {
    case LOADING_COORDINATE_SYSTEMS:
      return { ...state, loadingCoordinateSystems: action.loading }
    case SET_COORDINATE_SYSTEMS:
      return { ...state, allCoordinateSystems: action.coordinateSystems }
    case FILTER_COORDINATE_SYSTEMS: {
      const allCS = [...state.allCoordinateSystems]
      const names = allCS.filter((coordinateSystem: IProjection) =>
        coordinateSystem.name
          .toLowerCase()
          .includes(action.filter.toLowerCase())
      )
      return {
        ...state,
        searchCoordinateSystemsById: false,
        selectedCoordinateSystems: names
      }
    }

    case GET_COORDINATE_SYSTEMS_BY_ID: {
      const _allCS = [...state.allCoordinateSystems]
      const ids = _allCS.filter((coordinateSystem: IProjection) =>
        coordinateSystem.id.toString().startsWith(action.id.toString())
      )
      return {
        ...state,
        searchCoordinateSystemsById: true,
        selectedCoordinateSystems: ids
      }
    }

    default:
      return state
  }
}

export default coordinateSystems
