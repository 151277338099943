import { connect } from 'react-redux'
import { compose, lifecycle, withState, withHandlers } from 'recompose'
import { createSelector } from 'reselect'
import { withRouter } from 'react-router-dom'
import map from 'lodash/map'

import {
  loadRecycleBinItemsDeletedByMe,
  restoreRecycleBinItemsDeletedByMe
} from '../actions/recycleBin'

import {
  getRecycleBinItemsDeletedByMe,
  getLoadingRecycleBinItemsDeletedByMe,
  getRecycleBinItemsDeletedByMeOffset,
  getRecycleBinItemsDeletedByMeLimit,
  getRecycleBinItemsDeletedByMeTotalCount,
  getRestoringRecycleBinItemsDeletedByMe
} from '../state'

import RecycleBinItemsDeletedByMe from '../components/RecycleBinItemsDeletedByMe'

const mapStateToProps = createSelector(
  [
    getRecycleBinItemsDeletedByMe,
    getLoadingRecycleBinItemsDeletedByMe,
    getRecycleBinItemsDeletedByMeOffset,
    getRecycleBinItemsDeletedByMeLimit,
    getRecycleBinItemsDeletedByMeTotalCount,
    getRestoringRecycleBinItemsDeletedByMe
  ],
  (data, loadingContent, offset, limit, totalCount, restoring) => ({
    data,
    loading: loadingContent || restoring,
    offset,
    limit,
    totalCount
  })
)

const enhance = compose(
  withRouter,

  connect(mapStateToProps, dispatch => ({ dispatch })),

  withState('searchText', 'setSearchText', ''),
  withState('selection', 'setSelection', []),
  withState('page', 'setPage', 0),

  lifecycle({
    componentDidMount() {
      const tenantId = this.props.match.params.id
      this.props.dispatch(loadRecycleBinItemsDeletedByMe(tenantId))
    },
    componentDidUpdate(prevProps) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        const tenantId = this.props.match.params.id
        this.props.dispatch(loadRecycleBinItemsDeletedByMe(tenantId))
      }
    }
  }),

  withHandlers({
    onRefresh: props => () => {
      props.dispatch(loadRecycleBinItemsDeletedByMe(props.match.params.id))
    },

    onRestore: props => () => {
      props.setSelection([])
      const ids = map(props.selection, 'id')
      props.dispatch(
        restoreRecycleBinItemsDeletedByMe(props.match.params.id, ids)
      )
    },

    onRestoreOneItem: props => row => {
      props.setSelection([])
      if (row.canRestore) {
        const ids = [row.id]
        props.dispatch(
          restoreRecycleBinItemsDeletedByMe(props.match.params.id, ids)
        )
      }
    },

    onSelectionChange: props => selection => {
      props.setSelection(selection)
    },

    onSearchTextChange: props => text => {
      props.setSearchText(text)
    },

    onChangePage: props => (page, orderBy, order) => {
      props.dispatch(
        loadRecycleBinItemsDeletedByMe(
          props.match.params.id,
          page * props.limit,
          props.limit,
          orderBy,
          order
        )
      )
      props.setPage(page)
    },

    onChangeRowsPerPage: props => (limit, orderBy, order) => {
      props.dispatch(
        loadRecycleBinItemsDeletedByMe(
          props.match.params.id,
          props.page,
          limit,
          orderBy,
          order
        )
      )
    },

    onHandleRequestSort: props => (orderBy, order) => {
      props.dispatch(
        loadRecycleBinItemsDeletedByMe(
          props.match.params.id,
          props.page,
          props.limit,
          orderBy,
          order
        )
      )
    }
  })
)

export default enhance(RecycleBinItemsDeletedByMe)
