import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import { iconStyle, useStyles } from './iconStyles'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'

const LINKS_MAP = {
  dev0:
    'https://bswebapps-dev.dhigroup.com/DHI.WebApps.ITServices/Pages/Home.html?pn=11817725&env=dev',
  dev:
    'https://bswebapps-dev.dhigroup.com/DHI.WebApps.ITServices/Pages/Home.html?pn=11817725&env=dev',
  test:
    'https://bswebapps-test.dhigroup.com/DHI.WebApps.ITServices/Pages/Home.html?pn=11817725',
  prod:
    'https://bswebapps.dhigroup.com/DHI.WebApps.ITServices/Pages/Home.html?pn=11817725',
  old:
    'https://dhigroup.sharepoint.com/sites/wiki/SitePages/MIKE-Cloud---How-to-create-new-site.aspx'
}

export function CreateInternalTenantButton() {
  const classes = useStyles()
  // const [environment, setEnvironment] = useState<string>('')

  // const link = environment ? LINKS_MAP[environment] : LINKS_MAP['prod']
  const link = LINKS_MAP['old']

  // Commented out because it's not used in current release, after PO review we can uncomment it and adjust new links
  // useEffect(() => {
  //   const getConfig = async () => {
  //     const config = await fetchConfig()
  //
  //     setEnvironment(config.environment)
  //   }
  //   getConfig().then()
  // }, [])

  return (
    <Button
      className={classes.buttonSecondaryBorder}
      href={link}
      target="_blank"
    >
      <AddOutlinedIcon className={iconStyle} />
      <FormattedMessage id={'createTenantForm.createTenantButton'} />
    </Button>
  )
}
