import { metadata } from './support'

export const getProject = id => {
  return metadata.getJson(`metadata/project/${id}`, 3)
}

export const getProjectDatasets = async projectId => {
  const { data } = await metadata.getJson(
    `metadata/project/${projectId}/dataset/list`,
    3
  )
  return data
}

export const getProjectSubprojects = async projectId => {
  const { data } = await metadata.getJson(
    `metadata/project/${projectId}/subprojects`,
    3
  )
  return data
}

export const getProjectFolders = async (
  projectId,
  newOffset,
  newLimit,
  sortBy,
  sortOrder,
  namePrefix
) => {
  const query = `?offset=${newOffset}&limit=${newLimit}&sortBy=${sortBy}&sortOrder=${sortOrder}&namePrefix=${namePrefix}`
  const { data, totalCount, offset, limit } = await metadata.getJson(
    `metadata/project/${projectId}/subproject/offset-list${query}`,
    3
  )
  return { data: data, totalCount: totalCount, offset: offset, limit: limit }
}

export const getProjectDataset = datasetId =>
  metadata.getJson(`metadata/dataset/${datasetId}`, 3)

export const getProjectMembers = async projectId => {
  const members = await metadata.getJson(
    `metadata/project/${projectId}/member`,
    3
  )

  return members.data
}

export const postProjectMembers = async (projectId, data) => {
  await metadata.postJson(`metadata/project/${projectId}/member`, data, 3)
}

export const getCapabilities = async projectId => {
  return await metadata.getJson(`metadata/project/${projectId}/capabilities`, 3)
}

export const delProjectDataset = datasetId =>
  metadata.delJson(`metadata/dataset/${datasetId}`, 3)

export const putProjectDataset = (projectId, data) =>
  metadata.putJson(`metadata/project/${projectId}/dataset/`, data, 3)

export const moveDataset = (datasetId, targetProjectId) => {
  const data = { targetProjectId: targetProjectId }
  metadata.postJson(`metadata/dataset/${datasetId}/move`, data, 3)
}

export const getProjectPath = async projectId => {
  const { data } = await metadata.getJson(
    `metadata/project/${projectId}/path`,
    3
  )
  return data
}
