import { orderBy } from 'lodash'

export const getSortedPageRows = props => {
  const { data, rowsPerPage, _order, _orderBy, page } = props
  const sortedData = orderBy(data, _orderBy, _order)
  return getPageRows(page, rowsPerPage, sortedData)
}

export const compareValues = (a, b) => (b < a ? -1 : b > a ? 1 : 0)

const getPageRows = (page, rowsPerPage, data) =>
  data.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
