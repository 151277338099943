import { RootProjectListQueryParams } from '../reducers/rootProjectList'

export const LOAD_ROOT_PROJECT_LIST = 'ROOT_PROJECT_LIST/LOAD'
export type LoadRootProjectListType = {
  type: string
  params: RootProjectListQueryParams
}
export const loadRootProjectList = params =>
  ({
    type: LOAD_ROOT_PROJECT_LIST,
    params
  } as LoadRootProjectListType)
