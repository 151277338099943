import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    /* Styles applied to the option elements. */
    option: {
      overflowX: 'hidden'
    },
    /* Styles applied to the `listbox` component. */
    listbox: {
      maxHeight: '20vh'
    },
    primary: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  })
})
