import IFeature from '../model/IFeature'

export const LOAD_TENANT_FEATURES = 'TENANT_FEATURES/LOAD'
export const loadTenantFeatures = () => ({
  type: LOAD_TENANT_FEATURES
})

export const LOADING_TENANT_FEATURES = 'TENANT_FEATURES/LOADING'
export const loadingTenantFeatures = (loading = true) => ({
  type: LOADING_TENANT_FEATURES,
  loading
})

export const SET_TENANT_FEATURES = 'TENANT_FEATURES/SET'
export const setTenantFeatures = (features: IFeature) => ({
  type: SET_TENANT_FEATURES,
  features
})

export const UPDATE_TENANT_APPLICATION = 'TENANT_APPLICATION/UPDATE'
export const updateTenantApplication = (
  appId: string,
  tenantId: string,
  status: boolean
) => ({
  type: UPDATE_TENANT_APPLICATION,
  appId,
  tenantId,
  status
})
