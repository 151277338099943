import { call, put, takeLatest, takeEvery, all, fork } from 'redux-saga/effects'

import { getTenantUsers } from '../apis/admin'

import { getGroups, createGroup, deleteGroup, patchGroup } from '../apis/groups'

import {
  LOAD_GROUPS,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUPS,
  loadGroups,
  // updateGroup,
  CreateUpdateGroupType,
  LoadGroupsType,
  DeleteGroupsType
} from '../actions/groups'

import { addError } from '../actions/errors'

import { notify } from '../helpers/saga'

import { setGroupsList, setGroupsLoadingState } from '../reducers/groups'
import ITenantGroup from '../model/ITenantGroup'

export default function* watchGroup() {
  yield takeLatest(LOAD_GROUPS, fetchGroups)
  yield takeEvery(CREATE_GROUP, postGroup)
  yield takeEvery(DELETE_GROUPS, delGroup)
  yield takeEvery(UPDATE_GROUP, updtGroup)
}

export function* fetchGroups(action: LoadGroupsType) {
  try {
    yield put(setGroupsLoadingState({ loadingState: true }))
    const tenantUsers = (yield call(getTenantUsers)).users
    const groups = (yield call(getGroups, action.tenantId)).data
    yield put(setGroupsList({ groups: groups, tenantUsers: tenantUsers }))
  } catch (error) {
    yield put(addError(LOAD_GROUPS, error))
  } finally {
    yield put(setGroupsLoadingState({ loadingState: false }))
  }
}

export function* postGroup(action: CreateUpdateGroupType) {
  try {
    const formData = action.group
    const group = {
      name: formData.name,
      members: formData.members
    }
    const ids = formData.members.map(member => {
      return member.id
    })
    const createPayload: any = { name: group.name, members: ids }
    yield call(createGroup, action.tenantId, createPayload)
    yield put(loadGroups(action.tenantId))

    yield fork(notify, `Group "${action.group.name}" was created!`)
  } catch (error) {
    yield put(addError(UPDATE_GROUP, error))
  }
}

export function* delGroup(action: DeleteGroupsType) {
  const { groups, tenantId } = action

  try {
    yield all(
      groups.map((group: ITenantGroup) => call(deleteGroup, group.id, tenantId))
    )
    yield put(loadGroups(tenantId))
    yield fork(
      notify,
      groups.length > 1
        ? `${groups.length} groups were deleted!`
        : 'The group was deleted!'
    )
  } catch (error) {
    yield put(addError(DELETE_GROUPS, error))
  }
}

export function* updtGroup(action: CreateUpdateGroupType) {
  try {
    const formData = action.group
    const name = {
      name: formData.name
    } as any
    const gId = formData.id
    yield call(patchGroup, action.tenantId, name, gId)
    yield put(loadGroups(action.tenantId))
    yield fork(notify, `Group "${action.group.name}" was updated!`)
  } catch (error) {
    yield put(addError(UPDATE_GROUP, error))
  }
}
