/* eslint-disable react/prop-types */
import React from 'react'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { makeStyles, createStyles, Typography } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import ConfirmationDialog from '../Dialog/ConfirmationDialog'
import uploadManyDialogTranslations from './translations/uploadManyDialogTranslations'

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    distance: {
      paddingTop: theme.spacing(1)
    },
    title: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1)
    },
    dialogContent: {
      maxWidth: 378,
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      backgroundColor: theme.palette.background.paper
    }
  })
})

interface IProps {
  includesZippedFiles: boolean
  selectedFilesCount: number
  open: boolean
  onCancel: () => void
  onOk: () => void
}

const DatasetUploadManyDialog = (props: IProps) => {
  const classes = useStyles()
  const {
    includesZippedFiles,
    open,
    onCancel,
    onOk,
    selectedFilesCount
  } = props

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      cancel={uploadManyDialogTranslations.cancel}
      ok={uploadManyDialogTranslations.ok}
    >
      <MuiDialogContent className={classes.dialogContent}>
        <Typography variant="h3" className={classes.title}>
          {uploadManyDialogTranslations.titlePart1 +
            ' ' +
            selectedFilesCount +
            ' ' +
            uploadManyDialogTranslations.titlePart2}
        </Typography>
        <Typography variant="body2">
          {uploadManyDialogTranslations.message}
        </Typography>
        {includesZippedFiles && (
          <Typography className={classes.distance} variant="body2">
            {uploadManyDialogTranslations.additionalMessageForZippedFiles}
          </Typography>
        )}
      </MuiDialogContent>
    </ConfirmationDialog>
  )
}

export default DatasetUploadManyDialog
