import { administration } from './support'

import ITenantGroup from '../model/ITenantGroup'

export interface GetGroupsReturnType {
  data: ITenantGroup[]
}

const BASE_IAM_URL = 'iam'

export const getGroups = (tenantId: string) =>
  administration.getJson(
    `${BASE_IAM_URL}/project/${tenantId}/membergroup/list`,
    3
  ) as Promise<GetGroupsReturnType>

export const createGroup = (tenantId: string, group: ITenantGroup) =>
  administration.postJson(
    `${BASE_IAM_URL}/project/${tenantId}/membergroup`,
    group,
    3
  )

export const deleteGroup = (groupId: string, tenantId: string) =>
  administration.delJson(
    `${BASE_IAM_URL}/project/${tenantId}/membergroup/${groupId}`,
    3
  )

export const patchGroup = (tenantId: string, name: any, gId: string) =>
  administration.putJson(
    `${BASE_IAM_URL}/project/${tenantId}/membergroup/${gId}`,
    name,
    3
  )
