const uploadManyDialogTranslations = {
  message:
    'File datasets will be downloaded without any conversion (as-is). Batch download of datasets of type Gisvectordata, Timeseries or Multidimensional is not supported yet and require a conversion. In order to convert datasets, select only one dataset at a time when downloading.',
  cancel: 'Cancel',
  ok: 'Download',
  titlePart1: 'Download',
  titlePart2: 'selected datasets of type file'
}

export default uploadManyDialogTranslations
