export const SET_CONFIG = 'UI/SET_CONFIG'
export const setConfig = config => ({
  type: SET_CONFIG,
  config
})

export const DISMISS_PROGRESS_ITEM = 'UI/DISMISS_PROGRESS_ITEM'
export const dismissProgressItem = id => ({
  type: DISMISS_PROGRESS_ITEM,
  id
})

export const OPEN_POPUP_REMINDER = 'UI/OPEN_POPUP_REMINDER'
export const openPopupReminder = (open = true) => ({
  type: OPEN_POPUP_REMINDER,
  open
})

export const SUPRESS_POPUP_REMINDER = 'UI/SURPRESS_POPUP_REMINDER'
export const surpressPopupReminder = surpress => ({
  type: SUPRESS_POPUP_REMINDER,
  surpress
})

export const NOTIFY = 'UI/NOTIFY'
export const notify = message => ({
  type: NOTIFY,
  message
})

export const SHOW_NOTIFICATION = 'UI/SHOW_NOTIFICATION'
export const showNotification = message => ({
  type: SHOW_NOTIFICATION,
  message
})

export const SHOW_LINK = 'UI/SHOW_LINK'
export const showLink = (message, project) => ({
  type: SHOW_LINK,
  message,
  project
})
