const getConfigUrl = () => {
  switch (process.env.REACT_APP__DHI_TARGET_ENV) {
    case 'dev':
      return '/config/app.config-dev.json'
    case 'dev0':
      return '/config/app.config-dev0.json'
    case 'localhost':
      return '/config/app.config-local.json'
    default:
      return '/config/appsettings.json'
  }
}

async function fetchConfig() {
  const configPath = getConfigUrl()
  const resp = await fetch(configPath)

  return await resp.json()
}

export default fetchConfig
