/* eslint-disable react/prop-types */
import React from 'react'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { makeStyles, createStyles, Typography } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import MikeButton from '@mike/mike-shared-frontend/mike-button'
import conversionOptionsTranslations from './translations/conversionOptionsTranslations'
import uploadManyDialogTranslations from './translations/uploadManyDialogTranslations'
import { useDialogContentStyles } from './styles/datasetUploadStyles'

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    conversionOptionsTitle: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(1)
    },
    conversionOptionsSubtitle: {
      paddingBottom: theme.spacing(3)
    },
    distance: {
      paddingBottom: theme.spacing(1)
    },
    distanceTop: {
      paddingTop: theme.spacing(1)
    },
    color: {
      color: theme.palette.primary.main,
      '& .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root': {
        color: theme.palette.primary.main
      }
    }
  })
})

export interface ITextResources {
  convertToDifferentFormatOption: string
  asIfOption: string
  submit: string
  title: string
}

interface IProps {
  isZippedFile: boolean
  convert: string
  disableConvert?: boolean
  fileName: string
  handleOnClose: () => void
  handleChange: (event: React.ChangeEvent<unknown>) => void
  handleOnClick: () => void
  textResources?: ITextResources
}

const DatasetConversionOptions = (props: IProps) => {
  const classes = useStyles()
  const dialogContentClasses = useDialogContentStyles()
  const {
    convert,
    disableConvert = false,
    isZippedFile,
    fileName,
    handleOnClose,
    handleChange,
    handleOnClick,
    textResources = {
      submit: conversionOptionsTranslations.upload,
      asIfOption: conversionOptionsTranslations.uploadAsIs,
      title: conversionOptionsTranslations.title,
      convertToDifferentFormatOption:
        conversionOptionsTranslations.convertToDifferentFormat
    }
  } = props
  const [buttonLabel, setButtonLabel] = React.useState(
    conversionOptionsTranslations.upload
  )

  React.useEffect(() => {
    setButtonLabel(
      convert === 'false'
        ? textResources.submit
        : conversionOptionsTranslations.next
    )
  }, [convert, textResources.submit])

  return (
    <>
      <MuiDialogContent className={dialogContentClasses.dialogContent}>
        <Typography variant="h4" className={classes.conversionOptionsTitle}>
          {textResources.title}
        </Typography>

        <Typography
          variant="body2"
          className={classes.conversionOptionsSubtitle}
        >
          {conversionOptionsTranslations.selectedFile + ': ' + fileName}
        </Typography>

        <FormControl component="fieldset" className={classes.color}>
          <RadioGroup
            aria-label="option"
            name="option1"
            value={convert}
            onChange={handleChange}
          >
            <FormControlLabel
              className={classes.distance}
              value="false"
              control={<Radio color="primary" />}
              label={textResources.asIfOption}
            />
            <FormControlLabel
              disabled={disableConvert}
              value="true"
              control={<Radio color="primary" />}
              label={textResources.convertToDifferentFormatOption}
            />
          </RadioGroup>
        </FormControl>
        {isZippedFile && (
          <Typography className={classes.distanceTop} variant="body2">
            {uploadManyDialogTranslations.additionalMessageForZippedFiles}
          </Typography>
        )}
      </MuiDialogContent>
      <DialogActions className={dialogContentClasses.dialogActions}>
        <MikeButton
          onClick={handleOnClose}
          buttontype="text"
          className={dialogContentClasses.button}
        >
          {conversionOptionsTranslations.cancel}
        </MikeButton>
        <MikeButton
          onClick={handleOnClick}
          buttontype="primary"
          className={dialogContentClasses.button}
        >
          {buttonLabel}
        </MikeButton>
      </DialogActions>
    </>
  )
}

export default DatasetConversionOptions
