import { IBillingInformation } from '../apis/admin'

export const SET_BILLING_INFORMATION = 'BILLING/SET_BILLING_INFORMATION'

export const setBillingInformation = (
  billingInformation: IBillingInformation
) => ({
  type: SET_BILLING_INFORMATION,
  billingInformation
})
