import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import { useStyles } from './iconStyles'
import { useOidc } from '@axa-fr/react-oidc'

const ChangeSiteButton = (buttonProps: MuiButtonProps) => {
  const classes = useStyles()
  const { login } = useOidc()
  return (
    <Button
      className={classes.buttonSecondaryBorderWhite}
      {...buttonProps}
      variant="contained"
      onClick={() => {
        login('/')
      }}
    >
      <FormattedMessage id={'appLayout.changeTenantButton'} />
    </Button>
  )
}

export default ChangeSiteButton
