const writerFileExtension = {
  AsciiWriter: 'asc',
  Dfs2Writer: 'dfs2',
  DfsuWriter: 'dfsu',
  GISWriter: '',
  GeoJsonWriter: 'json',
  GeoTiffWriter: 'tif',
  MDWriter: '',
  NetcdfWriter: 'nc',
  ShpWriter: 'zip',
  TSWriter: '',
  TimeSeriesCsvWriter: 'zip',
  TimeSeriesDfs0Writer: 'dfs0',
  VtuWriter: 'vtu',
  VtiWriter: 'vti'
}

export default writerFileExtension
