import { get, has, isNil, isArray, first } from 'lodash'
import { compose, withProps } from 'recompose'

import { connect as formikConnect } from 'formik'

export default compose(
  formikConnect,

  withProps(({ formik, ...props }) => {
    const touched = get(formik.touched, props.name)

    const onBlur = formik.handleBlur
    const onChange = props.onChange || formik.handleChange

    const errorMessageOrErrors = get(formik.errors, props.name)
    const errorMessage = isArray(errorMessageOrErrors)
      ? first(errorMessageOrErrors)
      : errorMessageOrErrors

    const error = isNil(props.error)
      ? (touched || formik.submitCount > 0) && Boolean(errorMessage)
      : props.error

    const value = isNil(props.value)
      ? has(formik.values, props.name)
        ? get(formik.values, props.name)
        : ''
      : props.value

    const helperText = isNil(props.helperText)
      ? error
        ? errorMessage
        : ''
      : props.helperText

    return {
      onBlur,
      onChange,
      error,
      value,
      helperText
    }
  })
)
