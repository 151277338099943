import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { ReactComponent as Edit } from '@mike/mike-shared-frontend/media/icons/Edit'
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { css } from 'emotion'

const styles = () => ({
  button: {
    // color: mikePalette.primary.main,
    '&[disabled]': {
      color: mikePalette.secondary.light,
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  }
})

const iconStyle = css`
  path {
    fill: ${mikePalette.secondary.main};
  }
`

const EditProjectButton = ({ classes, tip = '', label, ...buttonProps }) => (
  <Tooltip title={tip}>
    <span>
      <Button className={classes.button} {...buttonProps}>
        {label}
        <Edit className={iconStyle} />
      </Button>
    </span>
  </Tooltip>
)

EditProjectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  tip: PropTypes.string,
  label: PropTypes.string
}

export default withStyles(styles)(EditProjectButton)
