module.exports = {
  // “Secret” string that enables the regression test bypass. The bypass will only work in selected environments (such as Test). The value will be provided to the test application.
  'regression-test-secret': 'LocalDevelopment',
  // Valid uuid/guid identifying the user. I recommend using id of Adam Amur.
  'regression-test-user-id': '862281a7-2e66-4c76-af8d-29c82c723b4b',
  // The user name / display name. The value is not important, but it has to be provided for completeness sake.
  'regression-test-user-name': 'Adam Amur',
  // The customer id. The value might be important for retrieving the right data (projects/datasets). Also for retrieving customer users I recommend using a real value.	The user email address. The value is not important, but it has to be provided for completeness sake.
  'regression-test-user-email': 'adam.amur.dhi1@outlook.com',
  // The customer name.
  'regression-test-customer-name': 'dhi1',
  // The customer id (guid). the value is used as tenant id.
  'regression-test-customer-guid': 'dhi1',
  // The flag specifying whether the user is admin or not. Allowed values are “true” or “false”.
  'regression-test-isadmin': true
}
