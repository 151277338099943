//import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { ITrackProgressItem } from '../model/ITrackProgressItem'

export const REMOVE_PROGRESS_ITEM = 'PROGRESS/REMOVE_ITEM'
export const removeProgressItem = (progressItem: ITrackProgressItem) => ({
  type: REMOVE_PROGRESS_ITEM,
  progressItem
})

export const UPDATE_OR_ADD_PROGRESS_ITEM = 'PROGRESS/UPDATE_OR_ADD_ITEM'
export const updateOrAddProgressItem = (progressItem: ITrackProgressItem) => ({
  type: UPDATE_OR_ADD_PROGRESS_ITEM,
  progressItem
})

export const PROGRESS_ITEM_COMPLETE = 'PROGRESS/ITEM_COMPLETE'
export const progressItemComplete = (progressItem: ITrackProgressItem) => ({
  type: PROGRESS_ITEM_COMPLETE,
  progressItem
})

export const PROGRESS_ITEM_FAILED = 'PROGRESS/ITEM_FAILED'
export const progressItemFailed = (progressItem: ITrackProgressItem) => ({
  type: PROGRESS_ITEM_FAILED,
  progressItem
})
