import produce from 'immer'

import { ADD_ERROR, REMOVE_ERROR } from '../actions/errors'

export default produce((draft, action) => {
  switch (action.type) {
    case ADD_ERROR:
      draft.push(action.error)
      return

    case REMOVE_ERROR:
      return draft.filter(error => error.id !== action.id)

    default:
      return
  }
}, [])
