import React from 'react'
import objectHash from 'object-hash'
import map from 'lodash/map'
import { Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import TextField from '../formik/TextField'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import messages from '../../shared/messages'
import { MikeButton } from '@mike/mike-shared-frontend'
import { css } from 'emotion'
import ITenantUser from '../../model/ITenantUser'
import SelectField from '../formik/SelectField'
import { ROLE_TYPES } from '../../helpers/project'

const submitButtonStyle = css`
  float: right;
`
const validationSchema = Yup.object().shape({
  role: Yup.string().required('Required')
})

interface IProps {
  user: ITenantUser
  handleSubmit: (user: ITenantUser) => void
}

const UserEditForm = (props: IProps) => {
  const intl = useIntl()
  const { handleSubmit, user } = props

  return (
    <Formik
      key={objectHash(user)}
      initialValues={user}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => {
        const { handleChange, setFieldTouched } = formik

        const change = (name, e) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label={intl.formatMessage(messages.name)}
                  type="name"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label={intl.formatMessage(messages.email)}
                  type="email"
                  fullWidth
                  required
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <SelectField
                  name="role"
                  label={intl.formatMessage(messages.role)}
                  valueField="value"
                  primaryField="name"
                  items={map(Object.values(ROLE_TYPES), role => ({
                    value: role,
                    name: intl.formatMessage({ id: `common.roles.${role}` })
                  }))}
                  onChange={change.bind(null, 'role')}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MikeButton
                  className={submitButtonStyle}
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  {intl.formatMessage({
                    id: 'updateUsers.updateButton'
                  })}
                </MikeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UserEditForm
