import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { compose, withProps, defaultProps } from 'recompose'
import { useHistory } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import AppPropTypes from '../../shared/appPropTypes'
import messages from '../../shared/messages'
import DataTable, { columnPropType } from '../DataTable'
import Renders from '../DataTable/renders'
import DataTableRenders from '../DataTable/renders'
import DateTime from '../DataTable/renders/DateTime'
import LongText from '../DataTable/renders/LongText'
import LongName from '../DataTable/renders/LongName'
import { CONVERT_TYPE } from '../../helpers/convert'
import { DOWNLOAD_TYPE } from '../../helpers/export'
import { ReactComponent as DataSet } from '@mike/mike-shared-frontend/media/icons/DataSet'
import { ReactComponent as Folder } from '@mike/mike-shared-frontend/media/icons/Folder'
import { css } from 'emotion'
import { getCapabilities } from '../../apis/project'
import { useTypedSelector } from '../../reducers'
import { isDataset } from './../../helpers/projectContent'

const ProjectContentTable = props => {
  const {
    projectContent,
    loading,
    selectedItems,
    onSelectionChange,
    onDelete,
    onEdit,
    onConvert,
    onDownload,
    onHandleRequestSort,
    _columns,
    topOffset,
    parentCapabilities,
    processDroppedFiles,
    onChangePage,
    onChangeRowsPerPage,
    page,
    rowsPerPage,
    totalCount,
    sortBy,
    sortOrder
  } = props

  const history = useHistory()
  const tenantId = useTypedSelector(state => state.auth?.user?.tenantId) || ''

  const [capabilities, setCapabilities] = useState({})

  const fetchCapabilitiesIfNeeded = useCallback(
    item => {
      if (
        !isDataset(item) &&
        (capabilities[item.id] === null || capabilities[item.id] === undefined)
      ) {
        return getCapabilities(item.id, tenantId).then(newCap => {
          setCapabilities({ ...capabilities, [item.id]: newCap })
          return newCap
        })
      }

      return new Promise(resolve => {
        resolve(capabilities[item.id])
      })
    },
    [capabilities, tenantId]
  )

  const actionPopupStateChange = (open, row) => {
    if (open) {
      fetchCapabilitiesIfNeeded(row)
    }
  }

  const onSelectionChangeWrapper = items => {
    items.forEach(item => {
      fetchCapabilitiesIfNeeded(item)
    })
    onSelectionChange(items)
  }

  const onColumnClick = React.useCallback(
    item => {
      fetchCapabilitiesIfNeeded(item).then(cap => {
        if (cap?.canListContent) {
          history.push(`/project/${item.id}`)
        }
      })
    },
    [fetchCapabilitiesIfNeeded, history]
  )

  const items = useMemo(() => {
    return projectContent.map(item => {
      return {
        ...item,
        capabilities: capabilities[item.id] || null
      }
    })
  }, [projectContent, capabilities])

  return (
    <DataTable
      actions={[
        {
          name: 'Edit',
          callBack: onEdit,
          render: Renders.renderEditItem,
          disableGutters: true,
          parentCapabilities: parentCapabilities
        },
        {
          name: 'Delete',
          callBack: onDelete,
          render: Renders.renderDeleteItem,
          disableGutters: true,
          parentCapabilities: parentCapabilities
        },
        {
          name: CONVERT_TYPE,
          callBack: onConvert,
          render: Renders.renderConvertDataset,
          disableGutters: true,
          parentCapabilities: parentCapabilities
        },
        {
          name: DOWNLOAD_TYPE,
          callBack: onDownload,
          render: Renders.renderDownloadDataset,
          disableGutters: true,
          parentCapabilities: parentCapabilities
        }
      ]}
      actionsPopupStateChange={actionPopupStateChange}
      loading={loading}
      columns={_columns}
      idField="id"
      data={items}
      selectedRows={selectedItems}
      onColumnClick={onColumnClick}
      onSelectionChange={onSelectionChangeWrapper}
      onHandleRequestSort={onHandleRequestSort}
      page={page}
      rowsPerPage={rowsPerPage}
      topOffset={topOffset}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      processDroppedFiles={processDroppedFiles}
      totalCount={totalCount}
      _order={[sortOrder]}
      _orderBy={[sortBy]}
    />
  )
}
ProjectContentTable.propTypes = {
  projectContent: PropTypes.arrayOf(AppPropTypes.dataset),
  selectedItems: PropTypes.arrayOf(AppPropTypes.dataset),
  loading: PropTypes.bool,
  onSelectionChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onConvert: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onHandleRequestSort: PropTypes.func.isRequired,
  _columns: PropTypes.arrayOf(columnPropType).isRequired,
  topOffset: PropTypes.number,
  parentCapabilities: PropTypes.object,
  processDroppedFiles: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired
}

const formatRender = value => {
  return value ? capitalize(value) : 'Folder'
}

const longTextRender = value => <LongText longText={value} />

const itemNameRender = (value, item) => (
  <LongName longName={value} icon={'datasetType' in item ? DataSet : Folder} />
)

const dateTimeRender = value => <DateTime jsonTime={value} />

const hoverStyle = item => {
  const className =
    'datasetType' in item
      ? null
      : css`
          &:hover {
            cursor: pointer;
          }
        `
  return className
}

const enhance = compose(
  injectIntl,

  defaultProps({
    searchText: '',
    loading: false
  }),

  withProps(({ intl }) => ({
    _columns: [
      {
        field: 'name',
        label: intl.formatMessage(messages.name),
        render: itemNameRender,
        className: hoverStyle
      },
      {
        field: 'description',
        label: intl.formatMessage(messages.description),
        render: longTextRender,
        className: hoverStyle
      },
      {
        field: 'datasetType',
        label: intl.formatMessage(messages.type),
        render: formatRender,
        className: hoverStyle
      },
      {
        field: 'datasetFormat',
        label: intl.formatMessage(messages.format),
        render: formatRender,
        className: hoverStyle
      },
      {
        field: 'storageSize',
        label: intl.formatMessage(messages.size),
        render: DataTableRenders.size(1024),
        className: hoverStyle
      },
      {
        field: 'createdAt',
        label: intl.formatMessage(messages.createdAt),
        render: dateTimeRender,
        className: hoverStyle
      },
      {
        field: 'updatedAt',
        label: intl.formatMessage(messages.editedAt),
        render: dateTimeRender,
        className: hoverStyle
      }
    ]
  }))
)

export default enhance(ProjectContentTable)
