import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'

const DateTime = ({ jsonTime }) => {
  const local = moment
    .utc(jsonTime)
    .local()
    .toDate()

  return (
    <Tooltip title={moment(local).format('DD MMM YYYY h:mm A')}>
      <div style={{ whiteSpace: 'nowrap' }}>
        {moment(local).format('DD MMM YYYY')}
      </div>
    </Tooltip>
  )
}

DateTime.propTypes = {
  jsonTime: PropTypes.string.isRequired
}

export default React.memo(DateTime)
