import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import DoneIcon from '@material-ui/icons/Done'
import Popper from '@material-ui/core/Popper'
import InputBase from '@material-ui/core/InputBase'
import { FormattedMessage } from 'react-intl'
import { ROLE_TYPES } from '../../helpers/project'
import ITenantUser from '../../model/ITenantUser'
import { makeStyles, createStyles } from '@material-ui/core/styles'

export interface ITextResources {
  label: string
  loading: string
  noData: string
  placeholder: string
  title: string
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: 15
    }
  })
)
interface IProps {
  /**
   * Tenant selected callback
   */
  onMemberSelected: (members: ITenantUser[]) => void
  /**
   * Tenants matching search string (to be updated by onTenantSearchTextChanged)
   */
  members: Array<ITenantUser>
  /**
   * Loading state
   */
  membersLoading?: boolean
  /**
   * Selected tenant
   */
  selectedMembers?: Array<ITenantUser>
  /**
   * To overwrite text resources like e.g. input field label
   */
  textResources?: ITextResources

  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any

  users: Array<ITenantUser>
}

/**
 * @name TenantSelect
 * @summary An autocomplete component to select tenant by name
 *
 * @param props
 */
const TenantMembersSelect = (props: IProps) => {
  const {
    // membersLoading = false,
    users = [],
    members = [],
    textResources = {
      label: 'Current members',
      loading: 'Loading...',
      noData: 'No member found',
      placeholder: 'Search user to add',
      title: 'Members'
    },
    setFieldValue
  } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<any>(null)
  const [value, setValue] = React.useState(members)
  const [pendingValue, setPendingValue] = React.useState<ITenantUser[]>([])

  const open = Boolean(anchorEl)
  const id = open ? 'tenant-member-select' : undefined

  const renderOption = (option: ITenantUser, param) => {
    return (
      <React.Fragment>
        <DoneIcon
          style={{
            visibility: param.selected ? 'visible' : 'hidden',
            color: 'green'
          }}
        />
        <Typography variant="body2">{option.name}</Typography>
      </React.Fragment>
    )
  }

  const handleButtonClick = event => {
    setPendingValue(value)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return
    }
    setValue(pendingValue)
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const colorByRole = role =>
    role === ROLE_TYPES.OWNER
      ? 'primary'
      : role === ROLE_TYPES.CONTRIBUTOR
      ? 'secondary'
      : undefined

  const handleDelete = (member: ITenantUser) => {
    const newValue = value.filter(v => {
      return v.id !== member.id
    })
    setValue(newValue)
  }

  return (
    <>
      <Typography variant="h5">{textResources.title}</Typography>
      <br />
      <Button
        disableRipple
        variant="outlined"
        aria-describedby={id}
        onClick={handleButtonClick}
        size={'small'}
      >
        <AddOutlinedIcon />
        <FormattedMessage id={'userGroupForm.userSelect.addUser'} />
      </Button>
      <div className={classes.container}>
        {value &&
          value.map((v: ITenantUser, index) => {
            return (
              <Chip
                key={index + v.name}
                label={v.name}
                color={colorByRole(v.role)}
                style={{ margin: '5px' }}
                onDelete={() => handleDelete(v)}
              />
            )
          })}
      </div>

      <Popper
        style={{
          border: '1px solid rgba(27,31,35,.15)',
          boxShadow: '0 3px 12px rgba(27,31,35,.15)',
          borderRadius: 3,
          width: 300,
          zIndex: 10000,
          fontSize: 13,
          color: '#586069',
          backgroundColor: '#f6f8fa'
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <div>{textResources.placeholder}</div>
        <Autocomplete
          open
          onClose={handleClose}
          multiple
          value={pendingValue}
          onChange={(event, newValue: ITenantUser[]) => {
            setPendingValue(newValue)
            setFieldValue('members', newValue)
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText="No users"
          renderOption={renderOption}
          options={users}
          getOptionSelected={(option, value) => value.id === option.id}
          getOptionLabel={(option: ITenantUser) => option.name}
          renderInput={params => {
            return (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
              />
            )
          }}
        />
      </Popper>
    </>
  )
}

export default TenantMembersSelect
