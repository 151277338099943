import produce from 'immer'

import {
  LOADING_PROJECT_MEMBERS,
  SET_PROJECT_MEMBERS,
  LOADING_PROJECT_DETAILS,
  SET_PROJECT_DETAILS,
  DELETING_PROJECT_DATASETS,
  LOADING_PROJECT_DATASETS,
  SET_PROJECT_DATASETS,
  UPDATE_PROJECT_DATASET,
  LOADING_PROJECT_SUBPROJECTS,
  SET_PROJECT_SUBPROJECTS,
  LOADING_PROJECT_PARENT,
  SET_PROJECT_PARENT,
  MOVING_PROJECT_CONTENT,
  LOADING_PROJECT_INITIAL_TREE,
  SET_PROJECT_ROOT,
  SET_PROJECT_INITIAL_TREE,
  SET_PROJECT_SUBPROJECTS_TREE,
  LOADING_PROJECT_PATH,
  SET_PROJECT_PATH,
  SET_PROJECT_PAGINATION,
  SET_PROJECT_ROWSPERPAGE,
  SET_PROJECT_CONTENT
} from '../actions/project'

const initState = {
  pagination: {
    page: 0,
    rowsPerPage: 0
  },
  details: {
    capabilities: null,
    data: null,
    loading: true
  },
  datasets: {
    data: null,
    loading: false,
    deleting: false,
    updating: false
  },
  members: {
    data: null,
    loading: false
  },
  subprojects: {
    data: null,
    loading: false
  },
  parent: {
    data: null,
    loading: false
  },
  tree: {
    data: null,
    loading: false
  },
  initialtree: {
    data: { project: [] },
    loading: false
  },
  content: {
    moving: false
  },
  path: {
    data: null,
    loading: false
  },
  projectContent: {
    data: [],
    sortBy: 'updatedAt',
    sortOrder: 'desc',
    totalCount: 0,
    offset: 0,
    namePrefix: '',
    rowsPerPage: 25
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case SET_PROJECT_PAGINATION:
      draft.pagination.page = action.page
      return

    case SET_PROJECT_ROWSPERPAGE:
      draft.pagination.rowsPerPage = action.rowsPerPage
      return

    case UPDATE_PROJECT_DATASET:
      draft.datasets.updating = action.updating
      return

    case DELETING_PROJECT_DATASETS:
      draft.datasets.deleting = action.deleting
      return

    case LOADING_PROJECT_DETAILS:
      draft.details.loading = action.loading
      return

    case SET_PROJECT_DETAILS:
      draft.details.data = action.details
      draft.details.capabilities = action.capabilities
      return

    case SET_PROJECT_MEMBERS:
      draft.members.data = action.members
      return

    case LOADING_PROJECT_MEMBERS:
      draft.members.loading = action.loading
      return

    case LOADING_PROJECT_DATASETS:
      draft.datasets.loading = action.loading
      return

    case SET_PROJECT_DATASETS:
      draft.datasets.data = action.datasets
      return

    case LOADING_PROJECT_SUBPROJECTS:
      draft.subprojects.loading = action.loading
      return

    case SET_PROJECT_SUBPROJECTS:
      draft.subprojects.data = action.subprojects
      return

    case LOADING_PROJECT_PARENT:
      draft.parent.loading = action.loading
      return

    case SET_PROJECT_PARENT:
      draft.parent.data = action.parentProject
      return

    case MOVING_PROJECT_CONTENT:
      draft.content.moving = action.moving
      return

    case LOADING_PROJECT_INITIAL_TREE:
      draft.initialtree.loading = action.loading
      return

    case SET_PROJECT_ROOT:
      draft.tree.data = action.rootProject
      return

    case SET_PROJECT_INITIAL_TREE:
      draft.initialtree.data = action.initialtree
      return

    case SET_PROJECT_SUBPROJECTS_TREE:
      draft.tree.data = {
        ...action.tree,
        [action.parentProjectId]: action.subprojects || []
      }
      return

    case LOADING_PROJECT_PATH:
      draft.path.loading = action.loading
      return

    case SET_PROJECT_PATH:
      draft.path.data = action.data
      return

    case SET_PROJECT_CONTENT:
      draft.projectContent = {
        data: action.projectContent,
        offset: action.offset,
        rowsPerPage: action.rowsPerPage,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        namePrefix: action.namePrefix,
        totalCount: action.totalCount
      }
      return

    default:
      return
  }
}, initState)
