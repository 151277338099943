import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ITenantGroup from '../model/ITenantGroup'
import ITenantUser from '../model/ITenantUser'
import { StateEnum } from '../model/StateEnum'

export type GroupState = {
  groups: Array<ITenantGroup>
  loadingState: StateEnum
  tenantUsers: Array<ITenantUser>
}

const initState = {
  groups: [],
  loadingState: StateEnum.INIT,
  tenantUsers: []
} as GroupState

const groupsSlice = createSlice({
  name: 'groupsSlice',
  initialState: initState as GroupState,
  reducers: {
    setGroupsLoadingState: (
      state,
      action: PayloadAction<{ loadingState: boolean }>
    ): GroupState => {
      return {
        ...state,
        loadingState: action.payload.loadingState
          ? StateEnum.LOADING
          : StateEnum.LOADED
      }
    },
    setGroupsList: (
      state,
      action: PayloadAction<{
        groups: ITenantGroup[]
        tenantUsers: ITenantUser[]
      }>
    ): GroupState => {
      return {
        ...state,
        groups: action.payload.groups,
        loadingState: StateEnum.LOADED,
        tenantUsers: action.payload.tenantUsers
      }
    }
  }
})

export const { setGroupsList, setGroupsLoadingState } = groupsSlice.actions

export default groupsSlice.reducer
