import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { IConsumptionEntry } from '../model/IConsumptionEntry'
import { StateEnum } from '../model/StateEnum'

export type SiteCpuUsageQueryParams = {
  page: number
  rowsPerPage: number
  sortOrder: string
  orderBy: string
  startDate: string
  endDate: string
}

export type SiteCpuUsageState = {
  cpuUsage: IConsumptionEntry[]
  params: SiteCpuUsageQueryParams
  totalCount: number
  state: StateEnum
}

const initState = {
  cpuUsage: [],
  params: {
    page: 0,
    rowsPerPage: 10,
    sortOrder: 'desc',
    orderBy: 'value',
    startDate: moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  },
  totalCount: -1,
  state: StateEnum.INIT
} as SiteCpuUsageState

const siteCpuUsageSlice = createSlice({
  name: 'siteCpuUsage',
  initialState: initState as SiteCpuUsageState,
  reducers: {
    setCpuUsageLoadingState: (
      state,
      action: PayloadAction<{ loadingState: boolean }>
    ): SiteCpuUsageState => {
      return {
        ...state,
        state: action.payload.loadingState
          ? StateEnum.LOADING
          : StateEnum.LOADED
      }
    },
    setTotalCount: (state, action: PayloadAction<{ totalCount: number }>) => {
      return {
        ...state,
        totalCount: action.payload.totalCount
      }
    },
    setCpuQueryParams: (
      state,
      action: PayloadAction<SiteCpuUsageQueryParams>
    ) => {
      return {
        ...state,
        params: action.payload
      }
    },
    setCpuUsageData: (state, action: PayloadAction<IConsumptionEntry[]>) => {
      return {
        ...state,
        cpuUsage: action.payload
      }
    },
    setCpuUsageState: (
      state,
      action: PayloadAction<SiteCpuUsageState>
    ): SiteCpuUsageState => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export const {
  setCpuUsageLoadingState,
  setCpuUsageState,
  setTotalCount,
  setCpuQueryParams,
  setCpuUsageData
} = siteCpuUsageSlice.actions

export default siteCpuUsageSlice.reducer
