import PropTypes from 'prop-types'

import { ACCESS_LEVELS, ROLE_TYPES } from '../helpers/project'
import { PARAMETER_TYPES } from '../helpers/parameters'
import { UPLOAD_STATES } from '../helpers/upload'
import { TRANSFER_STATUSES } from '../apis/transfer'
import { EXPORT_STATES } from '../helpers/export'

import { MDUI_SIZES, MDUI_PLACEMENTS } from '../shared/constants'

const loginConfig = PropTypes.exact({
  clientId: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  sessionId: PropTypes.string
})

const role = PropTypes.oneOf(Object.values(ROLE_TYPES))

const mduiSizes = PropTypes.oneOf(Object.values(MDUI_SIZES))

const mduiPlacements = PropTypes.oneOf(Object.values(MDUI_PLACEMENTS))

const projectCapabilities = PropTypes.exact({
  canCreateContent: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canDeleteContent: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canEditAccessLevel: PropTypes.bool.isRequired,
  canGrantAccess: PropTypes.bool.isRequired,
  canListContent: PropTypes.bool.isRequired,
  canReadContent: PropTypes.bool.isRequired,
  canUpdateContent: PropTypes.bool.isRequired
})

const language = PropTypes.exact({
  code: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
})

const coordinateSystem = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  wkt: PropTypes.string.isRequired,
  proj4String: PropTypes.string.isRequired
})

const customerUser = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
})

const projectMember = PropTypes.shape({
  userId: PropTypes.string.isRequired,
  role: role.isRequired,
  displayName: PropTypes.string
})

const apiError = PropTypes.shape({
  httpStatus: PropTypes.number.isRequired,
  httpStatusText: PropTypes.string.isRequired,
  body: PropTypes.any
})

const error = PropTypes.exact({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isApiError: PropTypes.bool.isRequired,
  source: PropTypes.object, //PropTypes.oneOfType([apiError, PropTypes.instanceOf(Error)]),
  timestamp: PropTypes.number.isRequired
})

const upload = PropTypes.exact({
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  state: PropTypes.oneOf(Object.values(UPLOAD_STATES)).isRequired,
  fileUrl: PropTypes.string,
  blobName: PropTypes.string,
  progress: PropTypes.number,
  error
})

const exportForm = PropTypes.exact({
  coordinateSystemId: PropTypes.number,
  exporterId: PropTypes.string.isRequired
})

const exporter = PropTypes.shape({
  format: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  datasetTypes: PropTypes.arrayOf(PropTypes.string).isRequired
})

const exportData = PropTypes.exact({
  id: PropTypes.string.isRequired,
  state: PropTypes.oneOf(Object.values(EXPORT_STATES)).isRequired,
  fileName: PropTypes.string.isRequired,
  data: exportForm,
  projectId: PropTypes.string,
  datasetId: PropTypes.string,
  progress: PropTypes.number,
  error
})

const importForm = PropTypes.exact({
  coordinateSystemId: PropTypes.number,
  description: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  transferUpload: PropTypes.bool,
  importerId: PropTypes.string,
  importerParameters: PropTypes.object,
  name: PropTypes.string.isRequired,
  reader: PropTypes.string,
  writer: PropTypes.string,
  advancedOptions: PropTypes.bool,
  readerParameters: PropTypes.object
})

const importData = PropTypes.exact({
  id: PropTypes.string.isRequired,
  state: PropTypes.oneOf(Object.values(TRANSFER_STATUSES)).isRequired,
  data: importForm,
  fileUrl: PropTypes.string,
  projectId: PropTypes.string,
  createdBy: PropTypes.string,
  progress: PropTypes.number,
  error,
  initialId: PropTypes.string
})

const parameter = PropTypes.shape({
  name: PropTypes.string.isRequired,
  dataType: PropTypes.oneOf(Object.values(PARAMETER_TYPES)).isRequired,
  displayName: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired
})

const datasetImporter = PropTypes.shape({
  format: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  parameters: PropTypes.arrayOf(parameter)
})

const datasetReader = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  writers: PropTypes.arrayOf(PropTypes.string)
})

const datasetWriter = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  datasetFormat: PropTypes.string,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      dataType: PropTypes.string,
      required: PropTypes.bool
    })
  )
})

const user = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  tenantName: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string)
})

const projectAccessLevel = PropTypes.oneOf(Object.values(ACCESS_LEVELS))

const project = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  accessLevel: projectAccessLevel,
  description: PropTypes.string,
  members: PropTypes.arrayOf(projectMember),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  lastActivityAt: PropTypes.string
})

const dataset = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  datasetType: PropTypes.string
})

const recycleBinContent = PropTypes.exact({
  projects: PropTypes.arrayOf(project).isRequired,
  datasets: PropTypes.arrayOf(dataset).isRequired,
  datasetProjects: PropTypes.objectOf(project).isRequired
})

const support = PropTypes.exact({
  toEmail: PropTypes.string.isRequired,
  subject: PropTypes.string,
  body: PropTypes.string,
  portalLink: PropTypes.string
})

const tutorial = PropTypes.exact({
  link: PropTypes.string.isRequired,
  linkLibrary: PropTypes.string.isRequired,
  platformDocumentation: PropTypes.string.isRequired
})

const appPropTypes = {
  apiError,
  coordinateSystem,
  customerUser,
  dataset,
  datasetImporter,
  error,
  exportData,
  exporter,
  importData,
  importForm,
  language,
  loginConfig,
  mduiPlacements,
  mduiSizes,
  parameter,
  project,
  projectAccessLevel,
  projectCapabilities,
  projectMember,
  recycleBinContent,
  role,
  upload,
  user,
  support,
  tutorial,
  datasetReader,
  datasetWriter
}

export default appPropTypes
