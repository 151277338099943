/* eslint-disable no-console */
import {
  GET_EXPORT_INFO_DELAY,
  EXPORT_REMOVE_ON_DONE_DELAY
} from '../shared/constants'
import { call, put, takeEvery, delay } from 'redux-saga/effects'
import { mapUserConvertDataToConvertApiBody } from '../helpers/import'
import {
  transferConvert,
  TRANSFER_STATUSES,
  getTransfer
} from '../apis/transfer'
import {
  CONVERT_DATASET,
  convertStart,
  updateConversionProgress,
  convertComplete,
  convertFail,
  removeConvert
} from '../actions/converts'

const TAG = '@saga.converts'

export default function* watchConverts() {
  yield takeEvery(CONVERT_DATASET, convertDataset)
}

export function* convertDataset(action) {
  console.log(TAG, action.id, 'New conversion of existing dataset...', {
    action
  })

  const { projectId, datasetId, data, datasetName } = action
  let result
  try {
    result = yield call(
      transferConvert,
      datasetId,
      mapUserConvertDataToConvertApiBody(data, projectId)
    )
    const id = datasetId
    const title = 'Converting (' + datasetName + ')'
    yield put(convertStart(id, title))
    console.log(TAG, id, 'Start conversion...', { result })

    let info
    while (true) {
      info = yield call(getTransfer, result.id)
      console.log(TAG, id, 'Exporting state...', { info })

      if (info.status === TRANSFER_STATUSES.IN_PROGRESS) {
        yield put(updateConversionProgress(id, 50, title))
      } else if (info.status === TRANSFER_STATUSES.ERROR) {
        throw new Error(info.errorMessage)
      } else if (info.status === TRANSFER_STATUSES.COMPLETED) {
        break
      }

      yield delay(GET_EXPORT_INFO_DELAY)
    }

    console.log(TAG, id, 'Conversion completed!')

    yield put(convertComplete(id))
    yield delay(EXPORT_REMOVE_ON_DONE_DELAY)
    yield put(removeConvert(id))
  } catch (error) {
    console.error(TAG, datasetId, 'Conversion failed!', error)
    yield put(convertFail(datasetId, error))
  }
}
