import produce from 'immer'

import { SET_USER } from '../actions/auth'
import { SET_CURRENT_TENANT_NAME } from '../actions/tenant'
import IUserProfile from '../model/IUserProfile'

type AuthState = {
  user: IUserProfile | null
}

const initState: AuthState = { user: null }

export default produce((draft, action) => {
  switch (action.type) {
    case SET_USER:
      draft.user = action.user
      return

    case SET_CURRENT_TENANT_NAME:
      if (draft.user) {
        draft.user.tenantName = action.name
      }
      return

    default:
      return
  }
}, initState)
