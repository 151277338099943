import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StateEnum } from '../model/StateEnum'

export type ProjectsMapType = {
  [key: string]: string
}

export type ProjectNamesState = {
  projects: ProjectsMapType
  state: StateEnum
}

const initState = {
  projects: {},
  state: StateEnum.INIT
} as ProjectNamesState

const projectNamesSlice = createSlice({
  name: 'projectNames',
  initialState: initState as ProjectNamesState,
  reducers: {
    setProjectNamesLoadingState: (
      state,
      action: PayloadAction<{ loadingState: boolean }>
    ): ProjectNamesState => {
      return {
        ...state,
        state: action.payload.loadingState
          ? StateEnum.LOADING
          : StateEnum.LOADED
      }
    },
    setProjectNames: (state, action: PayloadAction<ProjectsMapType>) => {
      return {
        ...state,
        projects: action.payload
      }
    },
    appendProjectNames: (state, action: PayloadAction<ProjectsMapType>) => {
      return {
        ...state,
        projects: {
          ...state.projects,
          ...action.payload
        }
      }
    }
  }
})

export const {
  setProjectNamesLoadingState,
  setProjectNames,
  appendProjectNames
} = projectNamesSlice.actions

export default projectNamesSlice.reducer
