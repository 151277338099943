import keyBy from 'lodash/keyBy'
import { call, put, takeLatest } from 'redux-saga/effects'

import { getCustomerUsers } from '../apis/customerUsers'

import {
  LOAD_CUSTOMER_USERS,
  setCustomerUsers,
  loadingCustomerUsers
} from '../actions/customerUsers'

import { addError } from '../actions/errors'

export default function* watchCustomerUsers() {
  yield takeLatest(LOAD_CUSTOMER_USERS, loadCustomerUsers)
}

export function* loadCustomerUsers(action) {
  try {
    yield put(loadingCustomerUsers())

    const users = yield call(getCustomerUsers, action.searchText)
    const userById = keyBy(users, 'id')
    yield put(setCustomerUsers(userById))
  } catch (error) {
    yield put(addError(LOAD_CUSTOMER_USERS, error))
  } finally {
    yield put(loadingCustomerUsers(false))
  }
}
