import { metadata } from './support'
import { RootProjectListQueryParams } from '../reducers/rootProjectList'

export const getRootProjectList = (params: RootProjectListQueryParams) => {
  const offset = params.page * params.rowsPerPage
  const query = new URLSearchParams({
    offset: offset.toString(),
    limit: params.rowsPerPage.toString(),
    sortBy: params.orderBy,
    sortOrder: params.sortOrder,
    namePrefix: params.namePrefix
  })
  return metadata.getJson(`metadata/project/offset-list?${query.toString()}`, 3)
}

export const getProjects = async (
  newOffset,
  newLimit,
  sortBy,
  sortOrder,
  namePrefix,
  my = false
) => {
  const roles = my ? '&role=Owner&role=Contributor&role=Reader' : '' //,Contributor,Reader
  const query =
    `?offset=${newOffset}&limit=${newLimit}&sortBy=${sortBy}&sortOrder=${sortOrder}&namePrefix=${namePrefix}` +
    roles
  const { data, totalCount, offset, limit } = await metadata.getJson(
    `metadata/project/offset-list${query}`,
    3
  )
  return { data: data, totalCount: totalCount, offset: offset, limit: limit }
}

export const postProject = data =>
  metadata.postJson('metadata/project', data, 3)

export const postSubProject = (data, projectId) =>
  metadata.postJson(`metadata/project/${projectId}/subproject`, data, 3)

export const putProject = data => metadata.putJson('metadata/project', data, 3)

export const patchProject = (id, data, versionId) =>
  metadata.patchJson(
    'metadata/project',
    { id, ...data, rowVersion: versionId },
    3
  )

export const delProject = async id =>
  await metadata.delJson(`metadata/project/${id}`, 3)

export const moveProject = (projectId, parentProjectId) => {
  const data = { targetProjectId: parentProjectId }
  metadata.postJson(`metadata/project/${projectId}/move`, data, 3)
}
