import { call, put, takeLatest, takeEvery, all, fork } from 'redux-saga/effects'

import {
  getUsers,
  delUser,
  getTenantUsers,
  inviteUserApi,
  postUsers,
  getProject,
  delUserIam
} from '../apis/admin'

import {
  LOAD_USERS,
  LoadUsersType,
  UPDATE_USER,
  DELETE_USERS,
  loadUsers,
  InviteUserType,
  INVITE_USER,
  UpdateUserType,
  DeleteUsersType
} from '../actions/users'

import { addError } from '../actions/errors'

import { notify } from '../helpers/saga'

import ITenantUser from '../model/ITenantUser'
import { setUsers, setLoadingUsers } from '../reducers/users'

export default function* watchUsers() {
  yield takeEvery(UPDATE_USER, updateUser)
  yield takeEvery(DELETE_USERS, deleteUsers)
  yield takeLatest(LOAD_USERS, fetchUsers)
  yield takeEvery(INVITE_USER, inviteUser)
}

export function* fetchUsers(action: LoadUsersType) {
  try {
    yield put(setLoadingUsers({ loadingUsers: true }))
    const tenantUsers = (yield call(getTenantUsers)).users
    const users = (yield call(getUsers, action.tenantId)).data
    const filteredUsers = users.filter(u => u.principalType === 'UserIdentity')
    const tUsers: ITenantUser[] = filteredUsers.map(user => {
      const tUser = tenantUsers.find(
        tu =>
          tu.userIdentityId === user.userId &&
          user.principalType === 'UserIdentity'
      )

      return {
        id: tUser?.userIdentityId,
        email: tUser?.email,
        name: tUser?.name,
        role: user?.role
      }
    })
    yield put(setUsers({ users: tUsers }))
  } catch (error) {
    yield put(addError(LOAD_USERS, error))
  } finally {
    yield put(setLoadingUsers({ loadingUsers: false }))
  }
}

export function* updateUser(action: UpdateUserType) {
  try {
    yield put(setLoadingUsers({ loadingUsers: true }))
    const project = yield call(getProject, action.tenantId)

    const users = project.members.map(user => {
      if (user.userId === action.user.id) {
        return action.user
      }
      return {
        id: user.userId,
        role: user.role
      }
    })

    yield call(postUsers, users, project.rowVersion, action.tenantId)
    yield put(loadUsers(action.tenantId))

    yield fork(notify, `User "${action.user.name}" was updated!` as any)
  } catch (error) {
    yield put(addError(UPDATE_USER, error))
  } finally {
    yield put(setLoadingUsers({ loadingUsers: false }))
  }
}

export function* deleteUsers(action: DeleteUsersType) {
  const { users, tenantId } = action

  try {
    yield all(users.map(user => call(delUser, user.id, tenantId)))
    yield all(users.map(user => call(delUserIam, user.id)))

    yield put(loadUsers(tenantId))
    yield fork(
      notify,
      users.length > 1
        ? `${users.length} users were deleted!`
        : ('The user was deleted!' as any)
    )
  } catch (error) {
    yield put(addError(DELETE_USERS, error))
  }
}

export function* inviteUser(action: InviteUserType) {
  try {
    yield call(
      inviteUserApi,
      action.email,
      action.role,
      action.redirectUri,
      action.tenantId
    )
  } catch (error) {
    yield put(addError(INVITE_USER, error))
  }
}
