import { SiteCpuUsageQueryParams } from '../reducers/siteCpuUsage'

export const LOAD_SITE_CPU_USAGE = 'CPU-USAGE/LOAD'
export type LoadSiteCpuUsageType = {
  type: string
  tenantId: string
  params: SiteCpuUsageQueryParams
}
export const loadSiteCpuUsage = (tenantId, params) =>
  ({
    type: LOAD_SITE_CPU_USAGE,
    tenantId,
    params
  } as LoadSiteCpuUsageType)
