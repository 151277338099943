import { parseError } from '../helpers/errors'

export const CONVERT_DATASET = 'CONVERTS/CONVERT_DATASET'
export const convertDataset = (projectId, datasetId, data, datasetName) => ({
  type: CONVERT_DATASET,
  projectId,
  datasetId,
  data,
  datasetName
})

export const CONVERT_START = 'CONVERTS/CONVERT_START'
export const convertStart = (id, title) => ({
  type: CONVERT_START,
  id,
  title
})

export const UPDATE_CONVERSION_PROGRESS = 'CONVERTS/UPDATE_PROGRESS'
export const updateConversionProgress = (id, progress, title) => ({
  type: UPDATE_CONVERSION_PROGRESS,
  id,
  progress,
  title
})

export const CONVERT_COMPLETE = 'CONVERTS/CONVERT_COMPLETE'
export const convertComplete = id => ({
  type: CONVERT_COMPLETE,
  id
})

export const CONVERT_FAIL = 'CONVERTS/CONVERT_FAIL'
export const convertFail = (id, error) => ({
  type: CONVERT_FAIL,
  id,
  error: parseError(error, { id })
})

export const REMOVE_CONVERT = 'CONVERTS/REMOVE_CONVERT'
export const removeConvert = id => ({
  type: REMOVE_CONVERT,
  id
})
