import {
  SET_CREDIT_LOADING,
  SET_CREDIT_TENANT,
  SET_CREDIT_UNASSIGNED_EXT,
  SET_CREDIT_UNASSIGNED,
  SET_CREDIT_DETAILS_EXT,
  SET_CREDIT_DETAILS,
  SET_CREDIT_AVAILABLE,
  SET_CREDIT_AVAILABLE_INFO_DATA
} from '../actions/credits'
import {
  ICreditsUnassignedResponse,
  ITenantCreditsDetailsExtResponse,
  ITenantCreditsDetailsResponse
} from '../model/ICredits'

export enum StateEnum {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR'
}

export type GroupState = {
  creditTenant: ICreditsUnassignedResponse
  creditUnassigned: ICreditsUnassignedResponse
  creditUnassignedExt: ITenantCreditsDetailsResponse
  creditDetails: ITenantCreditsDetailsResponse
  creditDetailsExt: ITenantCreditsDetailsExtResponse
  creditAvailable: any // TODO: jaky typ?
  creditAvailableInfoData: any // TODO: jaky typ?
  loadingState: boolean
}

const initState = {
  creditTenant: {},
  creditUnassigned: {},
  creditUnassignedExt: {},
  creditDetails: {},
  creditDetailsExt: {},
  creditAvailable: '',
  creditAvailableInfoData: {},
  loadingState: false
} as GroupState

const credits = (state = initState, action) => {
  switch (action.type) {
    case SET_CREDIT_LOADING:
      return { ...state, loadingState: action.data }
    case SET_CREDIT_DETAILS:
      return { ...state, creditDetails: action.data }
    case SET_CREDIT_DETAILS_EXT:
      return { ...state, creditDetailsExt: action.data }
    case SET_CREDIT_UNASSIGNED:
      return { ...state, creditUnassigned: action.data }
    case SET_CREDIT_UNASSIGNED_EXT:
      return { ...state, creditUnassignedExt: action.data }
    case SET_CREDIT_TENANT:
      return { ...state, creditTenant: action.data }
    case SET_CREDIT_AVAILABLE:
      return { ...state, creditAvailable: action.data }
    case SET_CREDIT_AVAILABLE_INFO_DATA:
      return { ...state, creditAvailableInfoData: action.data }

    default:
      return state
  }
}

export default credits
