import { parseError } from '../helpers/errors'

export const IMPORT_FILE = 'IMPORTS/IMPORT_FILE'
export const importFile = (id, fileName, fileUrl, projectId, data) => ({
  type: IMPORT_FILE,
  id,
  fileName,
  fileUrl,
  projectId,
  data
})

export const IMPORT_START = 'IMPORTS/IMPORT_START'
export const importStart = (
  id,
  createdBy,
  projectId,
  state,
  data,
  initialId = null
) => ({
  type: IMPORT_START,
  id,
  createdBy,
  projectId,
  state,
  data,
  initialId
})

export const UPDATE_PROGRESS = 'IMPORTS/UPDATE_PROGRESS'
export const updateProgress = (id, progress) => ({
  type: UPDATE_PROGRESS,
  id,
  progress
})

export const IMPORT_COMPLETE = 'IMPORTS/IMPORT_COMPLETE'
export const importComplete = id => ({
  type: IMPORT_COMPLETE,
  id
})

export const IMPORT_FAIL = 'IMPORTS/IMPORT_FAIL'
export const importFail = (id, error) => ({
  type: IMPORT_FAIL,
  id,
  error: parseError(error, { id })
})

export const REMOVE_IMPORT = 'IMPORTS/REMOVE_IMPORT'
export const removeImport = id => ({
  type: REMOVE_IMPORT,
  id
})
