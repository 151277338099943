import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IFeature from '../model/IFeature'

export enum StateEnum {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR'
}

export type GroupState = {
  features: Array<IFeature>
  loadingState: StateEnum
}

const initState = {
  features: [],
  loadingState: StateEnum.INIT
} as GroupState

const featuresSlice = createSlice({
  name: 'featuresSlice',
  initialState: initState as GroupState,
  reducers: {
    setFeaturesLoadingState: (
      state,
      action: PayloadAction<{ loadingState: boolean }>
    ): GroupState => {
      return {
        ...state,
        loadingState: action.payload.loadingState
          ? StateEnum.LOADING
          : StateEnum.LOADED
      }
    },
    setFeaturesList: (
      state,
      action: PayloadAction<{
        features: IFeature[]
      }>
    ): GroupState => {
      return {
        ...state,
        features: action.payload.features,
        loadingState: StateEnum.LOADED
      }
    }
  }
})

export const {
  setFeaturesList,
  setFeaturesLoadingState
} = featuresSlice.actions

export default featuresSlice.reducer
