import produce from 'immer'
import findIndex from 'lodash/fp/findIndex'
import { ITrackProgressItem } from '../model/ITrackProgressItem'
import {
  REMOVE_PROGRESS_ITEM,
  UPDATE_OR_ADD_PROGRESS_ITEM,
  PROGRESS_ITEM_FAILED,
  PROGRESS_ITEM_COMPLETE
} from '../actions/progressItems'

const progressItems = produce((draft, action) => {
  const { type } = action
  if (
    ![
      UPDATE_OR_ADD_PROGRESS_ITEM,
      PROGRESS_ITEM_COMPLETE,
      PROGRESS_ITEM_FAILED,
      REMOVE_PROGRESS_ITEM
    ].includes(type)
  ) {
    return
  }

  const progressItem = findOrCreateProgressItem(draft, action)

  switch (action.type) {
    case UPDATE_OR_ADD_PROGRESS_ITEM: {
      progressItem.progressValue = action.progressItem.progressValue
      progressItem.title =
        action.progressItem.title && action.progressItem.title
      progressItem.importId =
        action.progressItem.importId && action.progressItem.importId
      return
    }

    case PROGRESS_ITEM_COMPLETE: {
      progressItem.progressValue = 100
      progressItem.done = true
      return
    }

    case PROGRESS_ITEM_FAILED: {
      progressItem.error = true
      progressItem.title = 'Error in ' + progressItem.title
      return
    }

    case REMOVE_PROGRESS_ITEM: {
      removeProgressItem(progressItem.id)(draft)
      return
    }

    default:
      return
  }
}, Array<ITrackProgressItem>())

const findOrCreateProgressItem = (draft, action) => {
  var progressItem = findProgressItem(action.progressItem)(draft)

  if (!progressItem) {
    const item: ITrackProgressItem = {
      id: action.progressItem.id,
      title: action.progressItem.title,
      progressValue: 0,
      error: false,
      done: false
    }
    draft.push(item)
    return item
  }

  return progressItem
}

const findProgressItem = (item: ITrackProgressItem) => (
  draft: ITrackProgressItem[]
) =>
  draft.find(
    (p: ITrackProgressItem) =>
      p.id === item.id || (p.importId && p.importId === item.id)
  )

const removeProgressItem = id => draft =>
  draft.splice(findIndex({ id })(draft), 1)

export default progressItems
