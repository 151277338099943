/* eslint-disable react/prop-types */
import React from 'react'

import {
  makeStyles,
  createStyles,
  Typography,
  Grid,
  Button
} from '@material-ui/core'
import { IApplication } from '../../model/Applications'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import MikeAppIcon, {
  AppIconName
} from '@mike/mike-shared-frontend/mike-icon/MikeAppIcon'
import { appIconName, projectQueryParameter } from './configuration'

interface IProps {
  application: IApplication
  projectId: string
  linkToRootUrl: boolean
  tenantId: string
  disabled?: boolean
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    button: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '12px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
      height: theme.spacing(10),
      width: theme.spacing(10),
      '&:hover': {
        backgroundColor: theme.palette.primary,
        boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.16)'
      }
    },
    appName: {
      width: theme.spacing(16),
      paddingTop: theme.spacing(1)
    },
    appNameWithError: {
      paddingTop: theme.spacing(1)
    },
    status: {
      paddingLeft: theme.spacing(0.5)
    },
    warnIcon: {
      height: 14.68,
      width: 13
    },
    ellipsis: {
      maxWidth: theme.spacing(12),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  })
})

const MikeCloudApp = (props: IProps) => {
  const classes = useStyles()
  const { application, projectId, linkToRootUrl, tenantId, disabled } = props

  const iconName: AppIconName =
    appIconName(application.name) !== undefined
      ? appIconName(application.name)
      : 'genericApp'

  const queryParameter = linkToRootUrl
    ? '?tenantId=' + tenantId
    : application.name === 'MIKE 3D World'
    ? projectQueryParameter('MIKE 3D World', projectId, tenantId)
    : projectQueryParameter(application.name, projectId, tenantId)

  return (
    <Grid container direction="column" alignItems="center" spacing={0}>
      <Button
        disabled={disabled}
        className={classes.button}
        target="_blank"
        href={application.url && application.url + queryParameter}
      >
        {iconName && application.name === 'MIKE Mine' ? (
          <img
            srcSet={`${process.env.PUBLIC_URL}/icons/mike-mine.svg`}
            width={56}
            alt="ProductLogo"
          />
        ) : (
          <MikeAppIcon name={iconName} />
        )}
      </Button>

      <Grid
        className={classes.appName}
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography variant="body2" className={classes.ellipsis}>
            {application.name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MikeCloudApp
