import { metadata } from './support'

export const restoreRecycleBinProject = id => {
  return metadata.putJson(`recycle-bin/project/${id}/restore`, null, 2)
}

export const restoreRecycleBinDataset = id => {
  return metadata.putJson(`recycle-bin/dataset/${id}/restore`, null, 2)
}

// Currently only version 1 available
export const getRecycleBinItemsDeletedByMe = async (
  offset,
  limit,
  sortBy,
  sortOrder
) => {
  const query = `?offset=${offset}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`
  const { data, totalCount } = await metadata.getJson(
    `recycle-bin/my-deleted-items${query}`,
    1
  )
  return { data: data, offset: offset, limit: limit, totalCount: totalCount }
}
