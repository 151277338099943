import { makeStyles, createStyles } from '@material-ui/core'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { PaletteColor } from '@material-ui/core/styles/createPalette'

export const useDialogContentStyles = makeStyles((theme: IMikeTheme) => {
  const mediumGrey = theme.palette.mediumGrey as PaletteColor
  const darkGrey = theme.palette.darkGrey as PaletteColor
  return createStyles({
    dialogContent: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(10),
      minWidth: theme.spacing(70),
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: `${darkGrey.main} ${mediumGrey.main}`,
      '&::-webkit-scrollbar': {
        width: 10,
        borderRadius: '4px 4px',
        zIndex: 1
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px 4px',
        backgroundColor: darkGrey.main,
        zIndex: 1
      }
    },
    dialogActions: {
      zIndex: 11,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.background.paper,
      boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.16)',
      height: theme.spacing(8),
      width: '100%'
    },
    button: {
      margin: theme.spacing(1)
    },
    form: {
      padding: theme.spacing(0)
    },
    title: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1)
    }
  })
})
