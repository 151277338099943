import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    display: 'inline-block',
    whiteSpace: 'nowrap'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  }
})

const Text = ({ classes, icon: Icon, children }) => (
  <div className={classes.root}>
    {Icon && <Icon className={classes.icon} />}
    {children}
  </div>
)
Text.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.any,
  children: PropTypes.any.isRequired
}

export default withStyles(styles)(Text)
