/* eslint-disable react/prop-types */
import React from 'react'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { ReactComponent as UploadCloud } from '@mike/mike-shared-frontend/media/icons/UploadCloud'
import Renders from './renders'
import {
  makeStyles,
  createStyles,
  Typography,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    dragArea: (props: IProps) => ({
      height: props.height && props.height,
      backgroundImage: props.dragOver
        ? ''
        : 'repeating-linear-gradient(to right, ' +
          theme.palette.secondary.main +
          ' 0%, ' +
          theme.palette.secondary.main +
          ' 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to right, ' +
          theme.palette.secondary.main +
          ' 0%, ' +
          theme.palette.secondary.main +
          ' 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, ' +
          theme.palette.secondary.main +
          ' 0%, ' +
          theme.palette.secondary.main +
          ' 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, ' +
          theme.palette.secondary.main +
          ' 0%, ' +
          theme.palette.secondary.main +
          ' 50%, transparent 50%, transparent 100%)',
      backgroundPosition: props.dragOver
        ? ''
        : 'left top, left bottom, left top, right top',
      backgroundRepeat: props.dragOver
        ? ''
        : 'repeat-x, repeat-x, repeat-y, repeat-y',
      backgroundSize: props.dragOver
        ? ''
        : '20px 3px, 20px 3px, 3px 20px, 3px 20px',
      borderTop: props.dragOver
        ? '3px solid ' + theme.palette.secondary.main
        : 'none',
      borderBottom: props.dragOver
        ? '3px solid ' + theme.palette.secondary.main
        : 'none',
      borderLeft: props.dragOver
        ? '3px solid ' + theme.palette.secondary.main
        : 'none',
      backgroundColor: !props.dragOver
        ? theme.palette.background.paper
        : theme.palette.mediumGrey && theme.palette.mediumGrey.main,
      opacity: props.dragOver ? 0.8 : 1
    }),
    dragLabel: {
      color: theme.palette.secondary.main
    },
    firstColumn: {
      paddingLeft: theme.spacing(4)
    },
    lastColumn: {
      paddingRight: theme.spacing(4)
    },
    dragIconLabelContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })
})

interface IProps {
  columns: Array<any>
  dragOver: boolean
  label?: string
  height?: string
}

const TableBodyDragAndDropArea = (props: IProps) => {
  const classes = useStyles(props)
  const { columns, label = 'Drop to upload' } = props

  const [columsMid, setColumsMid] = React.useState(0)

  React.useEffect(() => {
    setColumsMid(Math.floor(columns.length / 2) - 1)
  }, [columns])

  return (
    <TableBody>
      <TableRow role="checkbox" tabIndex={-1} className={classes.dragArea}>
        {columns.map((_head, index) => {
          return (
            <TableCell
              padding={'checkbox'} //index === 0 ? 'checkbox' : 'default'}
              key={index}
              className={
                index === 0
                  ? classes.firstColumn
                  : index === columns.length - 1
                  ? classes.lastColumn
                  : ''
              }
            >
              {index === columsMid && (
                <div className={classes.dragIconLabelContainer}>
                  <UploadCloud className={Renders.iconActionStyle()} />
                  <Typography className={classes.dragLabel} variant="body2">
                    {label}
                  </Typography>
                </div>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableBody>
  )
}

export default TableBodyDragAndDropArea
