import React, { useMemo } from 'react'
import messages from '../../shared/messages'
import { ReactComponent as MyProjects } from '@mike/mike-shared-frontend/media/icons/MyProjects'
import { ReactComponent as TableResults } from '@mike/mike-shared-frontend/media/icons/TableResults'
import { ReactComponent as Users } from '@mike/mike-shared-frontend/media/icons/Users'
import { Tabs, Tab } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { LinkTab } from '../AppNav'

const useAppNavStyles = makeStyles(theme =>
  createStyles({
    tabsIndicator: {
      height: 3
    },
    hiddenTabRoot: {
      minWidth: 0,
      padding: 0,
      margin: 0,
      minHeight: 48
    },
    activeLabel: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main
    },
    svg: {
      filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)'
    }
  })
)

interface IProps {
  showSiteAccess?: boolean
}

const IAMNav = (props: IProps) => {
  const { showSiteAccess } = props
  const intl = useIntl()
  const classes = useAppNavStyles()

  function usePath() {
    return useLocation().pathname
  }

  let currentPath = usePath()

  const activeTab = useMemo(() => {
    if (currentPath.startsWith('/site-access')) {
      return 2
    }

    if (currentPath.startsWith('/site-info')) {
      return 3
    }

    if (
      currentPath.startsWith('/site') ||
      currentPath.startsWith('/project') ||
      currentPath.startsWith('/recycle-bin')
    ) {
      return 1
    }

    return 0
  }, [currentPath])

  return (
    <Tabs
      classes={{ indicator: classes.tabsIndicator }}
      value={activeTab}
      indicatorColor="secondary"
      textColor="primary"
      centered
    >
      <Tab classes={{ root: classes.hiddenTabRoot }} />
      <LinkTab
        to={'/site'}
        label={
          <span className={activeTab === 1 ? classes.activeLabel : ''}>
            {intl.formatMessage(messages.data)}
          </span>
        }
        icon={<MyProjects className={activeTab === 1 ? classes.svg : ''} />}
      />
      {showSiteAccess ? (
        <LinkTab
          to={'/site-access'}
          label={
            <span className={activeTab === 2 ? classes.activeLabel : ''}>
              {intl.formatMessage(messages.tenantAccess)}
            </span>
          }
          icon={<Users className={activeTab === 2 ? classes.svg : ''} />}
        />
      ) : (
        <Tab classes={{ root: classes.hiddenTabRoot }} />
      )}
      {showSiteAccess ? (
        <LinkTab
          to={'/site-info'}
          label={
            <span className={activeTab === 3 ? classes.activeLabel : ''}>
              {intl.formatMessage(messages.tenantInfo)}
            </span>
          }
          icon={<TableResults className={activeTab === 3 ? classes.svg : ''} />}
        />
      ) : (
        <Tab classes={{ root: classes.hiddenTabRoot }} />
      )}
    </Tabs>
  )
}

export default IAMNav
