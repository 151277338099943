import React from 'react'
import PropTypes from 'prop-types'

const BASE_COLOR = '#00A4EC'

const CreditsIcon = ({ colorR = BASE_COLOR, fillR = true, ...props }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 16C23.4705 16 27.5 18.1824 27.5 20.875V24.125C27.5 26.8176 23.4705 29 18.5 29C13.6179 29 9.644 26.8948 9.50409 24.2688L9.5 24.125V20.875C9.5 18.1824 13.5295 16 18.5 16ZM18.5 25.75C15.4564 25.75 12.7645 24.931 11.1364 23.6781V24.125C11.1364 25.6541 14.3134 27.375 18.5 27.375C22.5991 27.375 25.7311 25.7256 25.8595 24.2209L25.8636 24.125L25.8645 23.6781C24.2363 24.931 21.5445 25.75 18.5 25.75ZM18.5 17.625C14.3134 17.625 11.1364 19.3459 11.1364 20.875C11.1364 22.4041 14.3134 24.125 18.5 24.125C22.6866 24.125 25.8636 22.4041 25.8636 20.875C25.8636 19.3459 22.6866 17.625 18.5 17.625Z"
      fill={colorR ? colorR : BASE_COLOR}
    />
    <path
      d="M18.5 25.75C15.4564 25.75 12.7645 24.931 11.1364 23.6781V24.125C11.1364 25.6541 14.3134 27.375 18.5 27.375C22.5991 27.375 25.7311 25.7256 25.8595 24.2209L25.8636 24.125L25.8645 23.6781C24.2363 24.931 21.5445 25.75 18.5 25.75Z"
      fill={fillR ? '#C9ECFB' : '#DBE4E9'}
    />
    <path
      d="M18.5 17.625C14.3134 17.625 11.1364 19.3459 11.1364 20.875C11.1364 22.4041 14.3134 24.125 18.5 24.125C22.6866 24.125 25.8636 22.4041 25.8636 20.875C25.8636 19.3459 22.6866 17.625 18.5 17.625Z"
      fill={fillR ? '#C9ECFB' : '#DBE4E9'}
    />
    <path
      d="M21.5 11C26.4705 11 30.5 13.1824 30.5 15.875V19.125C30.5 21.8176 26.4705 24 21.5 24C16.6179 24 12.644 21.8948 12.5041 19.2688L12.5 19.125V15.875C12.5 13.1824 16.5295 11 21.5 11ZM21.5 20.75C18.4564 20.75 15.7645 19.931 14.1364 18.6781V19.125C14.1364 20.6541 17.3134 22.375 21.5 22.375C25.5991 22.375 28.7311 20.7256 28.8595 19.2209L28.8636 19.125L28.8645 18.6781C27.2363 19.931 24.5445 20.75 21.5 20.75ZM21.5 12.625C17.3134 12.625 14.1364 14.3459 14.1364 15.875C14.1364 17.4041 17.3134 19.125 21.5 19.125C25.6866 19.125 28.8636 17.4041 28.8636 15.875C28.8636 14.3459 25.6866 12.625 21.5 12.625Z"
      fill={colorR ? colorR : BASE_COLOR}
    />
    <path
      d="M21.5 20.75C18.4564 20.75 15.7645 19.931 14.1364 18.6781V19.125C14.1364 20.6541 17.3134 22.375 21.5 22.375C25.5991 22.375 28.7311 20.7256 28.8595 19.2209L28.8636 19.125L28.8645 18.6781C27.2363 19.931 24.5445 20.75 21.5 20.75Z"
      fill={fillR ? '#C9ECFB' : '#DBE4E9'}
    />
    <path
      d="M21.5 12.625C17.3134 12.625 14.1364 14.3459 14.1364 15.875C14.1364 17.4041 17.3134 19.125 21.5 19.125C25.6866 19.125 28.8636 17.4041 28.8636 15.875C28.8636 14.3459 25.6866 12.625 21.5 12.625Z"
      fill={fillR ? '#C9ECFB' : '#DBE4E9'}
    />
  </svg>
  //   </SvgIcon>
)
CreditsIcon.propTypes = {
  colorR: PropTypes.string,
  fillR: PropTypes.bool
}
export default CreditsIcon
