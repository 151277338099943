import uniqid from 'uniqid'

export const UPLOAD_AND_IMPORT_FILE = 'UPLOAD_AND_IMPORT_FILE'
export const uploadAndImportFile = (projectId, data) => ({
  type: UPLOAD_AND_IMPORT_FILE,
  id: uniqid.time(),
  projectId,
  data
})

export const UPLOAD_AND_IMPORT_MANY_FILES = 'UPLOAD_AND_IMPORT_MANY_FILES'
export const uploadAndImportManyFiles = (projectId, files) => ({
  type: UPLOAD_AND_IMPORT_MANY_FILES,
  projectId,
  files
})
