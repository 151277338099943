import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'
const BASE_COLOR = '#00A4EC'

const ReturnCreditsIcon = ({ colorR = BASE_COLOR, ...props }) => (
  <SvgIcon {...props} viewBox="-3 0 24 24">
    <path
      fill={colorR ? colorR : BASE_COLOR}
      d="M16 20L16 10L18 10L18 20C18 21.1 17.1 22 16 22L2 22C0.9 22 7.86828e-08 21.1 1.74846e-07 20L1.04907e-06 10L2 10L2 20L16 20Z"
    />
    <path
      fill={colorR ? colorR : BASE_COLOR}
      d="M8.91498 3.98847C7.82415 4.77386 6.99928 6.07408 6.99928 8L6.99928 15.5L8.99928 15.5L8.99928 8C8.99928 6.72592 9.50775 6.02614 10.0836 5.61153C10.7104 5.16022 11.4926 5 11.9993 5L11.9993 3C11.1726 3 9.95482 3.23978 8.91498 3.98847Z"
    />
    <path
      fill={colorR ? colorR : BASE_COLOR}
      d="M12.9993 8L17.9993 4L12.9993 -4.37177e-07L12.9993 8Z"
    />
    <path
      fill={colorR ? colorR : BASE_COLOR}
      d="M13.9993 5L11.9993 5L11.9993 3L13.9993 3L13.9993 5Z"
    />
  </SvgIcon>
)
ReturnCreditsIcon.propTypes = {
  colorR: PropTypes.string
}
export default ReturnCreditsIcon
