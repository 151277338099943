import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>
  createStyles({
    searchField: {
      padding: theme.spacing(1 / 4, 2),
      width: 320,
      height: 40,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '25px',
      border: '1px solid ' + theme.palette.devider.default
    }
  })
)

const useTextChange = onTextChange => [
  event => onTextChange(event.target.value),
  () => onTextChange('')
]

const SearchInput = ({ text, onTextChange, ...inputProps }) => {
  const classes = useStyles()
  const [handleTextChange, resetTextChange] = useTextChange(onTextChange)

  return (
    <Input
      {...inputProps}
      className={classes.searchField}
      disableUnderline={true}
      value={text}
      onChange={handleTextChange}
      autoComplete={'off'}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        text && (
          <InputAdornment position="end">
            <IconButton onClick={resetTextChange}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  )
}
SearchInput.propTypes = {
  text: PropTypes.string,
  onTextChange: PropTypes.func
}

export default SearchInput
