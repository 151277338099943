import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { compose, pure } from 'recompose'

import { withStyles } from '@material-ui/core/styles'

const LinkRenderer = (configure, options = {}) => {
  return function renderLink(value, item) {
    const { text, to, icon } = configure(value, item)

    return <CellLink style={options.style} to={to} icon={icon} text={text} />
  }
}

export default LinkRenderer

const cellLinkStyle = theme => ({
  root: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    fontSize: 14,
    '&>span:hover': {
      textDecoration: 'underline'
    },
    '&>svg': {
      verticalAlign: 'middle',
      marginRight: theme.spacing(1)
    }
  }
})

const BaseCellLink = ({ style, classes, to, text, icon }) => (
  <Link
    style={style}
    className={classes.root}
    to={to}
    onClick={event => event.stopPropagation()}
  >
    {icon && React.cloneElement(icon)}
    <span>{text}</span>
  </Link>
)
BaseCellLink.propTypes = {
  classes: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  style: PropTypes.object
}

const CellLink = compose(withStyles(cellLinkStyle), pure)(BaseCellLink)
