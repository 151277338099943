import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import Button from '@material-ui/core/Button'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import messages from '../../shared/messages'

import { iconSecondaryStyle, useStyles } from './iconStyles'

const DeleteButton = (buttonProps: MuiButtonProps) => {
  const classes = useStyles()
  return (
    <Button className={classes.button} {...buttonProps}>
      <Delete className={iconSecondaryStyle(buttonProps.disabled)} />
      <FormattedMessage id={messages.delete.id} />
    </Button>
  )
}

export default DeleteButton
