import { SET_BILLING_INFORMATION } from '../actions/billing'
import { IBillingInformation } from '../apis/admin'

const initialState: IBillingInformation = {
  billingReference: '',
  billingReferenceTag: '',
  billingReferenceType: ''
}

const billing = (state = initialState, action) => {
  switch (action.type) {
    case SET_BILLING_INFORMATION:
      return { ...state, ...action.billingInformation }
    default:
      return state
  }
}

export default billing
