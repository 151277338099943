export const COORDINATE_SYSTEM_SEARCH_TEXT_MIN_LEN = 3

export const COORDINATE_SYSTEM_MAX_ITEMS = 60

export const COORDINATE_SYSTEM_MAX_DESCRIPTION_LEN = 60

export const DEFAULT_LINKNOTIFY_TIMEOUT = 5000

export const DEFAULT_NOTIFY_TIMEOUT = 3500

export const MONITOR_TRANSFERS_DELAY = 5000

export const MONITOR_TRANSFERS_ERROR_DELAY = 10000

export const MONITOR_TRANSFERS_TRACKING_DELAY = 5000

export const GET_EXPORT_INFO_DELAY = 5000

export const IMPORT_REMOVE_ON_DONE_DELAY = 3000

export const EXPORT_REMOVE_ON_DONE_DELAY = 3000

export const UPLOAD_REMOVE_ON_DONE_DELAY = 3000

export const DOWNLOAD_REMOVE_ON_DONE_DELAY = 3000

export const DRAWER_WIDTH = 240

export const MDUI_SIZES = ['tiny', 'small', 'medium', 'large']

export const MDUI_PLACEMENTS = [
  'bottom-end',
  'bottom-start',
  'bottom',
  'left-end',
  'left-start',
  'left',
  'right-end',
  'right-start',
  'right',
  'top-end',
  'top-start',
  'top'
]

export const PROJECT_TYPE = 'Project'
export const FOLDER_TYPE = 'Folder'
export const DATASET_TYPE = 'Dataset'

export const BACKOFFICE_ROLE = 'BackOffice'
