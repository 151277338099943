import { metadata } from './support'
import { mockDedicatedReaders } from '../components/DatasetConversionDialog/model/mockDedicatedReaders'
import { mockFileReaders } from '../components/DatasetConversionDialog/model/mockFileReaders'

export const getMockDedicatedDataseReaders = async () => {
  return mockDedicatedReaders.map(element => {
    return {
      id: element.name,
      ...element
    }
  })
}

export const getMockFileDatasetReaders = async () => {
  return mockFileReaders.map(element => {
    return {
      id: element.name,
      ...element
    }
  })
}
export const getDatasetReaders = async (readerType = '') => {
  const queryParameter = readerType ? '?filter=' + readerType : ''
  const { data } = await metadata.getJson(
    'conversion/reader/list' + queryParameter,
    2
  )
  return data.map(element => {
    return {
      id: element.name,
      ...element
    }
  })
}
