import identity from 'lodash/fp/identity'
import merge from 'lodash/merge'
import IUserProfile from '../../model/IUserProfile'

let integrationHeaders = {}

if (
  process.env.TEST_INTEGRATION ||
  process.env.REACT_APP__DHI_TARGET_ENV === 'localhost'
) {
  const env =
    process.env.REACT_APP__DHI_TARGET_ENV || process.env.CI_ENV || 'localhost'
  integrationHeaders = require(`./headers.integration-${env}`)
}

interface IApi {
  getJson: (
    resource: string,
    version?: number,
    headers?: object
  ) => Promise<any>
  postJson: (
    resource: string,
    payload?: object,
    version?: number,
    headers?: object
  ) => Promise<any>
  putJson: (
    resource: string,
    payload?: object,
    version?: number,
    headers?: object
  ) => Promise<any>
  patchJson: (
    resource: string,
    payload?: object,
    version?: number,
    headers?: object
  ) => Promise<any>
  delJson: (
    resource: string,
    version?: number,
    headers?: object
  ) => Promise<any>
}
export let metadata: IApi
export let cloudHomeBackend: IApi
export let coordinateSystem: IApi
export let administration: IApi
export let metadataRequest
export let postConsumptionRequest

export default function init(config) {
  const metadataUrl = config.metadataUrl
  if (!metadataUrl) {
    return
  }
  metadataRequest = {
    scopes: [config.resourceId + '/.default']
  }
  administration = {
    getJson: makeAdminServiceGet(metadataUrl),
    postJson: makeAdminServicePost(metadataUrl),
    putJson: makeAdminServicePut(metadataUrl),
    patchJson: makeAdminServicePatch(metadataUrl),
    delJson: makeAdminServiceDel(metadataUrl)
  }

  metadata = {
    getJson: makeMetadataGet(metadataUrl),
    postJson: makeMetadataPost(metadataUrl),
    putJson: makeMetadataPut(metadataUrl),
    patchJson: makeMetadataPatch(metadataUrl),
    delJson: makeMetadataDel(metadataUrl)
  }
  coordinateSystem = {
    getJson: makeGisServiceGet(metadataUrl),
    postJson: makeGisServicePost(metadataUrl),
    putJson: makeGisServicePut(metadataUrl),
    patchJson: makeGisServicePatch(metadataUrl),
    delJson: makeGisServiceDel(metadataUrl)
  }

  postConsumptionRequest = makePlainPost()
}

export const isApiError = error => error instanceof ApiError

export function getUserProfile(userData: any) {
  const user: IUserProfile = {
    id: userData.oid,
    tenantId: userData.TenantId,
    tenantName: userData.TenantName,
    name: userData.sub,
    initials: userData.sub
      .split(' ')
      .map(n => n[0])
      .join(''),
    email: userData.email,
    roles: userData[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ]
      ? [
          userData[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
          ]
        ]
      : []
  }
  return user
}

export function getIntegrationHeaders() {
  return integrationHeaders
}

function makeAdminServiceGet(baseUrl, getOptions = identity) {
  return async (resource, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = { headers }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200])
  }
}

function makeAdminServicePost(baseUrl, getOptions = identity) {
  return async (resource, payload, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = {
      method: 'POST',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [201, 200])
  }
}

function makeAdminServicePut(baseUrl, getOptions = identity) {
  return async (resource, payload, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = {
      method: 'PUT',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200, 204])
  }
}

function makeAdminServicePatch(baseUrl, getOptions = identity) {
  return async (resource, payload, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = {
      method: 'PATCH',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200])
  }
}

function makeAdminServiceDel(baseUrl, getOptions = identity) {
  return async (resource, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = {
      method: 'DELETE',
      headers
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [204])
  }
}

function makeGisServiceGet(baseUrl, getOptions = identity) {
  return async (resource, version = 1) => {
    const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
    const defaultOptions = { headers }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200])
  }
}

function makeGisServicePost(baseUrl, getOptions = identity) {
  return async (resource, payload, version = 1) => {
    const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
    const defaultOptions = {
      method: 'POST',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [201, 200])
  }
}

function makeGisServicePut(baseUrl, getOptions = identity) {
  return async (resource, payload, version = 1) => {
    const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
    const defaultOptions = {
      method: 'PUT',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200, 204])
  }
}

function makeGisServicePatch(baseUrl, getOptions = identity) {
  return async (resource, payload, version = 1) => {
    const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
    const defaultOptions = {
      method: 'PATCH',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200])
  }
}

function makeGisServiceDel(baseUrl, getOptions = identity) {
  return async (resource, version = 1) => {
    const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
    const defaultOptions = {
      method: 'DELETE',
      headers
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [204])
  }
}

function makeMetadataGet(baseUrl, getOptions = identity) {
  return async (resource, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(additionalheaders, version)
    const defaultOptions = { headers }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200])
  }
}

function makeMetadataPost(baseUrl, getOptions = identity) {
  return async (resource, payload, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(additionalheaders, version)
    const defaultOptions = {
      method: 'POST',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [201, 200, 204])
  }
}

function makePlainPost(getOptions = identity) {
  return async (url, payload, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(additionalheaders, version)
    const defaultOptions = {
      method: 'POST',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const resp = await fetch(url, options)
    return handleResponse(resp, [201, 200, 204])
  }
}

function makeMetadataPut(baseUrl, getOptions = identity) {
  return async (resource, payload, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(additionalheaders, version)
    const defaultOptions = {
      method: 'PUT',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200, 204])
  }
}

function makeMetadataPatch(baseUrl, getOptions = identity) {
  return async (resource, payload, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(additionalheaders, version)
    const defaultOptions = {
      method: 'PATCH',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200])
  }
}

function makeMetadataDel(baseUrl, getOptions = identity) {
  return async (resource, version = 1, additionalheaders = {}) => {
    const headers = buildHeaders(additionalheaders, version)
    const defaultOptions = {
      method: 'DELETE',
      headers
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [204])
  }
}

const buildHeaders = (otherHeader = {}, version = 1) =>
  merge(
    {
      'api-version': version,
      'Content-Type': 'application/json; charset=utf-8'
    },
    otherHeader,
    integrationHeaders
  )

const getResponseBody = async resp => {
  const contentType = resp.headers.get('Content-Type') || ''
  return contentType.includes('application/json')
    ? await resp.json()
    : await resp.text()
}

export class ApiError extends Error {
  httpStatus: string
  httpStatusText: string
  body: string
  constructor(httpStatus, httpStatusText, body) {
    super(body?.Title || `${httpStatusText} [${httpStatus}]`)
    this.httpStatus = httpStatus
    this.httpStatusText = httpStatusText
    this.body = body
  }
}

const apiError = (resp, body) =>
  new ApiError(resp.status, resp.statusText, body)

const handleResponse = async (resp, okStatuses) => {
  const result = await getResponseBody(resp)

  if (!okStatuses.includes(resp.status)) {
    // eslint-disable-next-line no-console
    console.error(`Invalid status code ${resp.status}, expected ${okStatuses}`)

    return Promise.reject(apiError(resp, result))
  }

  return result
}
