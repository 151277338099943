import produce from 'immer'

import {
  SHOW_NOTIFICATION,
  SET_CONFIG,
  SHOW_LINK,
  OPEN_POPUP_REMINDER,
  SUPRESS_POPUP_REMINDER
} from '../actions/ui'

const initState = {
  openPopupReminder: false,
  surpressPopupReminder: Boolean(
    localStorage.getItem('supressActivatePopupReminder') &&
      localStorage.getItem('supressActivatePopupReminder') === 'true'
  ),
  popupReminderIsOpen: false,
  appStatus: null,
  notification: null,
  linkToProject: null,
  linkNotification: null
}

export default produce((draft, action) => {
  switch (action.type) {
    case SET_CONFIG:
      draft.config = action.config
      return

    case OPEN_POPUP_REMINDER:
      draft.popupReminderIsOpen = action.open
      return

    case SUPRESS_POPUP_REMINDER:
      draft.surpressPopupReminder = action.surpress
      return

    case SHOW_NOTIFICATION:
      draft.notification = action.message
      return

    case SHOW_LINK:
      draft.linkToProject = action.project
      draft.linkNotification = action.message
      return

    default:
      return
  }
}, initState)
