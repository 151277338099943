/* eslint-disable react/prop-types */
import React from 'react'
import ConfirmationDialog from '../Dialog/ConfirmationDialog'
import downloadManyDialogTranslations from './translations/downloadManyDialogTranslations'

interface IProps {
  selectedFilesCount: number
  open: boolean
  onCancel: () => void
  onOk: () => void
}

const DatasetDownloadManyDialog = (props: IProps) => {
  const { open, onCancel, onOk, selectedFilesCount } = props

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      cancel={downloadManyDialogTranslations.cancel}
      ok={downloadManyDialogTranslations.ok}
      message={downloadManyDialogTranslations.message}
      title={
        downloadManyDialogTranslations.titlePart1 +
        ' ' +
        selectedFilesCount +
        ' ' +
        downloadManyDialogTranslations.titlePart2
      }
    />
  )
}

export default DatasetDownloadManyDialog
