import { createTheme } from '@material-ui/core/styles'
import MikeSharedTheme from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors'

const DataAdminTheme = {
  ...MikeSharedTheme,
  // Add special cloud admin styles to the theme if needed
  overrides: {
    ...MikeSharedTheme.overrides,
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: MIKE_COLORS.DARKGREY_DEFAULT
        },
        '&$error': {
          color: MIKE_COLORS.PINK_DEFAULT
        }
      }
    },
    MuiFilledInputLabel: {
      root: {
        color: MIKE_COLORS.DARKGREY_DEFAULT,
        fontSize: '1rem', // 16px
        lineHeight: 1.374, // 22px
        '&:focused &:not($error)': {
          color: MIKE_COLORS.DARKGREY_DEFAULT
        },
        '&:error': {
          color: MIKE_COLORS.PINK_DEFAULT
        },
        '&:disabled': {
          color: MIKE_COLORS.MEDIUMGREY_LIGHT
        }
      }
    },
    MuiFilledInput: {
      root: {
        color: MIKE_COLORS.DARKGREY_DEFAULT,
        backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT,
        '&:hover': {
          backgroundColor: MIKE_COLORS.MEDIUMGREY_DEFAULT
        },
        '&:error': {
          backgroundColor: MIKE_COLORS.MEDIUMGREY_DARK
        },
        '&$disabled': {
          backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT
        },
        '&$focused': {
          color: MIKE_COLORS.BRANDBLUE_DEFAULT,
          backgroundColor: MIKE_COLORS.MEDIUMGREY_DARK
        }
      },
      underline: {
        color: MIKE_COLORS.BRANDBLUE_DEFAULT,
        '&:after &:not($error)': {
          borderBottom: '1px solid ' + MIKE_COLORS.DARKGREY_DEFAULT
        },
        '&:before &:not($error)': {
          borderBottom: '1px solid ' + MIKE_COLORS.DARKGREY_DEFAULT
        },
        '&$focused:before': {
          borderBottom: '2px solid ' + MIKE_COLORS.BRANDBLUE_DEFAULT
        },
        '&$focused:after': {
          borderBottom: '2px solid ' + MIKE_COLORS.BRANDBLUE_DEFAULT
        },
        '&$error:before': {
          borderBottom: '2px solid ' + MIKE_COLORS.PINK_DEFAULT
        },
        '&$error:after': {
          borderBottom: '2px solid ' + MIKE_COLORS.PINK_DEFAULT
        }
      },
      multiline: {
        color: MIKE_COLORS.BRANDBLUE_DEFAULT,
        '&:before &:not($error)': {
          borderBottom: '1px solid ' + MIKE_COLORS.DARKGREY_DEFAULT
        },
        '&:after &:not($error)': {
          borderBottom: '1px solid ' + MIKE_COLORS.DARKGREY_DEFAULT
        },
        '&$focused:before': {
          borderBottom: '2px solid ' + MIKE_COLORS.BRANDBLUE_DEFAULT
        },
        '&$focused:after': {
          borderBottom: '2px solid ' + MIKE_COLORS.BRANDBLUE_DEFAULT
        },
        '&$error:before': {
          borderBottom: '2px solid ' + MIKE_COLORS.PINK_DEFAULT
        },
        '&$error:after': {
          borderBottom: '2px solid ' + MIKE_COLORS.PINK_DEFAULT
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.75rem',
        lineHeight: 1.66,
        color: MIKE_COLORS.DARKGREY_DEFAULT,
        backgroundColor: 'transparent',
        '&$error': {
          color: MIKE_COLORS.PINK_DEFAULT
        }
      }
    },
    MuiSelect: {
      selectMenu: {
        backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT
      }
    },

    MuiMenuItem: {
      root: {
        color: MIKE_COLORS.BRANDBLUE_DEFAULT,
        backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT,
        '&:hover': {
          backgroundColor: MIKE_COLORS.MEDIUMGREY_DEFAULT
        },
        '&$selected, &$selected:hover, &$selected:focus': {
          backgroundColor: MIKE_COLORS.MEDIUMGREY_DARK,
          fontWeight: 'bold'
        }
      }
    },
    props: {
      ...MikeSharedTheme.props,
      MuiInput: {
        margin: 'dense'
      },
      MuiTextField: {
        variant: 'filled',
        margin: 'dense'
      },
      MuiMenuItem: {
        margin: 'dense'
      }
    }
  }
}

export const theme = createTheme(DataAdminTheme)
