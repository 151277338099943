import { call, put, takeEvery } from 'redux-saga/effects'
import {
  LOAD_SITE_STORAGE_USAGE,
  LoadSiteStorageUsageType
} from '../actions/siteStorageUsage'
import { postConsumptionMyList } from '../apis/consumptionLog'
import {
  setCpuQueryParams,
  setStorageUsageData,
  setStorageUsageLoadingState,
  setStorageUsageState,
  setTotalCount
} from '../reducers/siteStorageUsage'
import { StateEnum } from '../model/StateEnum'
import moment from 'moment'

export default function* watchSiteStorageUsage() {
  yield takeEvery(LOAD_SITE_STORAGE_USAGE, fetchSiteStorageUsage)
}

export function* fetchSiteStorageUsage(action: LoadSiteStorageUsageType) {
  yield put(setStorageUsageLoadingState({ loadingState: true }))
  yield put(setCpuQueryParams(action.params))

  const params = action.params
  const result = yield call(postConsumptionMyList, {
    projectId: action.tenantId,
    metricType: 'StorageUsage',
    offset: params.page * params.rowsPerPage,
    limit: params.rowsPerPage,
    orderBy: params.orderBy === 'validFrom' ? 'ValidFrom' : params.orderBy,
    sortOrder: params.sortOrder,
    from: moment()
      .subtract(1, 'days')
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm'),
    to: moment()
      .endOf('day')
      .format('YYYY-MM-DDTHH:mm')
  })

  if (result.data.length === 0) {
    if (params.page === 0) {
      yield put(
        setStorageUsageState({
          state: StateEnum.LOADED,
          storageUsage: [],
          totalCount: 0,
          params
        })
      )
    } else {
      yield put(setStorageUsageLoadingState({ loadingState: false }))
      yield put(setTotalCount({ totalCount: params.page * params.rowsPerPage }))
    }
  } else {
    if (result.data.length < params.rowsPerPage) {
      yield put(
        setTotalCount({
          totalCount: params.page * params.rowsPerPage + result.data.length
        })
      )
    } else if (params.page === 0) {
      yield put(setTotalCount({ totalCount: -1 }))
    }

    yield put(setStorageUsageLoadingState({ loadingState: false }))
    yield put(setStorageUsageData(result.data.slice(0, params.rowsPerPage)))
  }
}
