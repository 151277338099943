const conversionTranslations = {
  name: 'Rename (optional)',
  description: 'Description (optional)',
  submitDownload: 'Download and convert',
  submitConvert: 'Convert',
  submitUpload: 'Upload and convert',
  cancel: 'Cancel',
  readerDropDown: 'Current data type',
  readerDropDownConvert: 'Current data type',
  writerDropDown: 'Convert to',
  fileWriterAndSRIDHelp:
    'Apart from the coordinate system, no other input parameters are considered.',
  conversionOptions: 'Conversion options'
}

export default conversionTranslations
