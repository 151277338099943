/* eslint-disable react/prop-types */
import React from 'react'
import { makeStyles, createStyles, Grid } from '@material-ui/core'
import { IApplication } from '../../model/Applications'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import MikeCloudApp from './MikeCloudApp'
import { PaletteColor } from '@material-ui/core/styles/createPalette'
import AppsSkeleton from './AppsSkeleton'
import { useRouteMatch } from 'react-router-dom'

interface IProps {
  applications: Array<IApplication>
  loading: boolean
  projectId: string
  tenantId: string
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  const mediumGrey = theme.palette.mediumGrey as PaletteColor // TS fix: possibly undefined
  return createStyles({
    container: {
      padding: theme.spacing(6),
      backgroundColor: mediumGrey.light,
      border: '1px solid ' + mediumGrey.main
    },
    item: {
      paddingRight: theme.spacing(6)
    }
  })
})

const MikeCloudApps = (props: IProps) => {
  const classes = useStyles()
  const { applications, loading, projectId, tenantId } = props

  const match = useRouteMatch({
    path: '/project/:id',
    strict: true,
    sensitive: true
  })

  return loading ? (
    <AppsSkeleton />
  ) : (
    <Grid
      container
      direction="row"
      alignItems="flex-end"
      justifyContent="flex-start"
      className={classes.container}
    >
      <>
        {applications.map((app: IApplication, index: number) => {
          return (
            app.enabled && (
              <Grid className={classes.item} key={index} item>
                <MikeCloudApp
                  application={app}
                  key={index}
                  projectId={projectId}
                  linkToRootUrl={!match}
                  tenantId={tenantId}
                />
              </Grid>
            )
          )
        })}
      </>
    </Grid>
  )
}

export default MikeCloudApps
