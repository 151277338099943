import { call, put, takeEvery, select } from 'redux-saga/effects'

import { getDatasetReaders } from '../apis/datasetReaders'
//import { getMockDedicatedDataseReaders, getMockFileDatasetReaders } from '../apis/datasetReaders'

import {
  LOAD_DATASET_READERS,
  loadingDatasetReaders,
  setDatasetReaders
} from '../actions/datasetReaders'

import { getReadersToHide } from '../state'

import { addError } from '../actions/errors'

import { FILE_IMPORTER_ID } from '../helpers/import'

export default function* watchDatasetReaders() {
  yield takeEvery(LOAD_DATASET_READERS, loadDatasetReaders)
}

export function* loadDatasetReaders() {
  try {
    yield put(loadingDatasetReaders())
    const dedicatedReaders = yield call(getDatasetReaders, 'Dedicated')
    let readers = yield call(getDatasetReaders, 'File')

    /*     const dedicatedReaders = yield call(getMockDedicatedDataseReaders)
    let readers = yield call(getMockFileDatasetReaders) */

    // some readers shall only be shown in specific environments (e.g., dev)
    const readersToHide = yield select(getReadersToHide)
    if (readersToHide) {
      const readersToBeHidden = readersToHide.split(',')
      readers = readers.filter(reader => {
        return !readersToBeHidden.includes(reader.id)
      })
    }

    // reorder elements so that file importer is at the end
    var fileImporter = readers.find(({ format }) => {
      return format === FILE_IMPORTER_ID
    })

    if (fileImporter) {
      readers.push(readers.splice(readers.indexOf(fileImporter), 1)[0])
    }

    yield put(setDatasetReaders(readers, dedicatedReaders))
  } catch (error) {
    yield put(addError(LOAD_DATASET_READERS, error))
  } finally {
    yield put(loadingDatasetReaders(false))
  }
}
