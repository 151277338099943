import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import LandingLayout from './LandingLayout'
import { useOidc } from '@axa-fr/react-oidc'
import { defineMessages, useIntl } from 'react-intl'
import { MikeButton } from '@mike/mike-shared-frontend'

const localMessages = defineMessages({
  button: {
    id: 'screens.loginScreen.retryButton'
  }
})

type Props = {
  headline: string
  body: string
  showRetryButton?: boolean
}

const OpenIdStatusPage = ({ headline, body, showRetryButton }: Props) => {
  const { login } = useOidc()
  const intl = useIntl()

  return (
    <LandingLayout>
      <>
        <Grid item xs={12}>
          <Typography variant="h2">{headline}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant="body2">{body}</Typography>
          </Box>
        </Grid>

        {showRetryButton && (
          <MikeButton
            color="secondary"
            variant="contained"
            onClick={() => login('/')}
          >
            {intl.formatMessage(localMessages.button)}
          </MikeButton>
        )}
      </>
    </LandingLayout>
  )
}

export default OpenIdStatusPage
