import identity from 'lodash/identity'

export const LOAD_PROJECTCONTENT = 'PROJECT/LOAD_CONTENT'
export const loadProjectContent = (
  projectId,
  page = 0,
  rowsPerPage = 25,
  sortBy = 'UpdatedAt',
  sortOrder = 'Desc',
  namePrefix = ''
) => ({
  type: LOAD_PROJECTCONTENT,
  projectId,
  page,
  rowsPerPage,
  sortBy,
  sortOrder,
  namePrefix
})

export const SET_PROJECT_CONTENT = 'PROJECT/SET_CONTENT'
export const setProjectContent = (
  projectContent,
  totalCount,
  rowsPerPage,
  offset,
  namePrefix,
  sortBy,
  sortOrder
) => ({
  type: SET_PROJECT_CONTENT,
  projectContent,
  totalCount,
  rowsPerPage,
  offset,
  namePrefix,
  sortBy,
  sortOrder
})

export const SET_PROJECT_ROWSPERPAGE = 'PROJECT/SET_ROWSPERPAGE'
export const setProjectRowsPerPage = rowsPerPage => ({
  type: SET_PROJECT_ROWSPERPAGE,
  rowsPerPage
})

export const SET_PROJECT_PAGINATION = 'PROJECT/SET_PAGINATION'
export const setProjectPagination = page => ({
  type: SET_PROJECT_PAGINATION,
  page
})

export const LOAD_PROJECT_MEMBERS = 'PROJECT/LOAD_MEMBERS'
export const loadProjectMembers = projectId => ({
  type: LOAD_PROJECT_MEMBERS,
  projectId
})

export const LOADING_PROJECT_MEMBERS = 'PROJECT/LOADING_MEMBERS'
export const loadingProjectMembers = (loading = true) => ({
  type: LOADING_PROJECT_MEMBERS,
  loading
})

export const SET_PROJECT_MEMBERS = 'PROJECT/SET_MEMBERS'
export const setProjectMembers = members => ({
  type: SET_PROJECT_MEMBERS,
  members
})

export const UPDATE_PROJECT_DATASET = 'PROJECT/UPDATE_DATASET'
export const updateProjectDataset = (projectId, data) => ({
  type: UPDATE_PROJECT_DATASET,
  data,
  projectId
})

export const UPDATING_PROJECT_DATASET = 'PROJECT/UPDATING_DATASET'
export const updatingProjectDataset = (updating = true) => ({
  type: UPDATING_PROJECT_DATASET,
  updating
})

export const LOAD_PROJECT_DATASETS = 'PROJECT/LOAD_DATASETS'
export const loadProjectDatasets = projectId => ({
  type: LOAD_PROJECT_DATASETS,
  projectId
})

export const LOADING_PROJECT_DATASETS = 'PROJECT/LOADING_DATASETS'
export const loadingProjectDatasets = (loading = true) => ({
  type: LOADING_PROJECT_DATASETS,
  loading
})

export const SET_PROJECT_DATASETS = 'PROJECT/SET_DATASETS'
export const setProjectDatasets = datasets => ({
  type: SET_PROJECT_DATASETS,
  datasets
})

export const DELETE_PROJECT_DATASETS = 'PROJECT/DELETE_DATASETS'
export const deleteProjectDatasets = (projectId, datasetIds) => ({
  type: DELETE_PROJECT_DATASETS,
  projectId,
  datasetIds
})

export const DELETE_PROJECT_FOLDERS = 'PROJECT/DELETE_FOLDERS'
export const deleteSubProjects = (projectParentId, projectIds) => ({
  type: DELETE_PROJECT_FOLDERS,
  projectParentId,
  projectIds
})

export const DELETING_PROJECT_FOLDERS = 'PROJECT/DELETING_FOLDERS'
export const deletingSubProjects = (loading = true) => ({
  type: DELETING_PROJECT_FOLDERS,
  loading
})

export const DELETING_PROJECT_DATASETS = 'PROJECT/DELETING_DATASETS'
export const deletingProjectDatasets = (loading = true) => ({
  type: DELETING_PROJECT_DATASETS,
  loading
})

export const LOAD_PROJECT_DETAILS = 'PROJECT/LOAD_DETAILS'
export const loadProjectDetails = id => ({ type: LOAD_PROJECT_DETAILS, id })

export const LOADING_PROJECT_DETAILS = 'PROJECT/LOADING_DETAILS'
export const loadingProjectDetails = (loading = true) => ({
  type: LOADING_PROJECT_DETAILS,
  loading
})

export const SET_PROJECT_DETAILS = 'PROJECT/SET_DETAILS'
export const setProjectDetails = (details, capabilities) => ({
  type: SET_PROJECT_DETAILS,
  details,
  capabilities
})

export const LOAD_PROJECT_SUBPROJECTS = 'PROJECT/LOAD_SUBPROJECTS'
export const loadProjectSubprojects = (projectId, forTable = true) => ({
  type: LOAD_PROJECT_SUBPROJECTS,
  projectId,
  forTable
})

export const LOADING_PROJECT_SUBPROJECTS = 'PROJECT/LOADING_SUBPROJECTS'
export const loadingProjectSubprojects = (loading = true) => ({
  type: LOADING_PROJECT_SUBPROJECTS,
  loading
})

export const SET_PROJECT_SUBPROJECTS = 'PROJECT/SET_SUBPROJECTS'
export const setProjectSubprojects = subprojects => ({
  type: SET_PROJECT_SUBPROJECTS,
  subprojects
})

export const LOADING_PROJECT_SUBPROJECTS_TREE =
  'PROJECT/LOADING_SUBPROJECTS_TREE'
export const loadingProjectSubprojectsTree = (loading = true) => ({
  type: LOADING_PROJECT_SUBPROJECTS_TREE,
  loading
})

export const SET_PROJECT_SUBPROJECTS_TREE = 'PROJECT/SET_SUBPROJECTS_TREE'
export const setProjectSubprojectsTree = (
  subprojects,
  parentProjectId,
  tree
) => ({
  type: SET_PROJECT_SUBPROJECTS_TREE,
  subprojects,
  parentProjectId,
  tree
})

export const LOAD_PROJECT_PARENT = 'PROJECT/LOAD_PARENT'
export const loadProjectParent = () => ({
  type: LOAD_PROJECT_PARENT
})

export const LOADING_PROJECT_PARENT = 'PROJECT/LOADING_PARENT'
export const loadingProjectParent = (loading = true) => ({
  type: LOADING_PROJECT_PARENT,
  loading
})

export const SET_PROJECT_PARENT = 'PROJECT/SET_PARENT'
export const setProjectParent = parentProject => ({
  type: SET_PROJECT_PARENT,
  parentProject
})

export const LOAD_PROJECT_ROOT = 'PROJECT/LOAD_ROOT'
export const loadProjectRoot = project => ({
  type: LOAD_PROJECT_ROOT,
  project
})

export const LOADING_PROJECT_INITIAL_TREE = 'PROJECT/LOADING_INITIAL_TREE'
export const loadingProjectInitialTree = (loading = true) => ({
  type: LOADING_PROJECT_INITIAL_TREE,
  loading
})

export const SET_PROJECT_ROOT = 'PROJECT/SET_ROOT'
export const setProjectRoot = rootProject => ({
  type: SET_PROJECT_ROOT,
  rootProject
})

export const SET_PROJECT_INITIAL_TREE = 'PROJECT/SET_INITIAL_TREE'
export const setProjectInitialTree = initialtree => ({
  type: SET_PROJECT_INITIAL_TREE,
  initialtree
})

export const RESET_PROJECT_INITIAL_TREE = 'PROJECT/RESET_INITIAL_TREE'
export const resetProjectInitialTree = {
  type: RESET_PROJECT_INITIAL_TREE,
  data: { project: [] }
}

export const UPDATE_PROJECT_DETAILS = 'PROJECT/UPDATE_DETAILS'
export const updateProjectDetails = (data, projectDidUpdate = identity) => ({
  type: UPDATE_PROJECT_DETAILS,
  data,
  projectDidUpdate
})

export const MOVE_PROJECT_CONTENT = 'PROJECT/MOVE_CONTENT'
export const moveContent = (
  targetProjectId,
  projectIds,
  datasetIds,
  projectId
) => ({
  type: MOVE_PROJECT_CONTENT,
  targetProjectId,
  projectIds,
  datasetIds,
  projectId
})

export const MOVING_PROJECT_CONTENT = 'PROJECT/MOVING_CONTENT'
export const movingContent = (moving = true) => ({
  type: MOVING_PROJECT_CONTENT,
  moving
})

export const LOAD_PROJECT_PATH = 'PROJECT/LOAD_PATH'
export const loadProjectPath = projectId => ({
  type: LOAD_PROJECT_PATH,
  projectId
})

export const LOADING_PROJECT_PATH = 'PROJECT/LOADING_PATH'
export const loadingProjectPath = (loading = true) => ({
  type: LOADING_PROJECT_PATH,
  loading
})

export const SET_PROJECT_PATH = 'PROJECT/SET_PATH'
export const setProjectPath = data => ({
  type: SET_PROJECT_PATH,
  data
})
