import React from 'react'
import objectHash from 'object-hash'
import { Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import TextField from '../formik/TextField'

import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import messages from '../../shared/messages'
import { MikeButton } from '@mike/mike-shared-frontend'
import { css } from 'emotion'
import merge from 'lodash/merge'
import TenantMembersSelect from '../TenantMembersSelect/TenantMembersSelect'
import ITenantGroup from '../../model/ITenantGroup'
import { useTypedSelector } from '../../reducers'

const submitButtonStyle = css`
  float: right;
`

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required')
})

interface IProps {
  initialValues: ITenantGroup
  handleSubmit: (values: ITenantGroup) => void
}

const UserGroupForm = (props: IProps) => {
  const intl = useIntl()
  const { handleSubmit, initialValues } = props

  const users = useTypedSelector(state => state.users.users)

  const [selectedMembers, setSelectedMembers] = React.useState(
    initialValues.members
  )

  const editing = initialValues.name !== ''

  const defaultInitialValues: ITenantGroup = {
    id: '',
    name: '',
    members: []
  }

  let mergedInitialValues = editing
    ? {
        ...merge({}, defaultInitialValues, initialValues)
      }
    : {
        ...defaultInitialValues
      }

  const handleMemberSelected = members => {
    mergedInitialValues.members = members
    setSelectedMembers(members)
  }

  return (
    <Formik
      key={objectHash(mergedInitialValues)}
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => {
        const { handleChange, setFieldTouched, setFieldValue } = formik

        const change = (name, e) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label={intl.formatMessage(messages.name)}
                  fullWidth
                  required
                  autoFocus
                  onChange={change.bind(null, 'name')}
                />
              </Grid>
              {
                <Grid item xs={12}>
                  {!editing && (
                    <TenantMembersSelect
                      members={initialValues.members || []}
                      onMemberSelected={handleMemberSelected}
                      selectedMembers={selectedMembers}
                      setFieldValue={setFieldValue}
                      users={users}
                    />
                  )}
                </Grid>
              }
              <Grid item xs={12}>
                <MikeButton
                  className={submitButtonStyle}
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  {editing
                    ? intl.formatMessage({
                        id: 'userGroupForm.updateGroup'
                      })
                    : intl.formatMessage({
                        id: 'userGroupForm.createGroup'
                      })}
                </MikeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UserGroupForm
