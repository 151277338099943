import produce from 'immer'
import find from 'lodash/fp/find'
import findIndex from 'lodash/fp/findIndex'

import {
  UPLOAD_FILE,
  UPLOAD_START,
  UPLOAD_COMPLETE,
  UPLOAD_FAIL,
  UPDATE_PROGRESS,
  REMOVE_UPLOAD
} from '../actions/uploads'

import { UPLOAD_STATES } from '../helpers/upload'

export default produce((draft, action) => {
  const { type, ...payload } = action

  const upload = findUpload(payload.id)(draft)

  switch (type) {
    case UPLOAD_FILE:
      draft.push({
        ...payload,
        state: UPLOAD_STATES.PENDING
      })
      return

    case UPLOAD_START:
      upload.state = UPLOAD_STATES.IN_PROGRESS
      upload.progress = 0
      return

    case UPDATE_PROGRESS:
      upload.progress = payload.progress
      return

    case UPLOAD_COMPLETE:
      upload.state = UPLOAD_STATES.DONE
      upload.fileUrl = action.fileUrl
      return

    case UPLOAD_FAIL:
      upload.state = UPLOAD_STATES.FAILED
      upload.error = payload.error
      return

    case REMOVE_UPLOAD:
      removeUpload(action.id)(draft)
      return

    default:
      return
  }
}, [])

const findUpload = id => find({ id })
const removeUpload = id => draft => draft.splice(findIndex({ id })(draft), 1)
