import { call, put, takeEvery } from 'redux-saga/effects'
import {
  LOAD_PROJECT_NAMES,
  LoadProjectNamesType
} from '../actions/projectNames'
import {
  appendProjectNames,
  setProjectNamesLoadingState
} from '../reducers/projectNames'
import { getProjects } from '../apis/projects'

export default function* watchProjectNames() {
  yield takeEvery(LOAD_PROJECT_NAMES, fetchProjectNames)
}

export function* fetchProjectNames(action: LoadProjectNamesType) {
  yield put(setProjectNamesLoadingState({ loadingState: true }))
  let list = []
  let offset = 0
  do {
    const { data } = yield call(
      getProjects,
      offset,
      1000,
      'Name',
      'asc',
      '',
      true
    )
    list = data

    const nameHash = data.reduce((acc, project) => {
      acc[project.id] = project.name
      return acc
    }, {})

    yield put(appendProjectNames(nameHash))
    offset += 1000
  } while (list.length === 1000)
  yield put(setProjectNamesLoadingState({ loadingState: false }))
}
