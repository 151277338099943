const conversionOptionsTranslations = {
  title: 'Upload as-is or convert data?',
  downloadTitle: 'Download as-is or convert data?',
  selectedFile: 'Selected file',
  uploadAsIs: 'Upload as-is',
  downloadAsIs: 'Download as-is',
  downloadInDifferentFormat: 'Download and change or convert',
  convertToDifferentFormat: 'Upload and change or convert',
  upload: 'Upload',
  download: 'Download',
  cancel: 'Cancel',
  next: 'Next'
}

export default conversionOptionsTranslations
