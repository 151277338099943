// import MikeTooltip from '@mike/mike-shared-frontend/mike-tooltip/MikeTooltip'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import messages from '../../shared/messages'
import { ReactComponent as Projects } from '@mike/mike-shared-frontend/media/icons/Projects'
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import { Tabs, Tab } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { useLocation, Link } from 'react-router-dom'

const useAppNavStyles = makeStyles(theme =>
  createStyles({
    tabsIndicator: {
      height: 3
    },
    hiddenTabRoot: {
      minWidth: 0,
      padding: 0,
      margin: 0,
      minHeight: 48
    },
    root: {
      borderBottom: '1px solid' + theme.palette.grey[300],
      paddingLeft: '40px',
      marginBottom: '20px'
    },
    activeLabel: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main
    },
    svg: {
      filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)'
    }
  })
)

const AppNav = ({ tenantId }) => {
  const intl = useIntl()
  const classes = useAppNavStyles()

  function usePath() {
    return useLocation().pathname
  }

  let currentPath = usePath()

  const activeTab = useMemo(() => {
    if (currentPath.startsWith('/site') || currentPath.startsWith('/project')) {
      return 1
    }

    if (currentPath === `/recycle-bin/${tenantId}`) {
      return 2
    }

    return 0
  }, [tenantId, currentPath])

  return (
    <Tabs
      classes={{ indicator: classes.tabsIndicator, root: classes.root }}
      value={activeTab}
      indicatorColor="secondary"
      textColor="primary"
    >
      <Tab classes={{ root: classes.hiddenTabRoot }} />
      <LinkTab
        to={'/site'}
        label={
          <span className={activeTab === 1 ? classes.activeLabel : ''}>
            {intl.formatMessage(messages.siteData)}
          </span>
        }
        icon={<Projects className={activeTab === 1 ? classes.svg : ''} />}
      />
      <LinkTab
        to={'/recycle-bin/' + tenantId}
        label={
          <span className={activeTab === 2 ? classes.activeLabel : ''}>
            {intl.formatMessage(messages.recycleBin)}
          </span>
        }
        icon={<Delete className={activeTab === 2 ? classes.svg : ''} />}
      />
    </Tabs>
  )
}
AppNav.propTypes = {
  tenantId: PropTypes.string
}

const useTabStyles = makeStyles(
  createStyles({
    root: {
      textTransform: 'capitalize',
      fontSize: '1em',
      opacity: 1,
      minHeight: 48,
      padding: 0,
      margin: 0
    },
    wrapper: {
      paddingRight: '12px',
      flexDirection: 'row',
      '&&& > .icon': {
        marginLeft: -10,
        marginBottom: 0
      }
    }
  })
)

export const LinkTab = ({ to, label, icon, disabled }) => {
  const classes = useTabStyles()

  return (
    <Tab
      disabled={disabled}
      classes={classes}
      component={Link}
      to={to}
      label={label}
      icon={icon}
    />
  )
}

LinkTab.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  disabled: PropTypes.bool
}

export default AppNav
