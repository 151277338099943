/* eslint-disable import/no-anonymous-default-export */
/**
 * store
 *
 * Basic local storage wrapper.
 */
export default {
  get: (key: string) => {
    const str = localStorage.getItem(key) as string
    try {
      return JSON.parse(str)
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e)
      return str
    }
  },
  set: (key: string, str: any) =>
    localStorage.setItem(key, JSON.stringify(str)),
  remove: (key: string) => localStorage.removeItem(key),
  allItems: (): Array<string> => Object.keys(localStorage)
}
