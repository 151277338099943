import { makeStyles, createStyles } from '@material-ui/core'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'

export const useMetadataStyles = makeStyles((theme: IMikeTheme) => {
  const grey = theme.palette.mediumGrey && theme.palette.mediumGrey.main
  const lightgrey = theme.palette.lightGrey && theme.palette.lightGrey.main
  return createStyles({
    longText: {
      maxWidth: '8vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'table-cell',
      paddingRight: theme.spacing(1)
    },
    bottomBorder: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      backgroundColor: lightgrey,
      borderBottom: '1px solid ' + grey
    },
    tooltip: {
      maxHeight: '100%',
      height: '100%'
    }
  })
})
