import React from 'react'
import objectHash from 'object-hash'
import { Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import TextField from '../formik/TextField'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import messages from '../../shared/messages'
import { MikeButton } from '@mike/mike-shared-frontend'
import { css } from 'emotion'
import merge from 'lodash/merge'
import IAPIAccess from '../../model/IAPIAccess'
import SelectField from '../formik/SelectField'
import { ROLE_TYPES_EXT } from '../../helpers/project'
import map from 'lodash/map'

const submitButtonStyle = css`
  float: right;
`

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required')
})

interface IProps {
  initialValues: IAPIAccess
  handleSubmit: (values: IAPIAccess) => void
}

const APIAccessForm = (props: IProps) => {
  const intl = useIntl()
  const { handleSubmit, initialValues } = props
  const editing = initialValues.accountId !== undefined

  const defaultInitialValues: IAPIAccess = {
    name: '',
    role: ROLE_TYPES_EXT.NONE
  }

  const mergedInitialValues = editing
    ? {
        ...merge({}, defaultInitialValues, initialValues)
      }
    : {
        ...defaultInitialValues
      }

  return (
    <Formik
      key={objectHash(mergedInitialValues)}
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => {
        const { handleChange, setFieldTouched } = formik

        const change = (name, e) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label={intl.formatMessage(messages.name)}
                  fullWidth
                  required
                  autoFocus
                  onChange={change.bind(null, 'name')}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  name={'role'}
                  valueField="name"
                  label={intl.formatMessage(messages.role)}
                  primaryField="name"
                  items={map(Object.values(ROLE_TYPES_EXT), role => ({
                    name: role
                  }))}
                  fullWidth
                  onChange={change.bind(null, 'role')}
                />
              </Grid>
              <Grid item xs={12}>
                <MikeButton
                  className={submitButtonStyle}
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  {editing
                    ? intl.formatMessage({
                        id: 'apiAccessForm.updateAPIAccessButton'
                      })
                    : intl.formatMessage({
                        id: 'apiAccessForm.createAPIAccessButton'
                      })}
                </MikeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default APIAccessForm
