import produce from 'immer'
import merge from 'lodash/merge'

import { UPDATE } from 'react-intl-redux'

const initState = {
  locale: '',
  messages: {},
  formats: {}
}

const intlReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE:
      merge(draft, action.payload)
      return

    default:
      return
  }
}, initState)

export default intlReducer
