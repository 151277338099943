import React, { FC } from 'react'
import moment, { Moment } from 'moment'
import Tooltip from '@material-ui/core/Tooltip'

interface Props {
  date: string
}

const UTCDateTime: FC<Props> = ({ date }) => {
  const utcDate: Moment = moment.utc(date)

  return (
    <Tooltip
      title={moment(utcDate)
        .format('DD MMM YYYY HHmm Z')
        .replace('0000', '(2400')
        .replace('+00:00', 'UTC)')}
    >
      <div style={{ whiteSpace: 'nowrap' }}>
        {moment(utcDate).format('DD MMM YYYY')}
      </div>
    </Tooltip>
  )
}

export default UTCDateTime
