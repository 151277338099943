import ITenantGroup from '../model/ITenantGroup'

export const LOAD_GROUPS = 'GROUPS/LOAD'

export type LoadGroupsType = {
  type: string
  tenantId: string
}
export const loadGroups = (tenantId: string): LoadGroupsType => ({
  type: LOAD_GROUPS,
  tenantId
})

export const SET_GROUPS = 'GROUPS/SET'
export const setGroups = (groups: ITenantGroup[]) => ({
  type: SET_GROUPS,
  groups
})

export type CreateUpdateGroupType = {
  type: string
  group: any
  tenantId: string
}

export const CREATE_GROUP = 'GROUP/CREATE'
export const createGroup = (tenantId: string, group: ITenantGroup) =>
  ({
    type: CREATE_GROUP,
    group,
    tenantId
  } as CreateUpdateGroupType)

export const UPDATE_GROUP = 'GROUP/UPDATE'
export const updateGroup = (tenantId: string, group: ITenantGroup) =>
  ({
    type: UPDATE_GROUP,
    group,
    tenantId
  } as CreateUpdateGroupType)

export const DELETE_GROUPS = 'GROUPS/DELETE'
export type DeleteGroupsType = {
  type: string
  tenantId: string
  groups: ITenantGroup[]
}
export const deleteGroups = (groups: ITenantGroup[], tenantId: string) =>
  ({
    type: DELETE_GROUPS,
    groups,
    tenantId
  } as DeleteGroupsType)
