import React from 'react'

import { Tabs } from '@material-ui/core'
import { PollOutlined } from '@material-ui/icons'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { LinkTab } from '../AppNav'
import { ReactComponent as TableResults } from '@mike/mike-shared-frontend/media/icons/TableResults'
import CreditsIcon from '../icons/CreditsIcon'
import { theme } from '../../styles/theme'

const useAppNavStyles = makeStyles(theme =>
  createStyles({
    tabsIndicator: {
      height: 3
    },
    hiddenTabRoot: {
      minWidth: 0,
      padding: 0,
      margin: 0,
      minHeight: 48
    },
    root: {
      borderBottom: '1px solid' + theme.palette.grey[300],
      paddingLeft: '40px',
      marginBottom: '10px'
    },
    activeLabel: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main
    },
    svg: {
      filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)'
    }
  })
)

const SiteInfoNav = () => {
  const intl = useIntl()
  const classes = useAppNavStyles()

  function usePath() {
    return useLocation().pathname
  }

  let currentPath = usePath()

  let activeTab

  switch (currentPath) {
    case '/site-info/details':
      activeTab = 0
      break
    case '/site-info/cpu':
      activeTab = 1
      break
    case '/site-info/storage':
      activeTab = 2
      break
    case '/site-info/credits':
      activeTab = 3
      break
    default: {
      if (
        currentPath === '/site-info/credits-allocation' ||
        currentPath === '/site-info/credits-available' ||
        currentPath === '/site-info/credits-reserved' ||
        currentPath === '/site-info/credits-spent'
      ) {
        activeTab = 3
      } else {
        activeTab = 0
      }
    }
  }
  return (
    <Tabs
      classes={{ indicator: classes.tabsIndicator, root: classes.root }}
      value={activeTab}
      indicatorColor="secondary"
      textColor="primary"
    >
      <LinkTab
        to={'/site-info/details'}
        label={
          <span className={activeTab === 0 ? classes.activeLabel : ''}>
            {intl.formatMessage({ id: 'screens.siteInfo.tab.info' })}
          </span>
        }
        icon={
          <PollOutlined
            style={{ marginRight: 8 }}
            className={activeTab === 0 ? classes.svg : ''}
          />
        }
      />
      <LinkTab
        to={'/site-info/cpu'}
        label={
          <span className={activeTab === 1 ? classes.activeLabel : ''}>
            {intl.formatMessage({ id: 'screens.siteInfo.tab.cpu' })}
          </span>
        }
        icon={<TableResults className={activeTab === 1 ? classes.svg : ''} />}
      />
      <LinkTab
        to={'/site-info/storage'}
        label={
          <span className={activeTab === 2 ? classes.activeLabel : ''}>
            {intl.formatMessage({ id: 'screens.siteInfo.tab.storage' })}
          </span>
        }
        icon={<TableResults className={activeTab === 2 ? classes.svg : ''} />}
      />
      <LinkTab
        to={'/site-info/credits'}
        label={
          <span className={activeTab === 3 ? classes.activeLabel : ''}>
            {intl.formatMessage({ id: 'screens.siteInfo.tab.credits' })}
          </span>
        }
        icon={
          <CreditsIcon
            fillR={activeTab === 3}
            colorR={activeTab !== 3 ? theme.palette.primary.main : undefined}
            className={activeTab !== 3 ? classes.svg : ''}
          />
        }
      />
    </Tabs>
  )
}

export default SiteInfoNav
