import { metadata } from './support'
import { mockDedicatedWriters } from '../components/DatasetConversionDialog/model/mockDedicatedWriters'
import { mockFileWriters } from '../components/DatasetConversionDialog/model/mockFileWriters'

export const getDatasetWriters = async (writerType = '') => {
  const queryParameter = writerType ? '?filter=' + writerType : ''
  const { data } = await metadata.getJson(
    'conversion/writer/list' + queryParameter,
    2
  )
  return data.map(element => {
    return {
      id: element.name,
      ...element
    }
  })
}

export const getMockDedicatedDatasetWriters = async () => {
  return mockDedicatedWriters
}

export const getMockFileDatasetWriters = async () => {
  return mockFileWriters
}
