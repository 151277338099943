/* eslint-disable no-console */

import { take } from 'redux-saga/effects'

import { ADD_ERROR } from '../actions/errors'

export default function* watchUi() {
  while (true) {
    const { error } = yield take(ADD_ERROR)
    console.warn('Unhandled error', error.source)
  }
}
