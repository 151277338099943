import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import RestoreIcon from '@material-ui/icons/Undo'

import messages from '../../shared/messages'

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1)
  }
})

const RestoreButton = ({ classes, ...buttonProps }) => (
  <Button color="primary" {...buttonProps}>
    <RestoreIcon className={classes.leftIcon} />
    <FormattedMessage id={messages.restore.id} />
  </Button>
)
RestoreButton.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RestoreButton)
