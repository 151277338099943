import React, { useEffect } from 'react'

import { useOidcAccessToken } from '@axa-fr/react-oidc'

import { CreateInternalTenantButton } from '../buttons/CreateInternalTenantButton'
import CreateTenantButton from '../buttons/CreateTenantButton'
import IUserProfile from '../../model/IUserProfile'
import { IBillingInformation } from '../../apis/admin'

type Props = {
  tenants: Tenant[]
  user: IUserProfile
  onOpenCreateTenant: () => void
}

type Tenant = {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  billingInformation: IBillingInformation
}

type HookProps = {
  user: IUserProfile
  tenants: Tenant[]
}

type HookReturn = {
  canCreateTenants: boolean
  canCreateInternalTenants: boolean
  canCreateExternalTenants: boolean
}

const InternalTenantBillingReferenceType = 'DHI'
const AdminRole = 'MIKE Cloud Administrator'

const useTenantButtons = ({ user, tenants }: HookProps): HookReturn => {
  const { accessTokenPayload } = useOidcAccessToken()

  const [isUserInternal, setIsUserInternal] = React.useState(false)
  const [isSiteInternal, setIsSiteInternal] = React.useState(false)
  const [isAdmin, setIsAdmin] = React.useState(false)

  const [canCreateTenants, setCanCreateTenants] = React.useState(false)
  const [
    canCreateInternalTenants,
    setCanCreateInternalTenants
  ] = React.useState(false)
  const [
    canCreateExternalTenants,
    setCanCreateExternalTenants
  ] = React.useState(false)

  // Internal user rules
  useEffect(() => {
    setIsUserInternal(user.email.endsWith('@dhigroup.com'))
  }, [user])

  // Internal site rules
  useEffect(() => {
    if (!user || !tenants) {
      setIsSiteInternal(false)

      return
    }

    const currentTenant = tenants.find(t => t.id === user.tenantId)

    if (!currentTenant) {
      setIsSiteInternal(false)

      return
    }

    const tenantType = currentTenant.billingInformation?.billingReferenceType

    setIsSiteInternal(tenantType === InternalTenantBillingReferenceType)
  }, [tenants, user])

  // External user admin rules
  useEffect(() => {
    if (!accessTokenPayload) {
      setIsAdmin(false)

      return
    }

    const roles = accessTokenPayload[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ] as string[]

    if (!roles) {
      setIsAdmin(false)

      return
    }

    const isAdmin = roles.includes(AdminRole)

    setIsAdmin(isAdmin)
  }, [accessTokenPayload])

  // Tenant creation rules
  useEffect(() => {
    if (isSiteInternal && isUserInternal) {
      setCanCreateInternalTenants(true)
      setCanCreateExternalTenants(false)
      setCanCreateTenants(true)
    } else if (!isSiteInternal && isAdmin) {
      setCanCreateExternalTenants(true)
      setCanCreateInternalTenants(false)
      setCanCreateTenants(true)
    } else {
      setCanCreateInternalTenants(false)
      setCanCreateExternalTenants(false)
      setCanCreateTenants(false)
    }
  }, [isAdmin, isSiteInternal, isUserInternal])

  return {
    canCreateTenants,
    canCreateInternalTenants,
    canCreateExternalTenants
  }
}

export const TenantButtons = ({ tenants, user, onOpenCreateTenant }: Props) => {
  const {
    canCreateTenants,
    canCreateInternalTenants,
    canCreateExternalTenants
  } = useTenantButtons({ user, tenants })

  if (!canCreateTenants) return null

  if (canCreateInternalTenants) {
    return <CreateInternalTenantButton />
  }

  if (canCreateExternalTenants) {
    return <CreateTenantButton onClick={onOpenCreateTenant} />
  }

  return null
}
