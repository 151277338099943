import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IAPIKey from '../model/IAPIKey'
import { groupBy, uniqBy } from 'lodash'
import { StateEnum } from '../model/StateEnum'

export type APIKeysState = {
  apiKeyList: {
    string?: IAPIKey[]
  }
  lastAddedApiKey: IAPIKey | null
  state: StateEnum
}

const initState = {
  apiKeyList: {},
  state: StateEnum.INIT,
  lastAddedApiKey: null
} as APIKeysState

const apiAccessSlice = createSlice({
  name: 'apiAccess',
  initialState: initState as APIKeysState,
  reducers: {
    setAPIKeysLoadingState: (
      state,
      action: PayloadAction<{ loadingState: boolean }>
    ): APIKeysState => {
      return {
        ...state,
        state: action.payload.loadingState
          ? StateEnum.LOADING
          : StateEnum.LOADED
      }
    },
    setAPIKeysList: (
      state,
      action: PayloadAction<{ apiKeys: IAPIKey[] }>
    ): APIKeysState => {
      return {
        ...state,
        apiKeyList: groupBy<string, IAPIKey>(
          action.payload.apiKeys,
          'accountId'
        ),
        state: StateEnum.LOADED
      }
    },
    addAPIKeyList: (
      state,
      action: PayloadAction<{ apiKeys: IAPIKey[] }>
    ): APIKeysState => {
      const newList = {}
      action.payload.apiKeys.forEach(apiKey => {
        if (apiKey.accountId) {
          if (newList[apiKey.accountId]) {
            newList[apiKey.accountId] = uniqBy(
              newList[apiKey.accountId].concat(apiKey),
              'publicId'
            )
          } else {
            newList[apiKey.accountId] = [apiKey]
          }
        }
      })
      return {
        ...state,
        apiKeyList: newList
      }
    },
    deleteAPIKeyList: (
      state,
      action: PayloadAction<{ apiKeys: IAPIKey[] }>
    ): APIKeysState => {
      const accountIds = action.payload.apiKeys.map(apiKey => apiKey.accountId)
      const apiKeyIds = action.payload.apiKeys.map(apiKey => apiKey.publicId)
      const newList = {}

      Object.keys(state.apiKeyList).forEach((accountId: string) => {
        if (accountIds.includes(accountId)) {
          newList[accountId] = state.apiKeyList[accountId].filter(
            item => !apiKeyIds.includes(item.publicId)
          )
        } else {
          newList[accountId] = state.apiKeyList[accountId]
        }
      })

      return {
        ...state,
        apiKeyList: newList
      }
    },
    setLastAddedAPIKey: (
      state,
      action: PayloadAction<{ apiKey: IAPIKey | null }>
    ): APIKeysState => {
      return {
        ...state,
        lastAddedApiKey: action.payload.apiKey
      }
    }
  }
})

export const {
  setAPIKeysList,
  setAPIKeysLoadingState,
  addAPIKeyList,
  deleteAPIKeyList,
  setLastAddedAPIKey
} = apiAccessSlice.actions

export default apiAccessSlice.reducer
