/* eslint-disable import/no-anonymous-default-export */
import React from 'react'

import filesize from 'filesize'
import isNil from 'lodash/isNil'

import Typography from '@material-ui/core/Typography'
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { ReactComponent as Projects } from '@mike/mike-shared-frontend/media/icons/Projects'
import { ReactComponent as Undo } from '@mike/mike-shared-frontend/media/icons/Undo'
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import { ReactComponent as Edit } from '@mike/mike-shared-frontend/media/icons/Edit'
import { ReactComponent as Download } from '@mike/mike-shared-frontend/media/icons/Download'
import { ReactComponent as ConvertData } from '@mike/mike-shared-frontend/media/icons/ConvertData'
import { isDataset } from './../../../helpers/projectContent'
import { css } from 'emotion'

import link from './Link'

export const iconStyle = (disabled = false) => {
  return css`
    path {
      fill: ${disabled ? mikePalette.primary.light : mikePalette.primary.main};
    }
  `
}

const iconActionStyle = (disabled = false) => {
  return css`
    path {
      fill: ${disabled
        ? mikePalette.secondary.light
        : mikePalette.secondary.main};
    }
  `
}

export const actionStyle = (disabled = false) => {
  return css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${disabled
      ? mikePalette.primary.light
      : mikePalette.primary.default};
    background-color: ${mikePalette.mediumGrey.light};
    cursor: ${disabled ? 'no-drop' : 'pointer'};
    padding-bottom: 0px;
    padding-top: 0px;
    padding-right: 4px;
  `
}

const renderEdit = () => {
  return (
    <div className={actionStyle()}>
      <Edit className={iconStyle()} />
      Edit
    </div>
  )
}

const renderDelete = () => {
  return (
    <div className={actionStyle()}>
      <Delete className={iconStyle()} />
      Delete
    </div>
  )
}

const renderEditProject = row => {
  const canEdit =
    row.capabilities &&
    (row.capabilities.canEdit ||
      row.capabilities.canEditAccessLevel ||
      row.capabilities.canGrantAccess)

  return (
    <div className={actionStyle(!canEdit)}>
      <Edit className={iconStyle(!canEdit)} />
      Edit
    </div>
  )
}

const renderEditItem = (row, parentCapabilities) => {
  if (isDataset(row)) {
    const canEdit = parentCapabilities.canUpdateContent

    return (
      <div className={actionStyle(!canEdit)}>
        <Edit className={iconStyle(!canEdit)} />
        Edit
      </div>
    )
  } else {
    return null
  }
}

const renderDownloadDataset = (row, parentCapabilities) => {
  if (isDataset(row)) {
    const canDownload = parentCapabilities.canReadContent

    return (
      <div className={actionStyle(!canDownload)}>
        <Download className={iconStyle(!canDownload)} />
        Download
      </div>
    )
  } else {
    return null
  }
}

const renderDeleteItem = (row, parentCapabilities) => {
  const canDelete = isDataset(row)
    ? parentCapabilities.canDeleteContent
    : row.capabilities && row.capabilities.canDelete

  return (
    <div className={actionStyle(!canDelete)}>
      <Delete className={iconStyle(!canDelete)} />
      Delete
    </div>
  )
}

const renderDeleteProject = row => {
  const canDelete = row.capabilities && row.capabilities.canDelete

  return (
    <div className={actionStyle(!canDelete)}>
      <Delete className={iconStyle(!canDelete)} />
      Delete
    </div>
  )
}

const renderConvertDataset = (row, parentCapabilities) => {
  if (isDataset(row)) {
    const canConvert = parentCapabilities.canCreateContent
    return (
      <div className={actionStyle(!canConvert)}>
        <ConvertData className={iconStyle(!canConvert)} />
        Convert
      </div>
    )
  } else {
    return null
  }
}

const renderPath = row => {
  const path = row.path
  const names = path.map(p => {
    return p.name
  })
  const backgroundColor = row.canRestore
    ? mikePalette.mediumGrey.dark
    : mikePalette.mediumGrey.light
  const cursor = row.canRestore ? 'pointer' : 'no-drop'

  const originalLocation = 'Projects > ' + names.join(' > ')
  return (
    <div style={{ backgroundColor: mikePalette.mediumGrey.light }}>
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 24,
          paddingBottom: 24
        }}
      >
        <Typography variant={'h4'}>Original location</Typography>
        <Typography>{originalLocation}</Typography>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: backgroundColor,
          cursor: cursor
        }}
      >
        <Undo />
        Restore
      </div>
    </div>
  )
}

const size = (multi = 1) => value =>
  isNil(value) ? '' : value === 0 ? '< 1KB' : filesize(value * multi)

const makeProjectNameLink = (
  uri,
  options = {
    style: {
      whiteSpace: 'nowrap'
    }
  }
) =>
  link(
    (name, project) => ({
      text: name,
      to: `${uri}/${project.id}`,
      icon: <Projects />
    }),
    options
  )

const makeProjectContentNameLink = (
  uri,
  options = {
    style: {
      whiteSpace: 'nowrap'
    }
  }
) =>
  link(
    (name, project) => ({
      text: name,
      to: `${uri}/${project.id}`
    }),
    options
  )

const makeProjectNotificationLink = (
  uri,
  options = {
    style: {
      whiteSpace: 'nowrap',
      fontWeight: 'bold'
    }
  }
) =>
  link(
    (name, project) => ({
      text: name,
      to: `${uri}/${project.id}`
    }),
    options
  )

export default {
  iconActionStyle,
  renderEdit,
  renderDelete,
  renderEditItem,
  renderDeleteItem,
  renderEditProject,
  renderDeleteProject,
  renderConvertDataset,
  renderDownloadDataset,
  renderPath,
  size,
  link,
  makeProjectNameLink,
  makeProjectContentNameLink,
  makeProjectNotificationLink
}
