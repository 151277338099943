import { eventChannel } from 'redux-saga'
import { put, delay } from 'redux-saga/effects'

import { DEFAULT_NOTIFY_TIMEOUT } from '../shared/constants'
import { showNotification, showLink } from '../actions/ui'

export const createEventChannelCallback = async cb => {
  let channel

  const emit = await new Promise(resolve => {
    channel = eventChannel(emit => {
      resolve(emit)
      return () => {}
    })
  })

  const enhancedCb = (...args) => cb(emit, ...args)

  return [channel, enhancedCb]
}

export function* notify(message) {
  yield put(showNotification(message))
  yield delay(DEFAULT_NOTIFY_TIMEOUT)
  yield put(showNotification(null))
}

export function* linkNotify(message, project) {
  yield put(showLink(message, project))
}
