import * as React from 'react'
import { Route } from 'react-router-dom'
import { css } from 'emotion'
import Typography from '@material-ui/core/Typography'
import theme from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'

interface PrivateRouteProps {
  // tslint:disable-next-line:no-any
  component: any
  isSignedIn: boolean
  // tslint:disable-next-line:no-any
  location: any
}

const paddingStyle = css`
  padding: ${theme.spacing(1)}px;
`
const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, isSignedIn, location, ...rest } = props

  return (
    <Route
      {...rest}
      render={routeProps =>
        isSignedIn ? (
          <Component {...routeProps} />
        ) : (
          <Typography className={paddingStyle} variant="h4" color="textPrimary">
            You don&apos;t have access to this page.
          </Typography>
        )
      }
    />
  )
}

export default PrivateRoute
