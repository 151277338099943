import React, { useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import MikeButton from '@mike/mike-shared-frontend/mike-button'
import { ReactComponent as Plus } from '@mike/mike-shared-frontend/media/icons/Plus'
import TextField from '../formik/TextField'
import CheckboxField from '../formik/CheckboxField'
import classNames from 'classnames'
import { useConversionStyles } from './styles/useConversionStyles'
import { iconStyle } from './styles/iconStyles'
import readerWriterTranslation from './translations/readerWriterTranslations'
import ContextHelpContainer from '../ContextHelp'
import { IParameterErrors } from './DatasetConversion'
import { insertSpaces } from './support'

interface IParameter {
  name: string
  description: string
  dataType: string
  required: boolean
}

interface IProps {
  parameter: IParameter
  key: string
  disabled?: boolean
  reset: boolean
  handleReset: () => void
  parameterErrors?: IParameterErrors
}

/**
 * @name ParameterOutputField
 * @summary The paramter output field for writer specific output parameters.
 */
export const ParameterOutputField = (props: IProps) => {
  const classes = useConversionStyles()
  const [expandBooleanField, setExpandBooleanField] = useState(false)
  const [expandNumericField, setExpandNumericField] = useState(false)
  const [expandParameter, setExpandParameter] = useState(false)

  const { parameter, disabled, reset, handleReset, parameterErrors } = props

  useEffect(() => {
    if (reset) {
      setExpandBooleanField(false)
      setExpandNumericField(false)
      setExpandParameter(false)
      handleReset()
    }
  }, [reset, handleReset])

  const formatParameterName = outputParameter => {
    const id = 'import.writers.outputParameters.' + outputParameter
    return readerWriterTranslation[id]
      ? readerWriterTranslation[id]
      : insertSpaces(outputParameter)
  }

  let outputField
  switch (parameter.dataType) {
    case 'boolean':
      outputField = ''
      break
    case 'int':
      outputField = (
        <Grid container alignItems="flex-end">
          <Grid item xs={11} key={parameter.name}>
            <Collapse
              in={parameter.required ? false : !expandNumericField}
              timeout="auto"
              unmountOnExit
            >
              <Grid container alignItems="center" className={classes.marginTop}>
                <Grid item>
                  <MikeButton
                    onClick={() => {
                      !disabled && setExpandNumericField(true)
                    }}
                    buttontype="text"
                    className={classNames(
                      classes.plusButton,
                      disabled && classes.plusButtonDisabled
                    )}
                  >
                    <Plus className={iconStyle(disabled)} />
                    {formatParameterName(parameter.name)}
                  </MikeButton>
                </Grid>
                <Grid item>
                  <ContextHelpContainer
                    disabled={disabled}
                    helpTexts={[parameter.description]}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse
              in={parameter.required ? true : expandNumericField}
              timeout="auto"
              unmountOnExit
            >
              <Grid
                container
                alignItems="flex-end"
                className={classes.marginTop}
              >
                <Grid item xs={11}>
                  <TextField
                    name={`writerParameters.${parameter.name}`}
                    error={
                      parameterErrors &&
                      parameterErrors.writerParameters &&
                      parameterErrors.writerParameters.includes(parameter.name)
                    }
                    helperText={
                      parameterErrors &&
                      parameterErrors.writerParameters &&
                      parameterErrors.writerParameters.includes(parameter.name)
                        ? 'Please add a numeric value'
                        : ''
                    }
                    label={formatParameterName(parameter.name)}
                    type="number"
                    disabled={disabled}
                    className={classes.textField}
                    required={parameter.required}
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContextHelpContainer
                    disabled={disabled}
                    helpTexts={[parameter.description]}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      )
      break
    case 'bool':
      outputField = (
        <Grid container alignItems="flex-end">
          <Grid item xs={12} key={parameter.name}>
            <Collapse
              in={parameter.required ? false : !expandBooleanField}
              timeout="auto"
              unmountOnExit
            >
              <Grid container alignItems="center" className={classes.marginTop}>
                <Grid item>
                  <MikeButton
                    onClick={() => {
                      !disabled && setExpandBooleanField(true)
                    }}
                    buttontype="text"
                    className={classNames(
                      classes.plusButton,
                      disabled && classes.plusButtonDisabled
                    )}
                  >
                    <Plus className={iconStyle(disabled)} />
                    {formatParameterName(parameter.name)}
                  </MikeButton>
                </Grid>
                <Grid item>
                  <ContextHelpContainer
                    disabled={disabled}
                    helpTexts={[parameter.description]}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse
              in={parameter.required ? true : expandBooleanField}
              timeout="auto"
              unmountOnExit
            >
              <Grid
                container
                alignItems="flex-end"
                className={classes.marginTop}
              >
                <Grid item xs={11}>
                  <CheckboxField
                    name={`writerParameters.${parameter.name}`}
                    label={formatParameterName(parameter.name)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContextHelpContainer
                    disabled={disabled}
                    helpTexts={[parameter.description]}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      )
      break
    default:
      outputField = (
        <Grid container alignItems="flex-end">
          <Grid item xs={12} key={parameter.name}>
            <Collapse
              in={parameter.required ? false : !expandParameter}
              timeout="auto"
              unmountOnExit
            >
              <Grid container alignItems="center" className={classes.marginTop}>
                <Grid item>
                  <MikeButton
                    onClick={() => {
                      !disabled && setExpandParameter(true)
                    }}
                    buttontype="text"
                    className={classNames(
                      classes.plusButton,
                      disabled && classes.plusButtonDisabled
                    )}
                  >
                    <Plus className={iconStyle(disabled)} />
                    {formatParameterName(parameter.name)}
                  </MikeButton>
                </Grid>
                <Grid item>
                  <ContextHelpContainer helpTexts={[parameter.description]} />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse
              in={parameter.required ? true : expandParameter}
              timeout="auto"
              unmountOnExit
            >
              <Grid
                container
                alignItems="flex-end"
                className={classes.marginTop}
              >
                <Grid item xs={11}>
                  <TextField
                    disabled={disabled}
                    error={
                      parameterErrors &&
                      parameterErrors.writerParameters &&
                      parameterErrors.writerParameters.includes(parameter.name)
                    }
                    fullWidth
                    helperText={
                      parameterErrors &&
                      parameterErrors.writerParameters &&
                      parameterErrors.writerParameters.includes(parameter.name)
                        ? 'Please add a value'
                        : ''
                    }
                    name={`writerParameters.${parameter.name}`}
                    label={formatParameterName(parameter.name)}
                    required={parameter.required}
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContextHelpContainer helpTexts={[parameter.description]} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      )
  }

  return outputField
}

export default ParameterOutputField
