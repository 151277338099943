import React from 'react'
import objectHash from 'object-hash'
import { Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import TextField from '../formik/TextField'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import { MikeButton } from '@mike/mike-shared-frontend'
import { css } from 'emotion'
import { useTypedSelector } from '../../reducers'

const submitButtonStyle = css`
  float: right;
`

const validationSchema = Yup.object().shape({
  tenantName: Yup.string().required('Required'),
  billingReference: Yup.string()
})

export type SiteDetailsFormProps = {
  tenantName: string
  billingReference: string
}

interface IProps {
  values: SiteDetailsFormProps
  handleSubmit: (values: SiteDetailsFormProps) => void
}

const SiteDetailsForm = (props: IProps) => {
  const intl = useIntl()
  const { handleSubmit, values } = props
  const isBackOffice = useTypedSelector(state => state.admin.isBackOffice)

  return (
    <Formik
      key={objectHash(values)}
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => {
        const { handleChange, setFieldTouched } = formik

        const change = (name, e) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="tenantName"
                  label={intl.formatMessage({
                    id: 'screens.siteInfo.siteName'
                  })}
                  fullWidth
                  required
                  autoFocus
                  onChange={change.bind(null, 'tenantName')}
                />
              </Grid>
              {isBackOffice && (
                <Grid item xs={12}>
                  <TextField
                    name="billingReference"
                    label={intl.formatMessage({
                      id: 'screens.siteInfo.billingReference'
                    })}
                    fullWidth
                    required
                    autoFocus
                    onChange={change.bind(null, 'billingReference')}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <MikeButton
                  className={submitButtonStyle}
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  {intl.formatMessage({
                    id: 'screens.siteDetailsForm.updateButton'
                  })}
                </MikeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SiteDetailsForm
