import { administration, metadata } from './support'
import IAPIAccess from '../model/IAPIAccess'
import IAPIKey from '../model/IAPIKey'
import ITenantUser from '../model/ITenantUser'
import ITenant from '../model/ITenant'

const BASE_URL = 'admin'
const BASE_IAM_URL = 'iam'

export interface IBillingInformation {
  billingReference: string
  billingReferenceTag: string
  billingReferenceType: string
}

export interface ICreateTenant {
  name: string
  adminUserId: string
  billingInformation: IBillingInformation
}

export interface ICreateTenantFormValues {
  name: string
  billingReference: string
  billingReferenceType: 'Maconomy' | 'DHI'
  billingReferenceTag: string
}

export interface SASTokenReturnType {
  data: string
}
export const getSASToken = (tenantId: string): Promise<SASTokenReturnType> => {
  return metadata.getJson(
    `services/sastoken?projectId=${tenantId}`,
    1
  ) as Promise<SASTokenReturnType>
}

export const getCredits = () => administration.getJson(`${BASE_URL}/credit`, 3)

export const getTenantUsers = () =>
  administration.getJson(`${BASE_IAM_URL}/tenantuser`, 3)

export const getUserTenants = () =>
  administration.getJson(`${BASE_IAM_URL}/tenant/my`, 3)

export const postTenant = (data: ICreateTenant) =>
  administration.postJson(`${BASE_IAM_URL}/tenant`, data, 3)

export const patchTenant = (data: ITenant) =>
  getSASToken(data.id).then((token: SASTokenReturnType) => {
    administration.postJson(
      `${BASE_IAM_URL}/tenant/${data.id}`,
      { name: data.name },
      3,
      {
        'dhi-sas-token': token.data
      }
    )
  })

export const putBillingInformation = (
  tenantId,
  billingInformation: IBillingInformation
) =>
  metadata.putJson(
    `metadata/project/${tenantId}/billing-information`,
    { id: tenantId, billingInformation },
    3
  )

export const getUsers = (tenantId: string) =>
  metadata.getJson(`metadata/project/${tenantId}/member`, 3)

export const getProject = (tenantId: string) =>
  metadata.getJson(`metadata/project/${tenantId}`, 3)

export const inviteUserApi = (
  email: string,
  role: string,
  redirectUri: string,
  tenantId: string
) => {
  const payload = {
    email,
    role
  }

  if (redirectUri !== 'none') {
    payload['redirectUri'] = redirectUri
  }

  return getSASToken(tenantId).then((token: SASTokenReturnType) => {
    return administration.postJson(
      `${BASE_IAM_URL}/project/${tenantId}/invite`,
      payload,
      3,
      {
        'dhi-sas-token': token.data
      }
    )
  })
}

export const acceptInvitation = (
  invitationVersion: string,
  invitationIv: string,
  invitation: string
) => {
  const params = { invitationVersion, invitationIv, invitation }
  return administration.postJson(
    `${BASE_IAM_URL}/invite/accept?${new URLSearchParams(params).toString()}`,
    {},
    3
  )
}

export const postUsers = (users: ITenantUser[], rowVersion, tenantId: string) =>
  metadata.putJson(
    `metadata/project/${tenantId}/members`,
    {
      members: users.map(user => ({
        userId: user.id,
        role: user.role
      })),
      rowVersion: rowVersion
    },
    3
  )

export const postUser = (data, tenantId: string) =>
  metadata.postJson(`metadata/project/${tenantId}/member`, data, 3)

export const delUser = (id: string, tenantId: string) =>
  metadata.delJson(`metadata/project/${tenantId}/member/${id}`, 3)

export const delUserIam = (id: string) =>
  administration.delJson(`${BASE_IAM_URL}/useridentity/${id}`, 3)

export interface GetAccountsReturnType {
  data: IAPIAccess[]
}
export const getAccounts = (tenantId: string) =>
  administration.getJson(
    `${BASE_IAM_URL}/project/${tenantId}/account/list`,
    3,
    {
      'dhi-project-id': tenantId
    }
  ) as Promise<GetAccountsReturnType>

export const postAccount = (tenantId, account: IAPIAccess) =>
  administration.postJson(
    `${BASE_IAM_URL}/project/${tenantId}/account`,
    account,
    3,
    {
      'dhi-project-id': tenantId
    }
  )

export const patchAccount = (tenantId, account: IAPIAccess) =>
  administration.patchJson(
    `${BASE_IAM_URL}/project/${tenantId}/account`,
    account,
    3,
    {
      'dhi-project-id': tenantId
    }
  )

export const deleteAccounts = (tenantId, accountList: IAPIAccess[]) =>
  accountList.map(account => {
    return administration.delJson(
      `${BASE_IAM_URL}/project/${tenantId}/account/${account.accountId}`,
      3,
      {
        'dhi-project-id': tenantId
      }
    )
  })

export interface GetKeysReturnType {
  data: IAPIKey[]
}
export const getApiKeys = (tenantId: string, account: IAPIAccess) =>
  administration.getJson(
    `${BASE_IAM_URL}/project/${tenantId}/account/${account.accountId}/keys`,
    3,
    {
      'dhi-project-id': tenantId
    }
  ) as Promise<GetKeysReturnType>

export const postAPIKey = (tenantId, account: IAPIAccess, key: IAPIKey) =>
  administration.postJson(
    `${BASE_IAM_URL}/project/${tenantId}/account/${account.accountId}/key`,
    key,
    3,
    {
      'dhi-project-id': tenantId
    }
  )

export const patchAPIKey = (tenantId, account: IAPIAccess, key: IAPIKey) =>
  administration.patchJson(
    `${BASE_IAM_URL}/project/${tenantId}/account/${account.accountId}/key/${key.publicId}`,
    key,
    3,
    {
      'dhi-project-id': tenantId
    }
  )

export const deleteAPIKeys = (tenantId, keys: IAPIKey[]) =>
  keys.map(key => {
    return administration.delJson(
      `${BASE_IAM_URL}/project/${tenantId}/account/${key.accountId}/key/${key.publicId}`,
      3,
      {
        'dhi-project-id': tenantId
      }
    )
  })

export const getCurrentConsumptionLogUrl = () =>
  metadata.getJson(`services/consumption/baseuri`, 3)
