import React, { useState } from 'react'
import { useOidc } from '@axa-fr/react-oidc'
import Fab from '@material-ui/core/Fab'
import { Typography } from '@material-ui/core'
import { useTypedSelector } from '../../reducers'
import MikeButton from '@mike/mike-shared-frontend/mike-button/MikeButton'

import './mike-login.css'
import Menu from '@material-ui/core/Menu'

interface IProps {
  logInLabel?: string
  logOutLabel?: string
  flat?: boolean
  logoutClickCallback?: any
}

export const LOGGING_OUT = 'loggingOut'

/**
 * @name MikeLogin
 * @summary Login component for use within CloudBridge applications.
 */
const MikeLogin = (props: IProps) => {
  const { logOutLabel, flat, logoutClickCallback } = props
  const { logout } = useOidc()

  const user = useTypedSelector(state => state['auth'].user)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleLogout = async () => {
    logoutClickCallback()
    sessionStorage.setItem(LOGGING_OUT, 'true')
    logout('/')
  }

  const open = Boolean(anchorEl!)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const fabStyle = {
    fontWeight: 300,
    fontSize: 12,
    minHeight: 0,
    width: 32,
    height: 32,
    boxShadow: flat ? 'none' : undefined
  }

  return (
    <>
      <Fab
        onClick={handleOpen}
        color="primary"
        size="small"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={fabStyle}
      >
        {user?.initials || ''}
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl!)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        getContentAnchorEl={null}
      >
        <div
          style={{
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            gap: '1rem'
          }}
        >
          <Typography>{user?.name}</Typography>
          <MikeButton buttontype="primary" size="small" onClick={handleLogout}>
            {logOutLabel}
          </MikeButton>
        </div>
      </Menu>
    </>
  )
}

export default MikeLogin
