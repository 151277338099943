import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IProject from '../model/IProject'
import { StateEnum } from '../model/StateEnum'

export type RootProjectListQueryParams = {
  page: number
  rowsPerPage: number
  sortOrder: string
  orderBy: string
  namePrefix: string
}

export type RootProjectLisState = {
  projects: IProject[]
  params: RootProjectListQueryParams
  totalCount: number
  state: StateEnum
}

const initState = {
  projects: [],
  params: {
    page: 0,
    rowsPerPage: 10,
    sortOrder: 'desc',
    orderBy: 'updatedAt',
    namePrefix: ''
  },
  totalCount: -1,
  state: StateEnum.INIT
} as RootProjectLisState

const rootProjectLisSlice = createSlice({
  name: 'rootProjectLis',
  initialState: initState as RootProjectLisState,
  reducers: {
    resetRootProjectLoadingState: state => {
      return {
        ...state,
        state: StateEnum.INIT
      }
    },
    setRootProjectListLoadingState: (
      state,
      action: PayloadAction<{ loadingState: boolean }>
    ): RootProjectLisState => {
      return {
        ...state,
        state: action.payload.loadingState
          ? StateEnum.LOADING
          : StateEnum.LOADED
      }
    },
    setTotalCount: (
      state,
      action: PayloadAction<{ totalCount: number }>
    ): RootProjectLisState => {
      return {
        ...state,
        totalCount: action.payload.totalCount
      }
    },
    setRootProjectQueryParams: (
      state,
      action: PayloadAction<RootProjectListQueryParams>
    ) => {
      return {
        ...state,
        params: action.payload
      }
    },
    setRootProjectListData: (
      state,
      action: PayloadAction<IProject[]>
    ): RootProjectLisState => {
      return {
        ...state,
        projects: action.payload
      }
    },
    setRootProjectListState: (
      state,
      action: PayloadAction<RootProjectLisState>
    ): RootProjectLisState => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export const {
  setRootProjectListLoadingState,
  setRootProjectListState,
  setTotalCount,
  setRootProjectQueryParams,
  setRootProjectListData,
  resetRootProjectLoadingState
} = rootProjectLisSlice.actions

export default rootProjectLisSlice.reducer
