import produce from 'immer'

import {
  LOAD_CUSTOMER_USERS,
  LOADING_CUSTOMER_USERS,
  SET_CUSTOMER_USERS
} from '../actions/customerUsers'

const initState = {
  loading: false,
  searchText: null,
  data: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case LOAD_CUSTOMER_USERS:
      draft.searchText = action.searchText
      return

    case SET_CUSTOMER_USERS:
      draft.data = action.customerUsers
      return

    case LOADING_CUSTOMER_USERS:
      draft.loading = action.loading
      return

    default:
      return
  }
}, initState)
