import { parseError } from '../helpers/errors'

export const ADD_ERROR = 'ERRORS/ADD'
export const addError = (name, error) => ({
  type: ADD_ERROR,
  error: parseError(error, { name })
})

export const REMOVE_ERROR = 'ERRORS/REMOVE'
export const removeError = id => ({
  type: REMOVE_ERROR,
  id
})
