import { put, takeEvery, all, delay } from 'redux-saga/effects'
//import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { ITrackProgressItem } from '../model/ITrackProgressItem'
import { PROGRESS_ITEM_TIMEOUT } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner'
import {
  removeUpload,
  UPLOAD_FILE,
  UPDATE_PROGRESS,
  UPLOAD_FAIL
} from '../actions/uploads'
import {
  IMPORT_START,
  IMPORT_FAIL,
  IMPORT_COMPLETE,
  removeImport
} from '../actions/imports'
import {
  EXPORT_START,
  UPDATE_EXPORT_PROGRESS,
  EXPORT_FAIL,
  EXPORT_COMPLETE,
  removeExport
} from '../actions/exports'
import {
  CONVERT_START,
  UPDATE_CONVERSION_PROGRESS,
  CONVERT_FAIL,
  CONVERT_COMPLETE,
  removeConvert
} from '../actions/converts'

import {
  updateOrAddProgressItem,
  progressItemComplete,
  progressItemFailed,
  removeProgressItem
} from '../actions/progressItems'
import { addError } from '../actions/errors'

export default function* watchProgress() {
  yield takeEvery(UPLOAD_FILE, handleUploadStart)
  yield takeEvery(IMPORT_START, handleImportStart)
  yield takeEvery(UPLOAD_FAIL, handleProcessFailed)
  yield takeEvery(UPDATE_PROGRESS, handleUpdateUploadProgress)

  yield takeEvery(IMPORT_START, handleImportStart)
  yield takeEvery(IMPORT_FAIL, handleProcessFailed)
  yield takeEvery(IMPORT_COMPLETE, handleImportComplete)

  yield takeEvery(EXPORT_START, handleExportStart)
  yield takeEvery(UPDATE_EXPORT_PROGRESS, handleUpdateProgress)
  yield takeEvery(EXPORT_FAIL, handleProcessFailed)
  yield takeEvery(EXPORT_COMPLETE, handleExportComplete)

  yield takeEvery(CONVERT_START, handleConvertStart)
  yield takeEvery(UPDATE_CONVERSION_PROGRESS, handleUpdateProgress)
  yield takeEvery(CONVERT_FAIL, handleProcessFailed)
  yield takeEvery(CONVERT_COMPLETE, handleConversionComplete)
}

export function* handleProcessFailed(action) {
  const { id, error } = action
  const progressItem: ITrackProgressItem = {
    id: id,
    title: 'Error',
    progressValue: 75
  }
  yield put(progressItemFailed(progressItem))
  yield put(addError(action.type, error))
  yield delay(PROGRESS_ITEM_TIMEOUT)
  yield put(removeProgressItem(progressItem))
  yield all([
    put(removeImport(id)),
    put(removeUpload(id)),
    put(removeExport(id)),
    put(removeConvert(id))
  ])
}

export function* handleUpdateUploadProgress(action) {
  const { id, progress, fileName } = action
  const progressItem: ITrackProgressItem = {
    id: id,
    title: 'Step 1 of 2: Uploading (' + fileName + ')',
    progressValue: progress / 2
  }
  yield put(updateOrAddProgressItem(progressItem))
}

export function* handleUpdateProgress(action) {
  const { id, progress, title } = action
  const progressItem: ITrackProgressItem = {
    id: id,
    title: title && title,
    progressValue: progress
  }
  yield put(updateOrAddProgressItem(progressItem))
}

export function* handleUploadStart(action) {
  const { id, fileName } = action
  const progressItem: ITrackProgressItem = {
    id: id,
    title: 'Step 1 of 2: Uploading (' + fileName + ')',
    progressValue: 0
  }
  yield put(updateOrAddProgressItem(progressItem))
}

export function* handleExportStart(action) {
  const { id, title } = action
  const progressItem: ITrackProgressItem = {
    id: id,
    title: title,
    progressValue: 0
  }
  yield put(updateOrAddProgressItem(progressItem))
}

export function* handleConvertStart(action) {
  const { id, title } = action
  const progressItem: ITrackProgressItem = {
    id: id,
    title: title,
    progressValue: 0
  }
  yield put(updateOrAddProgressItem(progressItem))
}

export function* handleImportStart(action) {
  const { id, data, initialId } = action
  if (initialId) {
    const name =
      data && data.name
        ? ' (' + data.name + ')'
        : data && data.fileName
        ? ' (' + data.fileName + ')'
        : ''

    const progressItem: ITrackProgressItem = {
      id: initialId,
      title: 'Step 2 of 2: Converting' + name,
      progressValue: 75,
      importId: id
    }
    yield put(updateOrAddProgressItem(progressItem))
  }
}

export function* handleExportComplete(action) {
  const progressItem: ITrackProgressItem = {
    id: action.id,
    title: 'Download complete',
    progressValue: 100
  }
  yield put(progressItemComplete(progressItem))
  yield delay(PROGRESS_ITEM_TIMEOUT)
  yield put(removeProgressItem(progressItem))
  yield put(removeExport(action.id))
}

export function* handleConversionComplete(action) {
  const progressItem: ITrackProgressItem = {
    id: action.id,
    title: 'Conversion complete',
    progressValue: 100
  }
  yield put(progressItemComplete(progressItem))
  yield delay(PROGRESS_ITEM_TIMEOUT)
  yield put(removeProgressItem(progressItem))
  yield put(removeConvert(action.id))
}

export function* handleImportComplete(action) {
  const progressItem: ITrackProgressItem = {
    id: action.id,
    title: 'Upload complete',
    progressValue: 100
  }
  yield put(progressItemComplete(progressItem))
  yield delay(PROGRESS_ITEM_TIMEOUT)
  yield put(removeProgressItem(progressItem))
  yield all([put(removeImport(action.id)), put(removeUpload(action.id))])
}
