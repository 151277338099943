import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { MIKE_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors'
import withFormik from './withFormik'

const styles = theme => ({
  progress: {
    position: 'absolute',
    top: theme.spacing(6),
    left: 0,
    width: '100%'
  },
  formControl: {
    height: 'auto',
    minWidth: theme.spacing(15)
  },
  input: {
    paddingTop: theme.spacing(2.25),
    paddingBottom: 0
  },
  inputNoLabel: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.75)
  },
  iconDisabled: {
    fill: theme.palette.mediumGrey && theme.palette.mediumGrey.main
  },
  iconError: {
    fill: theme.palette.error.main
  },
  iconHidden: {
    opacity: 0
  },
  primaryDisabled: {
    color: theme.palette.mediumGrey.main
  },
  primarySelected: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  },
  helperTextPlaceHolder: {
    minHeight: theme.spacing(2),
    backgroundColor: 'transparent'
  },
  menuList: {
    backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT
  }
})

const SelectField = ({
  autoFocus,
  classes,
  disabled,
  error,
  fullWidth,
  helperText,
  hideIcon,
  items,
  label,
  loading,
  name,
  onBlur,
  onChange,
  onSelectClose,
  onSelectOpen,
  primaryField,
  required,
  secondaryField,
  selectOpen,
  value,
  valueField,
  formik
}) => {
  const [open, setOpen] = useState(false)

  const isOpen = selectOpen || open

  const handleOpen = (...args) => {
    onSelectOpen && onSelectOpen(...args)
    setOpen(true)
  }

  const handleClose = (...args) => {
    onSelectClose && onSelectClose(...args)
    setOpen(false)
  }

  const handleChange = (...args) => {
    setOpen(false)
    onChange(...args)
  }

  const forceUpdateOnItemListChange = `${name}-${items.length}`

  return (
    <FormControl
      className={classes.formControl}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      error={error}
      margin={'dense'}
      variant={label ? 'filled' : 'standard'}
    >
      {label && (
        <InputLabel htmlFor={name} required={false} disabled={disabled}>
          {label}
        </InputLabel>
      )}

      <Select
        key={forceUpdateOnItemListChange}
        autoFocus={autoFocus}
        inputProps={{
          name,
          id: name,
          classes: {
            root:
              value !== ''
                ? label
                  ? classes.input
                  : classes.inputNoLabel
                : '',
            icon: hideIcon
              ? classes.iconHidden
              : error
              ? classes.iconError
              : disabled && classes.iconDisabled
          }
        }}
        MenuProps={{ classes: { paper: classes.menuList } }}
        onBlur={onBlur}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={isOpen}
        value={value}
      >
        {items.map(item => {
          const itemValue = get(item, valueField)
          const selectedItemValue = get(formik.values, name)
          const primary = get(item, primaryField)
          const secondary = get(item, secondaryField)

          return (
            <MenuItem
              className={classes.menuItem}
              key={itemValue}
              value={itemValue}
            >
              <ListItemText
                classes={
                  disabled
                    ? { primary: classes.primaryDisabled }
                    : itemValue === selectedItemValue && isOpen
                    ? { primary: classes.primarySelected }
                    : {}
                }
                primary={primary}
                secondary={
                  itemValue === selectedItemValue && !isOpen ? null : secondary
                }
              />
            </MenuItem>
          )
        })}
      </Select>

      {loading && <LinearProgress className={classes.progress} />}

      {helperText ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : (
        <div className={classes.helperTextPlaceHolder} />
      )}
    </FormControl>
  )
}
SelectField.propTypes = {
  autoFocus: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  hideIcon: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSelectClose: PropTypes.func,
  onSelectOpen: PropTypes.func,
  primaryField: PropTypes.string.isRequired,
  required: PropTypes.bool,
  secondaryField: PropTypes.string,
  selectOpen: PropTypes.bool,
  value: PropTypes.any.isRequired,
  valueField: PropTypes.string.isRequired
}

export default withFormik(withStyles(styles)(SelectField))
