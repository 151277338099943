import { coordinateSystem } from './support'

/* const BASE_URL =
  'data/00000000-0000-0000-0000-000000000000/coordinatesystems/coordinate-system' */

const BASE_URL = 'crs/coordinate-system'

export const getCoordinateSystems = async searchText => {
  const query = searchText ? `?fulltext=${searchText}` : ''
  const { data } = await coordinateSystem.getJson(`${BASE_URL}/list${query}`, 2)
  return data
}

export const getCoordinateSystem = srid =>
  coordinateSystem.getJson(`${BASE_URL}/${srid}`)

export const getAuthorities = () =>
  coordinateSystem.getJson(`${BASE_URL}/authority`)
