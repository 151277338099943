/* eslint-disable react/prop-types */
import React from 'react'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'

import { makeStyles, createStyles, Dialog, Typography } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MikeButton from '@mike/mike-shared-frontend/mike-button'
import DialogTitle from './DialogTitle'

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    title: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1)
    },
    dialogContent: {
      maxWidth: 378,
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      backgroundColor: theme.palette.background.paper
    },
    dialogTitle: {
      padding: theme.spacing(1),
      color: theme.palette.background.paper,
      height: theme.spacing(2),
      width: '100%'
    },
    dialogActions: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.background.paper,
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      width: '100%'
    }
  })
})

interface IProps {
  open: boolean
  onCancel: () => void
  onOk: () => void
  children?: React.ReactNode
  title?: string
  message?: string
  cancel?: string
  ok?: string
  hideCancelButton?: boolean
}

const ConfirmationDialog = (props: IProps) => {
  const classes = useStyles()
  const {
    children,
    open,
    message,
    title,
    ok = 'Ok',
    cancel = 'Cancel',
    onCancel,
    onOk
  } = props

  return (
    <Dialog maxWidth={'xs'} onClose={onCancel} open={open} scroll={'paper'}>
      <MuiDialogTitle className={classes.dialogTitle}>
        <DialogTitle onClose={onCancel} />
      </MuiDialogTitle>
      {children ? (
        children
      ) : (
        <MuiDialogContent className={classes.dialogContent}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2">{message}</Typography>
        </MuiDialogContent>
      )}
      <DialogActions className={classes.dialogActions}>
        {!props.hideCancelButton && (
          <MikeButton onClick={onCancel} buttontype="text">
            {cancel}
          </MikeButton>
        )}
        <MikeButton
          style={
            props.hideCancelButton ? { width: '95%' } : { width: 'default' }
          }
          onClick={onOk}
          buttontype="primary"
        >
          {ok}
        </MikeButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
