import { takeEvery, put, call, select } from 'redux-saga/effects'
import {
  setIsAdmin,
  setIsBackOffice,
  setIsRootContributor
} from '../actions/admin'
import { SET_IS_AUTHENTICATED, SET_USER } from '../actions/auth'
import { loadAdminCredits } from '../actions/admin'
import { BACKOFFICE_ROLE } from '../shared/constants'
import { getCapabilities } from '../apis/project'
import { getUser } from '../state'
import { loadTenantFeatures } from '../actions/features'
import { loadTenants } from '../actions/tenant'

export default function* watchAuth() {
  yield takeEvery(SET_USER, handleAuthData)
  yield takeEvery(SET_IS_AUTHENTICATED, handleIsAuthenticated)
}

function* handleIsAuthenticated(action) {
  const { isAuthenticated } = action
  const user = yield select(getUser)
  if (isAuthenticated && user) {
    yield put(loadAdminCredits())
    yield put(loadTenantFeatures())
    try {
      const capabilities = yield call(getCapabilities, user.tenantId)

      if (capabilities?.canGrantAccess) {
        yield put(setIsAdmin(true))
      } else {
        yield put(setIsAdmin(false))
      }

      if (capabilities?.canCreateContent) {
        yield put(setIsRootContributor(true))
      } else {
        yield put(setIsRootContributor(false))
      }
    } catch (error) {
      yield put(setIsAdmin(false))
      yield put(setIsRootContributor(false))
    }
  }
}

function* handleAuthData(action) {
  const { user } = action

  if (user) {
    const isBackOffice =
      user && user.roles && user.roles.includes(BACKOFFICE_ROLE)
    yield put(loadTenants(isBackOffice))
    yield put(setIsBackOffice(isBackOffice))
  }
}
