import { call, put, takeEvery } from 'redux-saga/effects'
import { addError } from '../actions/errors'
import {
  LoadRootProjectListType,
  LOAD_ROOT_PROJECT_LIST
} from '../actions/rootProjectActions'
import { getRootProjectList } from '../apis/projects'
import {
  setRootProjectListData,
  setRootProjectListLoadingState,
  setRootProjectQueryParams,
  setTotalCount
} from '../reducers/rootProjectList'

export default function* watchRootProjectList() {
  yield takeEvery(LOAD_ROOT_PROJECT_LIST, fetchRootProjectList)
}

export function* fetchRootProjectList(action: LoadRootProjectListType) {
  yield put(setRootProjectListLoadingState({ loadingState: true }))
  yield put(setRootProjectQueryParams(action.params))
  try {
    const result = yield call(getRootProjectList, action.params)

    yield put(setRootProjectListData(result.data))
    yield put(setTotalCount({ totalCount: result.totalCount }))
  } catch (error) {
    yield put(addError(LOAD_ROOT_PROJECT_LIST, error))
  } finally {
    yield put(setRootProjectListLoadingState({ loadingState: false }))
  }
}
