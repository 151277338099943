import uniqid from 'uniqid'

export const EXPORT_AND_DOWNLOAD_DATASET = 'EXPORT_AND_DOWNLOAD_DATASET'
export const exportAndDownloadDataset = (
  fileName,
  datasetId,
  projectId,
  data
) => ({
  type: EXPORT_AND_DOWNLOAD_DATASET,
  id: uniqid.time(),
  fileName,
  datasetId,
  projectId,
  data
})

export const EXPORT_AND_DOWNLOAD_MANY_DATASETS =
  'EXPORT_AND_DOWNLOAD_MANY_DATASETS'
export const exportAndDownloadManyDatasets = (datasets, data) => ({
  type: EXPORT_AND_DOWNLOAD_MANY_DATASETS,
  datasets,
  data
})
