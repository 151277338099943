import React from 'react'
import objectHash from 'object-hash'
import map from 'lodash/map'
import { Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import TextField from '../formik/TextField'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import messages from '../../shared/messages'
import { MikeButton } from '@mike/mike-shared-frontend'
import { css } from 'emotion'
import merge from 'lodash/merge'
import SelectField from '../formik/SelectField'
import { ROLE_TYPES } from '../../helpers/project'

// type TenantFeature = {
//   url?: string
//   featureId: string
//   name: string
//   type: 'Application'
//   enabled: boolean
// }

const submitButtonStyle = css`
  float: right;
`
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required'),
  role: Yup.string().required('Required'),
  url: Yup.string()
})

export type UserInviteType = {
  email: string
  role: string
  url: string
}

interface IProps {
  shouldAutoFocus?: boolean
  isEditMode?: boolean
  initialValues?: UserInviteType
  handleSubmit: (values: UserInviteType) => void
}

const UserInviteForm = (props: IProps) => {
  // const [redirects, setRedirects] = useState<{ label: string; id: string }[]>([
  //   { label: 'None', id: '' }
  // ])
  const intl = useIntl()
  const {
    // isEditMode = false,
    handleSubmit,
    initialValues,
    shouldAutoFocus
  } = props

  const defaultInitialValues: UserInviteType = {
    email: '',
    role: 'Reader',
    url: 'none'
  }

  const mergedInitialValues = {
    ...merge({}, defaultInitialValues, initialValues)
  }

  // useEffect(() => {
  //   const getTenantFeaturesFromApi = async () => {
  //     const tenantFeatures: { data: TenantFeature[] } = await getTenantFeatures()
  //
  //     const parsedFeatures: { label: string; id: string }[] = tenantFeatures.data
  //       .filter(feature => Boolean(feature?.url))
  //       .filter(feature => feature.enabled)
  //       .map(feature => ({
  //         label: feature.name,
  //         id: feature.url!
  //       }))
  //
  //     parsedFeatures.unshift({ label: 'None', id: 'none' })
  //
  //     setRedirects(parsedFeatures)
  //   }
  //
  //   getTenantFeaturesFromApi().catch(console.error)
  // }, [])

  return (
    <Formik
      key={objectHash(mergedInitialValues)}
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => {
        const { handleChange, setFieldTouched } = formik

        const change = (name, e) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label={intl.formatMessage(messages.email)}
                  type="email"
                  fullWidth
                  required
                  autoFocus={shouldAutoFocus !== false}
                  onChange={change.bind(null, 'email')}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectField
                  name={'role'}
                  valueField="name"
                  primaryField="name"
                  items={map(Object.values(ROLE_TYPES), role => ({
                    name: role
                  }))}
                  fullWidth
                />
              </Grid>

              {/*{!isEditMode && (*/}
              {/*  <Grid item xs={12}>*/}
              {/*    <Accordion>*/}
              {/*      <AccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
              {/*        <Typography variant="body1">Advanced settings</Typography>*/}
              {/*      </AccordionSummary>*/}

              {/*      <AccordionDetails>*/}
              {/*        <SelectField*/}
              {/*          label="Redirect link"*/}
              {/*          name="url"*/}
              {/*          valueField="id"*/}
              {/*          primaryField="label"*/}
              {/*          items={redirects}*/}
              {/*          value={formik.values.url}*/}
              {/*          fullWidth*/}
              {/*        />*/}
              {/*      </AccordionDetails>*/}
              {/*    </Accordion>*/}
              {/*  </Grid>*/}
              {/*)}*/}

              <Grid item xs={12}>
                <MikeButton
                  disabled={!formik.isValid || formik.isSubmitting}
                  className={submitButtonStyle}
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  {intl.formatMessage({
                    id: 'inviteUserForm.inviteButton'
                  })}
                </MikeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UserInviteForm
