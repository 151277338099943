import ITenant from '../model/ITenant'
import { IBillingInformation, ICreateTenantFormValues } from '../apis/admin'

export const LOAD_TENANTS = 'TENANT/LOAD_TENANTS'
export const loadTenants = (canCreateTenants: boolean) => ({
  type: LOAD_TENANTS,
  canCreateTenants
})

export const LOADING_TENANTS = 'TENANT/LOADING_TENANTS'
export const loadingTenants = (loadingTenants = true) => ({
  type: LOADING_TENANTS,
  loadingTenants
})

export const SET_TENANTS = 'TENANT/SET_TENANTS'
export const setTenants = (tenants: Array<ITenant>) => ({
  type: SET_TENANTS,
  tenants
})

export type UpdateTenantType = {
  type: string
  tenant: ITenant
  billingInformation: IBillingInformation
}
export const UPDATE_TENANT = 'TENANT/UPDATE'
export const updateTenant = (
  tenant: ITenant,
  billingInformation: IBillingInformation
) =>
  ({
    type: UPDATE_TENANT,
    tenant,
    billingInformation
  } as UpdateTenantType)

export const UPDATING_TENANTS = 'TENANT/UPDATING'
export const updatingTenant = (updating = true) => ({
  type: UPDATING_TENANTS,
  updating
})

export type SetCurrentTenantNameType = {
  type: string
  id: string
  name: string
}
export const SET_CURRENT_TENANT_NAME = 'TENANT/SET_CURRENT_TENANT_NAME'
export const setCurrentTenantName = (id: string, name: string) =>
  ({
    type: SET_CURRENT_TENANT_NAME,
    id,
    name
  } as SetCurrentTenantNameType)

export const CREATE_TENANT = 'TENANT/CREATE'
export const createTenant = (data: ICreateTenantFormValues) => ({
  type: CREATE_TENANT,
  data
})

export const CREATING_TENANT = 'TENANT/CREATING'
export const creatingTenant = (creating = true) => ({
  type: CREATING_TENANT,
  creating
})
