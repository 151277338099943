import produce from 'immer'
import find from 'lodash/fp/find'
import findIndex from 'lodash/fp/findIndex'

import {
  IMPORT_START,
  UPDATE_PROGRESS,
  IMPORT_COMPLETE,
  IMPORT_FAIL,
  REMOVE_IMPORT
} from '../actions/imports'

import { TRANSFER_STATUSES } from '../apis/transfer'

export default produce((draft, action) => {
  const { type, ...payload } = action

  if (
    ![
      IMPORT_START,
      UPDATE_PROGRESS,
      IMPORT_COMPLETE,
      IMPORT_FAIL,
      REMOVE_IMPORT
    ].includes(type)
  ) {
    return
  }

  const importItem = findOrCreateImportItem(draft, action)

  switch (type) {
    case IMPORT_START: {
      importItem.state = action.state
      return
    }

    case UPDATE_PROGRESS:
      importItem.progress = payload.progress
      return

    case IMPORT_COMPLETE:
      importItem.state = TRANSFER_STATUSES.COMPLETED
      return

    case IMPORT_FAIL:
      importItem.state = TRANSFER_STATUSES.ERROR
      importItem.error = payload.error
      return

    case REMOVE_IMPORT:
      removeImport(action.id)(draft)
      return

    default:
      return
  }
}, [])

const findOrCreateImportItem = (draft, action) => {
  const { ...payload } = action

  var importItem = findImport(payload.id)(draft)

  if (!importItem) {
    const item = {
      id: action.id,
      projectId: action.projectId,
      createdBy: action.createdBy,
      state: action.state,
      data: action.data,
      error: null,
      progress: 0,
      initialId: action.initialId
    }
    draft.push(item)
    return item
  }

  return importItem
}

const findImport = id => find({ id })
// const findImportByUploadId = initialId => find({ initialId })
const removeImport = id => draft => draft.splice(findIndex({ id })(draft), 1)
