import { call, put, takeLatest } from 'redux-saga/effects'
import { updateIntl } from 'react-intl-redux'

import { CHANGE_LANGUAGE } from '../actions/intl'

export default function* watchIntl() {
  yield takeLatest(CHANGE_LANGUAGE, changeLanguage)
}

export function* changeLanguage(action) {
  yield call(loadMessages, action.locale)
}

export function* loadMessages(locale = 'en') {
  let messages
  try {
    messages = yield call(getMessages, locale)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(
      `Error loading the messages for the language "${locale}"`,
      error
    )

    messages = yield call(getMessages, 'en')
  }

  yield put(updateIntl({ locale, messages }))
}

const getMessages = async lang => {
  const url = `/intl/messages/${lang}.json`
  const resp = await fetch(url)
  return resp.json()
}
