import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { getTenantFeatures, setFeatureStatus } from '../apis/features'
import fetchConfig from '../apis/config'

import {
  LOAD_TENANT_FEATURES,
  UPDATE_TENANT_APPLICATION,
  loadTenantFeatures
} from '../actions/features'

import { addError } from '../actions/errors'

import { setFeaturesList, setFeaturesLoadingState } from '../reducers/features'
import IFeature from '../model/IFeature'

export default function* watchFeatures() {
  yield takeLatest(LOAD_TENANT_FEATURES, fetchFeatures)
  yield takeEvery(UPDATE_TENANT_APPLICATION, patchApplication)
}

export function* fetchFeatures() {
  try {
    yield put(setFeaturesLoadingState({ loadingState: true }))
    const configuration = yield call(fetchConfig)
    const allFeatures: IFeature[] = (yield call(getTenantFeatures)).data
    //FeatureId of Cloud Admin in dev0, dev and test (needed when Cloud Admin is started locally)
    let ownFeatureId = '0fb30fa4-aa18-ed11-b83e-002248818247'
    if (!process.env.REACT_APP__DHI_TARGET_ENV) {
      // On deployed version FeatureId is injected during deployment
      if (configuration.auth.clientName) {
        ownFeatureId = configuration.auth.clientName
      }
    }
    const features = allFeatures.filter(
      (feature: IFeature) => feature.featureId !== ownFeatureId
    )

    yield put(setFeaturesList({ features }))
  } catch (error) {
    yield put(addError(LOAD_TENANT_FEATURES, error))
  } finally {
    yield put(setFeaturesLoadingState({ loadingState: false }))
  }
}

export function* patchApplication(action) {
  try {
    yield put(setFeaturesLoadingState({ loadingState: true }))
    yield call(setFeatureStatus, action.appId, action.tenantId, action.status)
    yield put(loadTenantFeatures())
  } catch (error) {
    yield put(addError(LOAD_TENANT_FEATURES, error))
  } finally {
    yield put(setFeaturesLoadingState({ loadingState: false }))
  }
}
