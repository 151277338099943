/* eslint-disable react/prop-types */
import React from 'react'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { makeStyles, createStyles, Typography } from '@material-ui/core'
import RadioGroup from './../formik/RadioGroup'
import { Field } from 'formik'
import ContextHelpContainer from '../ContextHelp'
import xyzReaderParameterTranslations from './translations/xyzReaderParameterTranslations'

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    titleContainer: {
      display: 'flex'
    },
    title: {
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(1)
    },
    subTitle: {
      fontSize: '0.75rem',
      color: theme.palette.darkGrey && theme.palette.darkGrey.main
    },
    disabledTitle: {
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(1),
      color: theme.palette.darkGrey && theme.palette.darkGrey.main
    },
    group: {
      margin: theme.spacing(1, 0)
    },
    color: {
      color: theme.palette.primary.main,
      '& .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root': {
        color: theme.palette.primary.main
      }
    },
    disabledColor: {
      color: theme.palette.primary.light,
      '& .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root': {
        color: theme.palette.primary.light
      }
    }
  })
})

interface IProps {
  name: string
  disabled?: boolean
}

/* The XYZ Reader expects the 
- x coordinate in the first column ( = column index 0), 
- the y coordinate in the second column  (= column index 1),
- the connectivity column in third or fourth column ( = column index 2 or 3)
- the z column index in third or fourth column ( = column index 2 or 3) 
- or if you do not specify the connectivity index in third column (column index 2) */
const DatasetXYZParameters = (props: IProps) => {
  const classes = useStyles()

  const { name, disabled } = props

  return (
    <>
      <div className={classes.titleContainer}>
        <div>
          <Typography
            variant="body2"
            className={disabled ? classes.disabledTitle : classes.title}
          >
            {xyzReaderParameterTranslations.title}
          </Typography>
          <Typography className={classes.subTitle}>
            {xyzReaderParameterTranslations.subTitle}
          </Typography>
        </div>
        <ContextHelpContainer
          disabled={disabled}
          helpTexts={[xyzReaderParameterTranslations.info]}
        />
      </div>

      <Field
        className={disabled ? classes.disabledColor : classes.color}
        name={name}
        options={[
          { value: 'xycz', label: xyzReaderParameterTranslations.xyczOption },
          { value: 'xyzc', label: xyzReaderParameterTranslations.xyzcOption },
          { value: 'xyc', label: xyzReaderParameterTranslations.xycOption },
          { value: 'xyz', label: xyzReaderParameterTranslations.xyzOption },
          { value: 'xy', label: xyzReaderParameterTranslations.xyOption }
        ]}
        disabled={disabled}
        component={RadioGroup}
      />
    </>
  )
}

export default DatasetXYZParameters
