import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { css } from 'emotion'
import Typography from '@material-ui/core/Typography'
import theme from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import SiteInfoNav from '../SiteInfoNav'
import { useTypedSelector } from '../../reducers'
import { makeStyles } from '@material-ui/core'
import DataTable from '../DataTable'
import { useIntl } from 'react-intl'
import { StateEnum } from '../../model/StateEnum'
import { SiteCpuUsageState } from '../../reducers/siteCpuUsage'
import { useDispatch } from 'react-redux'
import { loadSiteCpuUsage } from '../../actions/siteCpuUsage'
import { DateRangePicker, DateRange } from 'materialui-daterange-picker'
import { MikeButton, MikeTooltip } from '@mike/mike-shared-frontend'
import moment from 'moment'
import LongName from '../DataTable/renders/LongName'
import { loadProjectNames } from '../../actions/projectNames'

const paddingStyle = css`
  padding: ${theme.spacing(1)}px;
`

const useStyles = makeStyles(theme => ({
  tableTopHeader: {
    position: 'sticky',
    top: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    zIndex: 1,
    backgroundColor: '#f2f5f7'
  },
  dateRangePicker: {
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  dateRangePickerOverlay: {
    position: 'absolute',
    zIndex: 101
  }
}))

const hoverStyle = () => css`
  &:hover {
    cursor: pointer;
  }
`

const SiteCpuUsage = props => {
  const intl = useIntl()
  const classes = useStyles(props)
  const tenantId = useTypedSelector(state => state.auth?.user?.tenantId)
  const siteCpuUsage = useTypedSelector<SiteCpuUsageState>(
    state => state.siteCpuUsage as SiteCpuUsageState
  )
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const projectNames = useTypedSelector(state => state.projectNames)

  useEffect(() => {
    if (tenantId && siteCpuUsage.state === StateEnum.INIT) {
      dispatch(loadSiteCpuUsage(tenantId, siteCpuUsage.params))
    }
    if (tenantId && projectNames.state === StateEnum.INIT) {
      dispatch(loadProjectNames(tenantId))
    }
  }, [tenantId, siteCpuUsage, dispatch, projectNames.state])

  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height + 8)
    }
  }, [])

  const projectNameRender = value => {
    if (projectNames.projects[value]) {
      return <LongName longName={projectNames.projects[value]} />
    }
    return (
      <MikeTooltip
        title={intl.formatMessage({
          id: 'screens.siteCpuUsage.unknownProject'
        })}
      >
        <div>{value}</div>
      </MikeTooltip>
    )
  }

  const projectTimeRender = value => {
    return (
      <div>
        {value &&
          Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          }).format(moment(value).toDate())}
      </div>
    )
  }

  const projectMinutesRender = value => {
    return (
      <MikeTooltip title={value}>
        <span>{value.toFixed(2)}</span>
      </MikeTooltip>
    )
  }

  const onHandleRequestSort = (orderBy: string, sortOrder: string) => {
    dispatch(
      loadSiteCpuUsage(tenantId, {
        ...siteCpuUsage.params,
        page: 0,
        orderBy,
        sortOrder
      })
    )
  }

  const onChangePage = page => {
    dispatch(
      loadSiteCpuUsage(tenantId, {
        ...siteCpuUsage.params,
        page
      })
    )
  }

  const onChangeRowsPerPage = rowsPerPage => {
    dispatch(
      loadSiteCpuUsage(tenantId, {
        ...siteCpuUsage.params,
        page: Math.floor(
          (siteCpuUsage.params.page * siteCpuUsage.params.rowsPerPage) /
            rowsPerPage
        ),
        rowsPerPage
      })
    )
  }

  const setDateRange = (dateRange: DateRange) => {
    const start = moment(dateRange.startDate)
      .startOf('day')
      .format()
    const end = moment(dateRange.endDate)
      .endOf('day')
      .format()
    dispatch(
      loadSiteCpuUsage(tenantId, {
        ...siteCpuUsage.params,
        page: 0,
        startDate: start,
        endDate: end
      })
    )
  }

  const startAsDate = useMemo(
    () => moment(siteCpuUsage.params.startDate).toDate(),
    [siteCpuUsage.params.startDate]
  )
  const endAsDate = useMemo(
    () => moment(siteCpuUsage.params.endDate).toDate(),
    [siteCpuUsage.params.endDate]
  )

  return (
    <>
      <SiteInfoNav />
      <div className={classes.tableTopHeader} ref={measuredRef}>
        <Typography className={paddingStyle} variant="h4" color="textPrimary">
          {intl.formatMessage({
            id: 'screens.siteCpuUsage.cloudCpuUsage'
          })}
        </Typography>
      </div>

      <div className={classes.dateRangePicker}>
        <MikeButton onClick={() => setOpen(!open)} variant="outlined">
          {Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }).format(startAsDate)}
          <strong>&nbsp;to&nbsp;</strong>
          {Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }).format(endAsDate)}
        </MikeButton>
        <DateRangePicker
          open={open}
          initialDateRange={{
            startDate: startAsDate,
            endDate: endAsDate
          }}
          toggle={() => setOpen(!open)}
          maxDate={new Date()}
          onChange={range => {
            setDateRange(range)
            setOpen(!open)
          }}
          wrapperClassName={classes.dateRangePickerOverlay}
          closeOnClickOutside={true}
        />
      </div>

      <DataTable
        loading={siteCpuUsage.state === StateEnum.LOADING}
        columns={[
          {
            field: 'projectId',
            label: intl.formatMessage({ id: 'common.project' }),
            render: projectNameRender,
            className: hoverStyle
          },
          {
            field: 'validFrom',
            label: intl.formatMessage({ id: 'screens.siteCpuUsage.startTime' }),
            render: projectTimeRender,
            className: hoverStyle
          },
          {
            field: 'value',
            label: intl.formatMessage({ id: 'common.minutes' }),
            render: projectMinutesRender,
            className: hoverStyle
          }
        ]}
        notSortableColumns={['projectId']}
        idField={item => `${item.operationId}-${item.validFrom}`}
        data={siteCpuUsage.cpuUsage}
        onHandleRequestSort={onHandleRequestSort}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        hasSelection={false}
        topOffset={topOffset}
        totalCount={siteCpuUsage.totalCount}
        hasMorePages={true}
        page={siteCpuUsage.params.page}
        rowsPerPage={siteCpuUsage.params.rowsPerPage}
        _order={[siteCpuUsage.params.sortOrder]}
        _orderBy={[siteCpuUsage.params.orderBy]}
      />
    </>
  )
}

export default SiteCpuUsage
