import { LOADING_APPS, SET_APPS } from '../actions/applications'

const initState = {
  data: [],
  loading: false,
  loadingApps: false,
  apps: {}
}

const applications = (state = initState, action) => {
  switch (action.type) {
    case LOADING_APPS:
      return { ...state, loadingApps: action.loading }
    case SET_APPS:
      return { ...state, apps: action.apps }
    default:
      return state
  }
}

export default applications
