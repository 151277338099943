import { administration } from './support'

const BASE_URL = 'admin'

export const getTenantFeatures = () =>
  administration.getJson(`${BASE_URL}/feature/tenant/list`, 3)

export const setFeatureStatus = (
  appId: string,
  tenantId: string,
  status: boolean
) => {
  return administration.patchJson(
    `${BASE_URL}/feature/${appId}/tenant/${tenantId}`,
    {
      enabled: status
    },
    3,
    {
      'dhi-project-id': tenantId
    }
  )
}
