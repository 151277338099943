import IProject from '../model/IProject'

import {
  LOADING_PROJECTS,
  SET_PROJECTS,
  CREATING_PROJECT,
  UPDATING_PROJECT,
  DELETING_PROJECTS,
  SET_PROJECTS_PAGINATION,
  SET_PROJECTS_ROWSPERPAGE,
  SET_PROJECTS_SEARCHTEXT
} from '../actions/projects'

const initState = {
  data: Array<IProject>(),
  loading: false,
  creating: false,
  deleting: false,
  namePrefix: '',
  sortBy: 'UpdatedAt',
  sortOrder: 'Desc',
  totalCount: 0,
  offset: 0,
  page: 0,
  rowsPerPage: 25,
  my: false
}

const projects = (state = initState, action) => {
  switch (action.type) {
    case SET_PROJECTS_SEARCHTEXT:
      return { ...state, namePrefix: action.text }

    case SET_PROJECTS_PAGINATION:
      return { ...state, page: action.page }

    case SET_PROJECTS_ROWSPERPAGE:
      return { ...state, rowsPerPage: action.rowsPerPage }

    case DELETING_PROJECTS:
      return { ...state, deleting: action.deleting }

    case CREATING_PROJECT:
      return { ...state, creating: action.creating }

    case UPDATING_PROJECT:
      return { ...state, updating: action.updating }

    case LOADING_PROJECTS:
      return { ...state, my: action.my, loading: action.loading }

    case SET_PROJECTS:
      return {
        ...state,
        data: action.projects.newData,
        offset: action.projects.offset,
        rowsPerPage: action.projects.rowsPerPage,
        sortBy: action.projects.sortBy,
        sortOrder: action.projects.sortOrder,
        namePrefix: action.projects.namePrefix,
        totalCount: action.projects.totalCount
      }

    default:
      return state
  }
}

export default projects
