import { parseError } from '../helpers/errors'

export const EXPORT_DATASET = 'EXPORTS/EXPORT_DATASET'
export const exportDataset = (id, fileName, datasetId, projectId, data) => ({
  type: EXPORT_DATASET,
  id,
  fileName,
  datasetId,
  projectId,
  data
})

export const EXPORT_START = 'EXPORTS/EXPORT_START'
export const exportStart = (id, title) => ({
  type: EXPORT_START,
  id,
  title
})

export const UPDATE_EXPORT_PROGRESS = 'EXPORTS/UPDATE_PROGRESS'
export const updateExportProgress = (id, progress, title) => ({
  type: UPDATE_EXPORT_PROGRESS,
  id,
  progress,
  title
})

export const EXPORT_COMPLETE = 'EXPORTS/EXPORT_COMPLETE'
export const exportComplete = id => ({
  type: EXPORT_COMPLETE,
  id
})

export const EXPORT_FAIL = 'EXPORTS/EXPORT_FAIL'
export const exportFail = (id, error) => ({
  type: EXPORT_FAIL,
  id,
  error: parseError(error, { id })
})

export const REMOVE_EXPORT = 'EXPORTS/REMOVE_EXPORT'
export const removeExport = id => ({
  type: REMOVE_EXPORT,
  id
})
