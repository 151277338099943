export type LoadProjectNamesType = {
  type: string
  tenantId: string
}
export const LOAD_PROJECT_NAMES = 'PROJECTNAMES/LOAD_NAMES'
export const loadProjectNames = (tenantId: string) =>
  ({
    type: LOAD_PROJECT_NAMES,
    tenantId
  } as LoadProjectNamesType)
