import uniqid from 'uniqid'
import filter from 'lodash/fp/filter'
import without from 'lodash/fp/without'
import isObject from 'lodash/fp/isObject'

import { isApiError } from '../apis/support'

export const ERROR_CODES = {
  AUTH_FAILED: 'AuthError'
}

export const getAuthErrors = filter({ name: ERROR_CODES.AUTH_FAILED })

export const getNotAuthErrors = without({ name: ERROR_CODES.AUTH_FAILED })

export const parseError = (error, custom = {}) => ({
  id: custom.id || uniqid.time(),
  name: custom.name || (isObject(error) ? error.name : ''),
  message: isObject(error) ? error.message : error,
  isApiError: isApiError(error),
  source: error,
  timestamp: Date.now()
})
