import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  gutterTop: {
    paddingTop: theme.spacing(2)
  },
  gutterBottom: {
    paddingBottom: theme.spacing(2)
  }
})

const ActionsBar = ({ classes, children, align, gutter, ...gridProps }) => (
  <div
    className={
      React.Children.count(children) > 0
        ? gutter === 'top'
          ? classes.gutterTop
          : classes.gutterBottom
        : null
    }
  >
    <Grid
      container
      direction="row"
      spacing={1}
      {...gridProps}
      alignContent="center"
      alignItems="center"
      justifyContent={
        align === 'left'
          ? 'flex-start'
          : align === 'right'
          ? 'flex-end'
          : 'flex-start'
      }
    >
      {React.Children.map(children, c => {
        const stretch = Boolean(c.props['data-stretch'])

        return (
          <Grid style={{ flex: stretch ? 1 : 'none' }} item>
            {c}
          </Grid>
        )
      })}
    </Grid>
  </div>
)
ActionsBar.propTypes = {
  classes: PropTypes.object.isRequired,
  gutter: PropTypes.string.isRequired,
  children: PropTypes.any,
  align: PropTypes.oneOf(['left', 'center', 'right'])
}

export default withStyles(styles)(ActionsBar)
