export const LOAD_ADMIN_CREDITS = 'ADMIN/LOAD_CREDITS'
export const loadAdminCredits = () => ({
  type: LOAD_ADMIN_CREDITS
})

export const LOADING_ADMIN_CREDITS = 'ADMIN/LOADING_CREDITS'
export const loadingAdminCredits = (loadingCredits = true) => ({
  type: LOADING_ADMIN_CREDITS,
  loadingCredits
})

export const SET_ADMIN_CREDITS = 'ADMIN/SET_CREDITS'
export const setAdminCredits = (credits: number) => ({
  type: SET_ADMIN_CREDITS,
  credits
})

export const SET_ADMIN_USERROLE = 'ADMIN/SET_ADMINROLE'
export const setIsAdmin = (isAdmin: boolean) => ({
  type: SET_ADMIN_USERROLE,
  isAdmin
})

export const SET_BACKOFFICE_USERROLE = 'ADMIN/SET_BACKOFFICEROLE'
export const setIsBackOffice = (isBackOffice: boolean) => ({
  type: SET_BACKOFFICE_USERROLE,
  isBackOffice
})

export const SET_ROOT_CONTRIBUTOR_USERROLE = 'ADMIN/SET_ROOTCONTRIBUTORROLE'
export const setIsRootContributor = (isRootContributor: boolean) => ({
  type: SET_ROOT_CONTRIBUTOR_USERROLE,
  isRootContributor
})
