/* eslint-disable no-console */
import { call, put } from 'redux-saga/effects'
import * as qs from 'query-string'
/* import difference from 'lodash/difference'
import map from 'lodash/fp/map'
import isEmpty from 'lodash/isEmpty' */

import getConfig from '../apis/config'

import initApis from '../apis'

import { initAi } from '../apis/appInsights'

/* import { getTransfer, TRANSFER_STATUSES } from '../apis/transfer'

import {
  MONITOR_TRANSFERS_DELAY,
  MONITOR_TRANSFERS_ERROR_DELAY,
  MONITOR_TRANSFERS_TRACKING_DELAY,
  IMPORT_REMOVE_ON_DONE_DELAY
} from '../shared/constants' */

import { ERROR_CODES } from '../helpers/errors'
/* import { IMPORT_TYPE } from '../helpers/import'
import { EXPORT_TYPE } from '../helpers/export'
import { CONVERT_TYPE } from '../helpers/convert' */

/* import {
  updateProgress,
  importFail,
  importStart,
  importComplete,
  removeImport
} from '../actions/imports' */

import { addError } from '../actions/errors'
import { setConfig } from '../actions/ui'
// import { getImports, getUser } from '../state'

// import { loadMessages } from './intl'

// const TAG = '@bootstrap'

export default function* bootstrap() {
  try {
    const config = yield call(getConfig)

    // If url contains a session id we send it to mike-login's config
    // so that the user is logged in silently (SSO)
    const queryString = window.location.search
    if (queryString) {
      const parameters = qs.parse(queryString.split('?')[1]) || {}
      if (parameters.sid) {
        config.sessionId = '' + parameters.sid
      }
    }

    yield put(setConfig(config))

    // const { strict, fallback } = config.intl.languages

    // const lang = strict || (fallback || 'en')

    const appInsightsKey = (config.appInsights || {}).key

    // yield call(loadMessages, lang)
    yield call(initApis, config)
    yield call(initAi, appInsightsKey)
    // yield spawn(monitorTransfers) // Might be re-activated if we have a UI design for monitoring remote transfers
  } catch (error) {
    yield put(addError(ERROR_CODES.AUTH_FAILED, error))
  }
}

/* const MONITOR_TAG = `${TAG}.monitorTransfers`

export function* monitorTransfers() {
  console.log(MONITOR_TAG, 'Stating monitoring transfers...')

  let prevActiveTransfers = []

  while (true) {
    try {
      const user = yield select(getUser)

      if (user) {
         const transfers = yield call(getTransfers, {
          statuses: [TRANSFER_STATUSES.PENDING, TRANSFER_STATUSES.IN_PROGRESS]
        })
        const remoteTransfers = toTransferIds(transfers) 

        const imports = yield select(getImports)
        const localTransfers = toTransferIds(imports)

        const activeTransfers = localTransfers //union(remoteTransfers, localTransfers)

        const newActiveTransfers = difference(
          activeTransfers,
          prevActiveTransfers
        )

        if (!isEmpty(newActiveTransfers)) {
          yield fork(trackNewActiveTransfers, newActiveTransfers)
        }

        prevActiveTransfers = activeTransfers
      }

      yield delay(MONITOR_TRANSFERS_DELAY)
    } catch (error) {
      console.error(MONITOR_TAG, error)
      yield delay(MONITOR_TRANSFERS_ERROR_DELAY)
    }
  }
}

export function* trackNewActiveTransfers(transferIds) {
  for (const id of transferIds) {
    const info = yield call(getTransfer, id)

    console.log(MONITOR_TAG, id, 'Init transfer', { info })

    if (info.type === EXPORT_TYPE) {
      // TODO: monitoring also the export transfers
      return
    } else if (info.type === IMPORT_TYPE) {
      const {
        projectId,
        createdBy,
        datasetImportData: { name },
        importParameters: { fileName }
      } = info

      yield put(
        importStart(id, createdBy, projectId, TRANSFER_STATUSES.IN_PROGRESS, {
          name,
          fileName
        })
      )

      yield fork(trackTransfer, id)
    } else if (info.type === CONVERT_TYPE) {
      const { projectId, createdBy } = info

      let name = id

      if (info.datasetImportData && info.datasetImportData.name) {
        name = info.datasetImportData.name
      }

      yield put(
        importStart(id, createdBy, projectId, TRANSFER_STATUSES.IN_PROGRESS, {
          name,
          fileName: name
        })
      )

      yield fork(trackTransfer, id)
    } else {
      console.warn(MONITOR_TAG, `Unknown transfer type "${info.type}"`)
    }
  }
}

export function* trackTransfer(id) {
  while (true) {
    try {
      const info = yield call(getTransfer, id)
      console.log(MONITOR_TAG, id, 'Tracking transfer', { info })

      if (
        [
          TRANSFER_STATUSES.NONE,
          TRANSFER_STATUSES.PENDING,
          TRANSFER_STATUSES.IN_PROGRESS
        ].includes(info.status)
      ) {
        yield put(updateProgress(id, 0))
      } else {
        if (info.status === TRANSFER_STATUSES.COMPLETED) {
          yield put(importComplete(id))
          yield delay(IMPORT_REMOVE_ON_DONE_DELAY)
          yield put(removeImport(id))
        } else if (info.status === TRANSFER_STATUSES.ERROR) {
          yield put(importFail(id, new Error(info.errorMessage)))
        } else {
          yield put(
            importFail(
              id,
              new Error(`Unknown transfer status "${info.status}"`)
            )
          )
        }

        return
      }

      yield delay(MONITOR_TRANSFERS_TRACKING_DELAY)
    } catch (error) {
      console.error(MONITOR_TAG, error)
      yield delay(MONITOR_TRANSFERS_ERROR_DELAY)
    }
  }
} 

const toTransferIds = map('id') */
