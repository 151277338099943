import { call, put, takeLatest, select } from 'redux-saga/effects'
import { getAllCoordinateSystems } from '../state'
import { getCoordinateSystems } from '../apis/coordinateSystems'

import {
  LOAD_COORDINATE_SYSTEMS,
  setCoordinateSystems,
  loadingCoordinateSystems
} from '../actions/coordinateSystems'

import { addError } from '../actions/errors'

export default function* watchFetchCoordinateSystems() {
  yield takeLatest(LOAD_COORDINATE_SYSTEMS, fetchAllCoordinateSystems)
}

export function* fetchAllCoordinateSystems() {
  const appCoordinateSystems = yield select(getAllCoordinateSystems)

  if (appCoordinateSystems.length === 0) {
    try {
      yield put(loadingCoordinateSystems())

      const response = yield call(getCoordinateSystems)

      if (response) {
        yield put(setCoordinateSystems(response))
      }
    } catch (error) {
      yield put(addError(LOAD_COORDINATE_SYSTEMS, error))
    } finally {
      yield put(loadingCoordinateSystems(false))
    }
  }
}
