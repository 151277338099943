import { parseError } from '../helpers/errors'

export const UPLOAD_FILE = 'UPLOADS/UPLOAD_FILE'
export const uploadFile = (id, fileName, file) => ({
  type: UPLOAD_FILE,
  id,
  fileName,
  file
})

export const UPLOAD_START = 'UPLOADS/UPLOAD_START'
export const uploadStart = id => ({
  type: UPLOAD_START,
  id
})

export const UPDATE_PROGRESS = 'UPLOADS/UPDATE_PROGRESS'
export const updateProgress = (id, progress, fileName) => ({
  type: UPDATE_PROGRESS,
  id,
  progress,
  fileName
})

export const UPLOAD_COMPLETE = 'UPLOADS/UPLOAD_COMPLETE'
export const uploadComplete = (id, fileUrl) => ({
  type: UPLOAD_COMPLETE,
  id,
  fileUrl
})

export const UPLOAD_FAIL = 'UPLOADS/UPLOAD_FAIL'
export const uploadFail = (id, error) => ({
  type: UPLOAD_FAIL,
  id,
  error: parseError(error, { id })
})

export const REMOVE_UPLOAD = 'UPLOADS/REMOVE_UPLOAD'
export const removeUpload = id => ({
  type: REMOVE_UPLOAD,
  id
})
