import { metadata } from './support'

export const TRANSFER_STATUSES = {
  NONE: 'None',
  PENDING: 'Pending',
  IN_PROGRESS: 'InProgress',
  COMPLETED: 'Completed',
  ERROR: 'Error'
}

export const getTransfer = id => {
  return metadata.getJson(`conversion/transfer/${id}`, 2)
}

export const transferUploadConvert = body => {
  return metadata.postJson('conversion/upload-convert', body, 3)
}

export const transferConvert = (datasetId, body) => {
  return metadata.postJson(`metadata/dataset/${datasetId}/convert`, body, 3)
}

export const transferDownloadConvert = (datasetId, body) => {
  return metadata.postJson(
    `metadata/dataset/${datasetId}/download-convert`,
    body,
    3
  )
}
