import produce from 'immer'
import find from 'lodash/fp/find'
import findIndex from 'lodash/fp/findIndex'

import {
  CONVERT_DATASET,
  CONVERT_START,
  UPDATE_CONVERSION_PROGRESS,
  CONVERT_COMPLETE,
  CONVERT_FAIL,
  REMOVE_CONVERT
} from '../actions/converts'

import { EXPORT_STATES } from '../helpers/export'

export default produce((draft, action) => {
  const { type, ...payload } = action

  const convertItem = findConvert(payload.id)(draft)

  switch (type) {
    case CONVERT_DATASET: {
      draft.push({
        ...payload,
        id: payload.datasetId,
        state: EXPORT_STATES.PENDING
      })
      return
    }

    case CONVERT_START: {
      convertItem.state = EXPORT_STATES.IN_PROGRESS
      convertItem.title = payload.title
      convertItem.progress = 0
      return
    }

    case UPDATE_CONVERSION_PROGRESS: {
      convertItem.progress = payload.progress
      return
    }

    case CONVERT_COMPLETE: {
      convertItem.state = EXPORT_STATES.DONE
      return
    }

    case CONVERT_FAIL: {
      convertItem.state = EXPORT_STATES.FAILED
      convertItem.error = payload.error
      return
    }

    case REMOVE_CONVERT: {
      removeConvert(action.id)(draft)
      return
    }

    default:
      return
  }
}, [])

const findConvert = id => find({ id })
const removeConvert = id => draft => draft.splice(findIndex({ id })(draft), 1)
