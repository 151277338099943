const xyzReaderParameterTranslations = {
  title: 'Set column sequence',
  subTitle: 'The data will not be restructured',
  xyczOption: 'X, Y, Connection id, and Z',
  xyzcOption: 'X, Y, Z, and Connection id',
  xycOption: 'X, Y, and Connection id',
  xyzOption: 'X, Y, and Z',
  xyOption: 'X, and Y',
  info:
    'You need to indicate the sequence of the data cloumns in the current file.'
}

export default xyzReaderParameterTranslations
