export const LOAD_COORDINATE_SYSTEMS = 'COORDINATE_SYSTEMS/LOAD'
export const loadCoordinateSystems = () => ({
  type: LOAD_COORDINATE_SYSTEMS
})

export const LOADING_COORDINATE_SYSTEMS = 'COORDINATE_SYSTEMS/LOADING'
export const loadingCoordinateSystems = (loading = true) => ({
  type: LOADING_COORDINATE_SYSTEMS,
  loading
})

export const SET_COORDINATE_SYSTEMS = 'COORDINATE_SYSTEMS/SET'
export const setCoordinateSystems = coordinateSystems => ({
  type: SET_COORDINATE_SYSTEMS,
  coordinateSystems
})

export const GET_COORDINATE_SYSTEMS_BY_ID = 'COORDINATE_SYSTEMS/GET_BY_ID'
export const getCoordinateSystemsById = id => ({
  type: GET_COORDINATE_SYSTEMS_BY_ID,
  id
})

export const FILTER_COORDINATE_SYSTEMS = 'COORDINATE_SYSTEMS/FILTER'
export const filterCoordinateSystems = filter => ({
  type: FILTER_COORDINATE_SYSTEMS,
  filter
})

export const SET_SELECTED_COORDINATE_SYSTEMS = 'COORDINATE_SYSTEMS/SELECTED'
export const setSelectedCoordinateSystems = coordinateSystems => ({
  type: SET_SELECTED_COORDINATE_SYSTEMS,
  coordinateSystems
})
