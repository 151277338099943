/* eslint-disable react/prop-types */
import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const FormikRadioGroup = ({
  disabled,
  field,
  form: { setFieldValue },
  options,
  ...props
}) => {
  const [value, setValue] = React.useState('')

  const handleChange = event => {
    setValue(event.target.value)
    setFieldValue(field.name, event.target.value)
  }

  return (
    <React.Fragment>
      <RadioGroup
        {...field}
        {...props}
        value={value}
        name={field.name}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            disabled={disabled}
            control={<Radio color="primary" />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </React.Fragment>
  )
}

export default FormikRadioGroup
