import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import { ReactComponent as ProjectMoveTo } from '@mike/mike-shared-frontend/media/icons/ProjectMoveTo'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'

import { iconSecondaryStyle, useStyles } from './iconStyles'

const MoveToButton = (buttonProps: MuiButtonProps) => {
  const classes = useStyles()
  return (
    <Button className={classes.button} {...buttonProps}>
      <ProjectMoveTo className={iconSecondaryStyle(buttonProps.disabled)} />
      <FormattedMessage id={'projectContent.moveToButton'} />
    </Button>
  )
}

export default MoveToButton
