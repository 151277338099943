import { defineMessages } from 'react-intl'
import toLower from 'lodash/toLower'

import { ACCESS_LEVELS } from '../helpers/project'

export const commonMessages = defineMessages({
  accessLevel: { id: 'common.accessLevel' },
  allProjects: { id: 'common.allProjects' },
  cancel: { id: 'common.cancel' },
  confidential: { id: 'common.confidential' },
  confidentialShort: { id: 'common.confidentialShort' },
  createdAt: { id: 'common.createdAt' },
  dataset: { id: 'common.dataset' },
  delete: { id: 'common.delete' },
  deletedAt: { id: 'common.deletedAt' },
  description: { id: 'common.description' },
  download: { id: 'common.download' },
  edit: { id: 'common.edit' },
  editedAt: { id: 'common.editedAt' },
  folder: { id: 'common.folder' },
  format: { id: 'common.format' },
  import: { id: 'common.import' },
  lastActivityAt: { id: 'common.lastActivityAt' },
  locationType: { id: 'common.locationType' },
  members: { id: 'common.members' },
  myProjects: { id: 'common.myProjects' },
  name: { id: 'common.name' },
  okay: { id: 'common.okay' },
  owner: { id: 'common.owner' },
  private: { id: 'common.private' },
  privateShort: { id: 'common.privateShort' },
  project: { id: 'common.project' },
  projects: { id: 'common.projects' },
  recycleBin: { id: 'common.recycleBin' },
  refresh: { id: 'common.refresh' },
  restore: { id: 'common.restore' },
  shared: { id: 'common.shared' },
  sharedShort: { id: 'common.sharedShort' },
  siteData: { id: 'common.siteData' },
  size: { id: 'common.size' },
  tags: { id: 'common.tags' },
  type: { id: 'common.type' },
  updatedAt: { id: 'common.updatedAt' },
  upload: { id: 'common.upload' },
  yes: { id: 'common.yes' },
  yesDelete: { id: 'common.yesDelete' },
  data: { id: 'common.data' },
  email: { id: 'common.email' },
  role: { id: 'common.role' },
  userRole: { id: 'common.user-role' },
  tenantAccess: { id: 'common.tenantAccess' },
  tenantInfo: { id: 'common.tenantInfo' },
  tenantUsers: { id: 'common.tenantUsers' },
  tenantApiAccess: { id: 'common.tenantApiAccess' },
  noAccess: { id: 'common.noAccess' },
  tenantGroups: { id: 'common.tenantGroups' },
  tenantApplications: { id: 'common.tenantApplications' }
})

const messages = {
  ...commonMessages
  // TODO: put here other messages from different context
}

export const getProjectAccessLevelMessage = accessLevel =>
  commonMessages[toLower(accessLevel)]

export const getProjectAccessLevelShortMessage = accessLevel =>
  commonMessages[toLower(accessLevel) + 'Short']

export const localizeProjectAccessLevels = intl =>
  Object.values(ACCESS_LEVELS).map(value => ({
    value,
    name: intl.formatMessage(getProjectAccessLevelMessage(value))
  }))

export default messages
