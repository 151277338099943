import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import Typography from '@material-ui/core/Typography'
import theme from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import SiteInfoNav from '../../SiteInfoNav'
import { makeStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import ContextHelpContainer from '../../ContextHelp'
import { Link } from 'react-router-dom'
import { ArrowForward } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import CreditsNav from './CreditsNav'
import { CreditsStateEnum } from '../../../model/CreditsStateEnum'
import { useTypedSelector } from '../../../reducers'
import {
  callCreditTenantGet,
  callCreditUnassignedPost
} from '../../../actions/credits'
import { IBillingInformation, getProject } from '../../../apis/admin'

const useStyles = makeStyles(theme => ({
  tableTopHeader: {
    display: 'flex',
    flexDirectoin: 'row',
    position: 'sticky',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    zIndex: 1,
    backgroundColor: 'white',
    border: '3px black',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  tableTopButtons: {
    display: 'flex',
    flexDirectoin: 'row',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    alignItems: 'center'
  },
  secondColWrap: {
    display: 'flex',
    flexDirection: 'row',
    width: '58%',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    alignItems: 'center'
  },
  root: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    fontSize: 14,
    '&>span:hover': {
      textDecoration: 'underline'
    },
    '&>svg': {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    }
  },

  dataFrame: {
    display: 'flex',
    flexDirectoin: 'row',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    alignContent: 'center',
    backgroundColor: 'white',
    cursor: 'pointer'
  },
  sectionFrame: {
    marginTop: 20
  },
  paddedDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  specialTypo: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  firstColWrap: {
    display: 'flex',
    width: '42%',
    flexDirection: 'row'
  },
  iconButton: {
    marginRight: theme.spacing(1),
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center'
  },
  navSmallerPadding: {
    paddingBottom: theme.spacing(1)
  },
  dialogTitle: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(2)
  },
  dialogContent: {
    maxWidth: 378,
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  }
}))

const CreditsSummary = props => {
  const intl = useIntl()
  const history = useHistory()
  const classes = useStyles(props)

  const [availableValue, setAvailableValue] = useState<string>('--')
  const [reservedValue, setReservedValue] = useState<string>('--')
  const [spentValue, setSpentValue] = useState<string>('--')
  const [allocationValue, setAllocationValue] = useState<string>('--')
  const [billingInformation, setBillingInformation] = useState<
    IBillingInformation
  >()

  const dispatch = useDispatch()
  const tenantId =
    useTypedSelector(state => state['auth']?.user?.tenantId) || ''

  const credits = useTypedSelector(state => state['credits']?.creditTenant)

  const creditsUnassigned =
    useTypedSelector(state => state['credits']?.creditUnassigned) ?? 0

  const availableCredits =
    useTypedSelector(state => state['credits']?.creditAvailable) ?? 0

  const paddingStyle = css`
    padding: ${theme.spacing(2)}px;
  `

  const addspace = (num, separator) => {
    var mainString = num.toString()
    mainString = mainString
      .split('')
      .reverse()
      .join('')

    mainString = mainString.match(/.{1,3}/g).join(separator)
    mainString = mainString
      .split('')
      .reverse()
      .join('')

    return mainString
  }

  const evalCreditsValues = status => {
    const temp = credits.filter(item => item.status === status)[0]?.credits
    if (temp) {
      return addspace(Math.round(temp), '  ')
    } else {
      return '--'
    }
  }

  function onCreditsChange() {
    dispatch(callCreditTenantGet(tenantId))
  }

  useEffect(() => {
    if (tenantId) {
      getProject(tenantId).then(res => {
        setBillingInformation(res?.billingInformation)
      })
      dispatch(callCreditTenantGet(tenantId))
    }
  }, [dispatch, tenantId])

  useEffect(() => {
    if (credits.length > 0) {
      setAvailableValue(evalCreditsValues('Available'))
      setReservedValue(evalCreditsValues('Reserved'))
      setSpentValue(evalCreditsValues('Spent'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credits])

  useEffect(() => {
    if (
      creditsUnassigned &&
      creditsUnassigned.data &&
      creditsUnassigned.data.length > 0
    ) {
      var test =
        creditsUnassigned.data.filter(item => item.status === 'Available')[0]
          .credits ?? 0
      setAllocationValue(
        test
          ? parseFloat(test)
              .toFixed(0)
              .toString()
          : '--'
      )
    } else {
      setAllocationValue('--')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditsUnassigned])

  useEffect(() => {
    if (availableCredits) setAvailableValue(availableCredits.toFixed(0))
  }, [availableCredits])

  useEffect(() => {
    if (!billingInformation) return

    if (billingInformation.billingReferenceTag) {
      dispatch(callCreditUnassignedPost(billingInformation))
    } else {
      dispatch(
        callCreditUnassignedPost({
          billingReferenceTag: '',
          billingReference: billingInformation.billingReference,
          billingReferenceType: billingInformation.billingReferenceType ?? ''
        })
      )
    }
  }, [billingInformation])

  return (
    <>
      <SiteInfoNav />

      <CreditsNav
        displayState={CreditsStateEnum.SUMMARY}
        onCreditsChange={onCreditsChange}
      />

      <div className={classes.dataFrame}>
        <div className={classes.firstColWrap}>
          <Typography className={paddingStyle} variant="h4" color="textPrimary">
            {intl.formatMessage({
              id: 'credits.creditsSummary.siteCredits'
            })}
          </Typography>
        </div>
        <Typography className={paddingStyle} variant="h4" color="textPrimary">
          {intl.formatMessage({
            id: 'credits.creditsSummary.amount'
          })}
        </Typography>
      </div>
      <div
        className={classes.dataFrame}
        onClick={() => {
          history.push(`/site-info/credits-available`)
        }}
      >
        <div className={classes.firstColWrap}>
          <Typography
            className={paddingStyle}
            variant="body1"
            color="textPrimary"
          >
            {intl.formatMessage({
              id: 'credits.creditsSummary.available'
            })}
          </Typography>
          <ContextHelpContainer
            helpTexts={[
              intl.formatMessage({
                id: 'credits.creditsSummary.availableHelp'
              })
            ]}
          />
        </div>
        <div className={classes.secondColWrap}>
          <Typography
            className={paddingStyle}
            variant="body1"
            color="textPrimary"
          >
            {availableValue}
          </Typography>
          <Link
            className={classes.root}
            to={'/site-info/credits-available'}
            onClick={event => event.stopPropagation()}
          >
            <span>
              {' '}
              {intl.formatMessage({
                id: 'credits.creditsSummary.showMore'
              })}
            </span>
            <ArrowForward />
          </Link>
        </div>
      </div>
      <div
        className={classes.dataFrame}
        onClick={() => {
          history.push(`/site-info/credits-reserved`)
        }}
      >
        <div className={classes.firstColWrap}>
          <Typography
            className={paddingStyle}
            variant="body1"
            color="textPrimary"
          >
            {intl.formatMessage({
              id: 'credits.creditsSummary.reserved'
            })}
          </Typography>
          <ContextHelpContainer
            helpTexts={[
              intl.formatMessage({
                id: 'credits.creditsSummary.reservedHelp'
              })
            ]}
          />
        </div>
        <div className={classes.secondColWrap}>
          <Typography
            className={paddingStyle}
            variant="body1"
            color="textPrimary"
          >
            {reservedValue}
          </Typography>
          <Link
            className={classes.root}
            to={'/site-info/credits-reserved'}
            onClick={event => event.stopPropagation()}
          >
            <span>
              {' '}
              {intl.formatMessage({
                id: 'credits.creditsSummary.showMore'
              })}
            </span>
            <ArrowForward />
          </Link>
        </div>
      </div>
      <div
        className={classes.dataFrame}
        onClick={() => {
          history.push(`/site-info/credits-spent`)
        }}
      >
        <div className={classes.firstColWrap}>
          <Typography
            className={paddingStyle}
            variant="body1"
            color="textPrimary"
          >
            {intl.formatMessage({
              id: 'credits.creditsSummary.spent'
            })}
          </Typography>
          <ContextHelpContainer
            helpTexts={[
              intl.formatMessage({
                id: 'credits.creditsSummary.spentHelp'
              })
            ]}
          />
        </div>
        <div className={classes.secondColWrap}>
          <Typography
            className={paddingStyle}
            variant="body1"
            color="textPrimary"
          >
            {spentValue}
          </Typography>
          <Link
            className={classes.root}
            to={'/site-info/credits-spent'}
            onClick={event => event.stopPropagation()}
          >
            <span>
              {' '}
              {intl.formatMessage({
                id: 'credits.creditsSummary.showMore'
              })}
            </span>
            <ArrowForward />
          </Link>
        </div>
      </div>

      <div className={`${classes.dataFrame} ${classes.sectionFrame}`}>
        <div className={classes.firstColWrap}>
          <Typography className={paddingStyle} variant="h4" color="textPrimary">
            {intl.formatMessage({
              id: 'credits.creditsSummary.companyCredits'
            })}
          </Typography>
        </div>
        <Typography className={paddingStyle} variant="h4" color="textPrimary">
          {intl.formatMessage({
            id: 'credits.creditsSummary.amount'
          })}
        </Typography>
      </div>

      <div
        className={classes.dataFrame}
        onClick={() => {
          history.push(`/site-info/credits-allocation`)
        }}
      >
        <div className={classes.firstColWrap}>
          <Typography
            variant="body1"
            className={paddingStyle}
            color="textPrimary"
          >
            {intl.formatMessage({
              id: 'credits.creditsSummary.allocationToSites'
            })}
          </Typography>

          <ContextHelpContainer
            helpTexts={[
              intl.formatMessage({
                id: 'credits.creditsSummary.allocationHelp'
              })
            ]}
          />
        </div>

        <div className={classes.secondColWrap}>
          <Typography
            className={paddingStyle}
            variant="body1"
            color="textPrimary"
          >
            {allocationValue}
          </Typography>
          <Link
            className={classes.root}
            to={'/site-info/credits-allocation'}
            onClick={event => event.stopPropagation()}
          >
            <span>
              {' '}
              {intl.formatMessage({
                id: 'credits.creditsSummary.showMore'
              })}
            </span>
            <ArrowForward />
          </Link>
        </div>
      </div>
    </>
  )
}

export default CreditsSummary
