import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import { iconStyle, useStyles } from './iconStyles'

const CreateTenantButton = (buttonProps: MuiButtonProps) => {
  const classes = useStyles()
  return (
    <Button className={classes.buttonSecondaryBorder} {...buttonProps}>
      <AddOutlinedIcon className={iconStyle} />
      <FormattedMessage id={'createTenantForm.createTenantButton'} />
    </Button>
  )
}

export default CreateTenantButton
