import React from 'react'
import objectHash from 'object-hash'
import { Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import TextField from '../formik/TextField'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import messages from '../../shared/messages'
import { MikeButton } from '@mike/mike-shared-frontend'
import { css } from 'emotion'
import merge from 'lodash/merge'
import IAPIKey from '../../model/IAPIKey'
import moment from 'moment'

const submitButtonStyle = css`
  float: right;
`

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required')
})

interface IProps {
  initialValues: IAPIKey
  handleSubmit: (values: IAPIKey) => void
}

const APIKeyForm = (props: IProps) => {
  const intl = useIntl()
  const { handleSubmit, initialValues } = props
  const editing = initialValues.accountId !== undefined

  const defaultInitialValues: IAPIKey = {
    name: '',
    expirationDate: moment()
      .add(1, 'year')
      .toJSON()
  }

  const mergedInitialValues = editing
    ? {
        ...merge({}, defaultInitialValues, initialValues)
      }
    : {
        ...defaultInitialValues
      }

  const convertToRepresentation = (dateString: string | undefined) => {
    const date = moment.utc(dateString).local()
    return moment(date).format('YYYY-MM-DDTHH:mm')
  }

  const convertToModel = (dateString: string) => {
    const date = moment(dateString).toDate()
    return moment(date)
      .utc()
      .format('YYYY-MM-DDTHH:mm')
  }

  return (
    <Formik
      key={objectHash(mergedInitialValues)}
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => {
        const { handleChange, setFieldTouched, setFieldValue } = formik
        const change = (name, e) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        const changeDate = (name, e) => {
          if (!editing) {
            e.persist()
            setFieldValue(
              'expirationDate',
              convertToModel(e.target.value),
              true
            )
            setFieldTouched(name, true, false)
          }
        }

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label={intl.formatMessage(messages.name)}
                  fullWidth
                  required
                  autoFocus
                  onChange={change.bind(null, 'name')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="expirationDate"
                  label={intl.formatMessage({
                    id: 'screens.apiAccess.expirationDate'
                  })}
                  fullWidth
                  type="datetime-local"
                  disabled={editing}
                  value={convertToRepresentation(formik.values.expirationDate)}
                  onChange={changeDate.bind(null, 'name')}
                />
              </Grid>
              {editing && (
                <Grid item xs={12}>
                  <TextField
                    name="key"
                    label={intl.formatMessage({
                      id: 'screens.apiAccess.apiKey'
                    })}
                    fullWidth
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <MikeButton
                  className={submitButtonStyle}
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  {editing
                    ? intl.formatMessage({
                        id: 'apiKeyForm.updateAPIKeyButton'
                      })
                    : intl.formatMessage({
                        id: 'apiKeyForm.createAPIKeyButton'
                      })}
                </MikeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default APIKeyForm
