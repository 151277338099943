import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

const BASE_COLOR = '#00A4EC'

const BuyCreditsIcon = ({ colorR = BASE_COLOR, ...props }) => (
  <SvgIcon {...props} viewBox="-1 -3 24 24">
    <path
      fill={colorR ? colorR : BASE_COLOR}
      d="M12.0159 2.87956C11.2305 1.78873 9.93032 0.963867 8.0044 0.963867H0.504395V2.96387L8.0044 2.96387C9.27848 2.96387 9.97826 3.47233 10.3929 4.04817C10.8442 4.675 11.0044 5.45722 11.0044 5.96387V6.96387H8.0044L12.0044 11.9639L16.0044 6.96387H13.0044V5.96387C13.0044 5.13718 12.7646 3.9194 12.0159 2.87956ZM19 6.99976L19 16.9998L5 16.9998L5 6.99975H3L3 16.9998C3 18.0997 3.9 18.9998 5 18.9998L19 18.9998C20.1 18.9998 21 18.0997 21 16.9998L21 6.99976H19Z"
    />
  </SvgIcon>
)
BuyCreditsIcon.propTypes = {
  colorR: PropTypes.string
}
export default BuyCreditsIcon
