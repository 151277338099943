import { all, put, takeEvery, select } from 'redux-saga/effects'

import {
  EXPORT_AND_DOWNLOAD_DATASET,
  EXPORT_AND_DOWNLOAD_MANY_DATASETS
} from '../actions/exportAndDownload'
import { exportDataset } from '../actions/exports'
import { addError } from '../actions/errors'
import { openPopupReminder } from '../actions/ui'
import { getSurpressPopupReminder } from '../state'
import uniqid from 'uniqid'
const LOG_TAG = 'saga.exportAndDownload'

export default function* watchExportAndDownload() {
  yield takeEvery(EXPORT_AND_DOWNLOAD_DATASET, exportAndDownloadDataset)
  yield takeEvery(
    EXPORT_AND_DOWNLOAD_MANY_DATASETS,
    exportAndDownloadManyDatasets
  )
}

export function* exportAndDownloadDataset(action) {
  // eslint-disable-next-line no-console
  console.log(LOG_TAG, action.id, 'New export and download...', { action })
  const surpressPopupReminder = yield select(getSurpressPopupReminder)
  yield put(openPopupReminder(!surpressPopupReminder))
  const { id, fileName, datasetId, projectId, data } = action

  try {
    yield put(exportDataset(id, fileName, datasetId, projectId, data))

    /* while (true) {
      const exportAction = yield take([EXPORT_COMPLETE, EXPORT_FAIL])

      if (exportAction.id === id) {
        if (exportAction.type === EXPORT_COMPLETE) {
          yield put(downloadFile(id, exportAction.fileUrl))
        }

        break
      }
    } */
  } catch (error) {
    yield put(addError(EXPORT_AND_DOWNLOAD_DATASET, error))
  }
}

export function* exportAndDownloadManyDatasets(action) {
  const { datasets, data } = action
  // eslint-disable-next-line no-console
  console.log(LOG_TAG, action.id, 'New batch export and download...', {
    action
  })
  const surpressPopupReminder = yield select(getSurpressPopupReminder)
  yield put(openPopupReminder(!surpressPopupReminder))

  try {
    yield all(
      datasets.map(dataset =>
        put(
          exportDataset(
            uniqid.time(),
            dataset.name,
            dataset.id,
            dataset.projectId,
            {
              importData: {
                name: dataset.name,
                reader: data.reader,
                writer: data.writer
              }
            }
          )
        )
      )
    )
  } catch (error) {
    yield put(addError(EXPORT_AND_DOWNLOAD_MANY_DATASETS, error))
  }
}
