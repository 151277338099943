import React from 'react'

export const useWhenDone = (doneCondition, onDoneCallback) => {
  const readyRef = React.useRef(doneCondition)

  React.useEffect(() => {
    if (readyRef.current && !doneCondition) {
      onDoneCallback()
    }
    readyRef.current = doneCondition
  }, [doneCondition, onDoneCallback])
}

export const useMounted = () => {
  const mounted = React.useRef(false)

  React.useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return mounted
}
