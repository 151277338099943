/* eslint-disable react/prop-types */
import React from 'react'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'

import {
  makeStyles,
  createStyles,
  Typography,
  IconButton
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    titleBar: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    onlyIconBar: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    titleHeadings: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    closeButton: {
      padding: 0,
      color: theme.palette.primary.main
    }
  })
})

interface IProps {
  title?: string
  onClose: () => void
}

const DialogTitle = (props: IProps) => {
  const classes = useStyles()
  const { title, onClose } = props

  return (
    <div className={title ? classes.titleBar : classes.onlyIconBar}>
      {title ? (
        <div className={classes.titleHeadings}>
          <Typography variant="h3">{title}</Typography>
        </div>
      ) : null}
      <div>
        <IconButton
          aria-label="Close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  )
}

export default DialogTitle
