const defaultReader = {
  dfs0: 'Dfs0Reader',
  dfs1: 'Dfs1Reader',
  dfs2: 'Dfs2Reader',
  dfs3: 'Dfs3Reader',
  dfsu: 'DfsuReader',
  xyz: 'XyzReader',
  asc: 'AsciiReader',
  tif: 'GeoTiffReader',
  tiff: 'GeoTiffReader',
  geotiff: 'GeoTiffReader',
  geojson: 'GeoJsonReader',
  csv: 'CSVReader'
}

export default defaultReader
