import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      fontSize: 14,
      '&>span:hover': {
        textDecoration: 'underline'
      },
      '&>svg': {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1)
      }
    }
  })
)

interface IProps {
  to: string
  text: string
}

const ProjectLink = (props: IProps) => {
  const { to, text } = props
  const classes = useStyles()
  return (
    <Link
      className={classes.root}
      to={to}
      onClick={event => event.stopPropagation()}
    >
      {text}
    </Link>
  )
}

export default ProjectLink
