import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useTypedSelector } from '../../reducers'

import {
  loadTenantFeatures,
  updateTenantApplication
} from '../../actions/features'
import { useDispatch } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import SearchInput from '../SearchInput'
import { defineMessages, useIntl } from 'react-intl'
import RefreshButton from '../buttons/RefreshButton'
import DataTable from '../DataTable'
import { StateEnum } from '../../model/StateEnum'
import LongName from '../DataTable/renders/LongName'
import messages from '../../shared/messages'
import { css } from 'emotion'
import IFeature from '../../model/IFeature'
import { appIconName } from '../MikeCloudApps/configuration'
import MikeAppIcon, {
  AppIconName
} from '@mike/mike-shared-frontend/mike-icon/MikeAppIcon'
import Checkbox from '@material-ui/core/Checkbox'
import UsersNav from '../UsersNav'

const localMessages = defineMessages({
  title: {
    id: 'screens.applications.title'
  },
  searchPlaceholder: {
    id: 'screens.applications.input.search.placeholder'
  },
  active: {
    id: 'screens.applications.active'
  }
})

const hoverStyle = () => {
  return css`
    &:hover {
      cursor: pointer;
    }
  `
}

const useStyles = makeStyles(theme => ({
  tableTopHeader: {
    position: 'sticky',
    top: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    zIndex: 1,
    backgroundColor: '#f2f5f7'
  },
  tableTopActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  leftActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  rightActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  nameRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  },
  groupRow: {
    paddingLeft: '20px'
  },
  dialogTitle: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  dialogContent: {
    maxWidth: 378,
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  }
}))

const FIRST_PAGE = 0

const APPLICATION_TYPE = 'Application'

export const APP_TO_FILTER = ['Flood Screener', 'Global Seas']

const Applications = props => {
  const features = useTypedSelector(state => state.features.features)
  const tenantId = useTypedSelector(state => state.auth?.user?.tenantId) || ''

  const apps = features.filter(feat => {
    return feat.type === APPLICATION_TYPE && !APP_TO_FILTER.includes(feat.name)
  })

  const loadingFeatures =
    useTypedSelector(state => state.features.loadingState) === StateEnum.LOADING

  const dispatch = useDispatch()
  const intl = useIntl()
  const [searchText, setSearchText] = useState<string>('')
  const classes = useStyles(props)
  const [sortOrder, setSortOrder] = useState<string[]>(['asc'])
  const [sortOrderBy, setSortOrderBy] = useState<string[]>(['name'])

  const displayApps = useMemo(() => {
    return apps.filter(app => {
      return app.name.toLowerCase().includes(searchText.toLowerCase())
    })
  }, [apps, searchText])

  useEffect(() => {
    if (!features) {
      dispatch(loadTenantFeatures())
    }
  }, [dispatch, features])

  const applicationNameRender = (value, item: IFeature) => {
    const iconName: AppIconName =
      appIconName(item.name) !== undefined
        ? appIconName(item.name)
        : 'genericApp'
    if (item && item.name) {
      return (
        <div className={classes.nameRow}>
          {iconName && <MikeAppIcon name={iconName} />}
          <LongName longName={value} />
        </div>
      )
    }
  }

  const handleEnabledClick = (item, value) => {
    dispatch(updateTenantApplication(item.featureId, tenantId, !value))
  }

  const renderEnabledApp = (value, item: IFeature) => {
    return (
      <Checkbox
        checked={item.enabled}
        onClick={() => {
          handleEnabledClick(item, value)
        }}
      />
    )
  }

  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height + 8)
    }
  }, [])

  const onRefresh = () => {
    dispatch(loadTenantFeatures())
  }

  const onHandleRequestSort = (orderBy: string, order: string) => {
    setSortOrder([order])
    setSortOrderBy([orderBy])
  }

  return (
    <>
      <UsersNav />
      <div className={classes.tableTopHeader} ref={measuredRef}>
        <Typography variant={'h4'}>
          {intl.formatMessage(localMessages.title)}
        </Typography>
        <div className={classes.tableTopActionButtons}>
          <div className={classes.leftActionButtons}>
            <SearchInput
              id="tenant-applications-search-input"
              placeholder={intl.formatMessage(localMessages.searchPlaceholder)}
              autoFocus
              text={searchText}
              onTextChange={setSearchText}
            />
            <RefreshButton onClick={onRefresh} />
          </div>
        </div>
      </div>

      <DataTable
        loading={loadingFeatures}
        hasSelection={false}
        columns={[
          {
            field: 'name',
            label: intl.formatMessage(messages.name),
            render: applicationNameRender,
            className: hoverStyle
          },
          {
            field: 'enabled',
            label: intl.formatMessage(localMessages.active),
            render: renderEnabledApp,
            className: hoverStyle
          }
        ]}
        data={displayApps}
        idField={'featureId'}
        onHandleRequestSort={onHandleRequestSort}
        topOffset={topOffset}
        totalCount={displayApps.length}
        page={FIRST_PAGE}
        rowsPerPage={displayApps.length}
        _order={sortOrder}
        selectedRows={[]}
        _orderBy={sortOrderBy}
        onColumnClick={() => {}}
      />
    </>
  )
}

export default Applications
