import { makeStyles, createStyles } from '@material-ui/core'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'

export const useConversionStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    plusButton: {
      marginLeft: theme.spacing(-2.5)
    },
    plusButtonDisabled: {
      color: theme.palette.secondary.light
    },
    textField: {
      width: 200
    },
    marginTopArrow: {
      marginTop: theme.spacing(1.5)
    },
    marginTop: {
      marginTop: theme.spacing(2)
    },
    marginBottom: {
      marginBottom: theme.spacing(2)
    }
  })
})
