import React, { cloneElement, ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'

import { ReactComponent as NewProject } from '@mike/mike-shared-frontend/media/icons/NewProject'
import { iconStyle, useStyles } from './iconStyles'

type IconButtonType = {
  icon?: ReactElement
  messageId: string
}

const IconButton = (props: MuiButtonProps & IconButtonType) => {
  const { icon, messageId, ...buttonProps } = props
  const classes = useStyles()
  const iconComponent = icon || <NewProject />
  const iconElement = cloneElement(iconComponent, {
    className: iconStyle
  })
  return (
    <Button className={classes.buttonSecondaryBorder} {...buttonProps}>
      {iconElement}
      <FormattedMessage id={messageId} />
    </Button>
  )
}

export default IconButton
