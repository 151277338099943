export const LOAD_CUSTOMER_USERS = 'CUSTOMER_USERS/LOAD'
export const loadCustomerUsers = searchText => ({
  type: LOAD_CUSTOMER_USERS,
  searchText
})

export const LOADING_CUSTOMER_USERS = 'CUSTOMER_USERS/LOADING'
export const loadingCustomerUsers = (loading = true) => ({
  type: LOADING_CUSTOMER_USERS,
  loading
})

export const SET_CUSTOMER_USERS = 'CUSTOMER_USERS/SET'
export const setCustomerUsers = customerUsers => ({
  type: SET_CUSTOMER_USERS,
  customerUsers
})
