export const RESTORE_RECYCLE_BIN_ITEMS_DELETED_BY_ME =
  'RECYCLE_BIN/RESTORE_ITEMS_DELETED_BY_ME'
export const restoreRecycleBinItemsDeletedByMe = (tenantId, ids) => ({
  type: RESTORE_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
  tenantId,
  ids
})

export const RESTORING_RECYCLE_BIN_ITEMS_DELETED_BY_ME =
  'RECYCLE_BIN/RESTORING_ITEMS_DELETED_BY_ME'
export const restoringRecycleBinItemsDeletedByMe = (restoring = true) => ({
  type: RESTORING_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
  restoring
})

export const LOAD_RECYCLE_BIN_ITEMS_DELETED_BY_ME =
  'RECYCLE_BIN/LOAD_ITEMS_DELETED_BY_ME'
export const loadRecycleBinItemsDeletedByMe = (
  tenantId,
  offset = 0,
  limit = 10,
  sortBy = 'Name',
  sortOrder = 'Asc'
) => ({
  type: LOAD_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
  tenantId,
  offset,
  limit,
  sortBy,
  sortOrder
})

export const SET_RECYCLE_BIN_ITEMS_DELETED_BY_ME =
  'RECYCLE_BIN/SET_ITEMS_DELETED_BY_ME'
export const setRecycleBinItemsDeletedByMe = itemsDeletedByMe => ({
  type: SET_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
  itemsDeletedByMe
})

export const LOADING_RECYCLE_BIN_ITEMS_DELETED_BY_ME =
  'RECYCLE_BIN/LOADING_ITEMS_DELETED_BY_ME'
export const loadingRecycleBinItemsDeletedByMe = (loading = true) => ({
  type: LOADING_RECYCLE_BIN_ITEMS_DELETED_BY_ME,
  loading
})
