/* eslint-disable react/prop-types */
import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles } from '@material-ui/core'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { PaletteColor } from '@material-ui/core/styles/createPalette'

interface IProps {
  count?: number
  width?: number
  height?: number
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  const lightGrey = theme.palette.lightGrey as PaletteColor // TS fix: possibly undefined
  const mediumGrey = theme.palette.mediumGrey as PaletteColor // TS fix: possibly undefined
  return createStyles({
    container: {
      padding: theme.spacing(6),
      backgroundColor: lightGrey.main,
      border: '1px solid ' + mediumGrey.main
    },
    item: {
      paddingRight: theme.spacing(6)
    }
  })
})

const AppsSkeleton = (props: IProps) => {
  const { count = 3, width = 80, height = 80 } = props
  const classes = useStyles()
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-end"
      justifyContent="flex-start"
      className={classes.container}
    >
      {Array.from(new Array(count)).map((item, key) => (
        <Grid item key={key} className={classes.item}>
          <Skeleton variant="rect" width={width} height={height} />
          <Skeleton variant="text" />
        </Grid>
      ))}
    </Grid>
  )
}

export default AppsSkeleton
