import { administration } from './support'

const BASE_IAM_URL = 'iam'

const filterCustomerUsers = (
  searchText: string,
  users: Array<{ name: string; email: string }>
) => {
  if (!searchText) return users

  return users.filter(
    ({ name, email }: { name: string; email: string }) =>
      name?.toLowerCase().includes(searchText.toLowerCase()) ||
      email?.toLowerCase().includes(searchText.toLowerCase())
  )
}

export const getCustomerUsers = async (projectId, searchText) => {
  try {
    const { data } = await administration.getJson(
      `${BASE_IAM_URL}/project/${projectId}/principal/list`,
      3
    )

    return filterCustomerUsers(searchText, data)
  } catch (error) {
    return []
  }
}
