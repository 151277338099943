import { css } from 'emotion'
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { makeStyles, createStyles } from '@material-ui/core'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'

export const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    button: {
      color: theme.palette.secondary.main,
      '&:disabled': {
        color: theme.palette.secondary.light
      }
    },

    buttonSecondaryBorder: {
      padding: 0,
      paddingRight: 12,
      minWidth: 160,
      border: '2px solid ' + mikePalette.secondary.main,
      backgroundColor: mikePalette.lightGrey.main,
      color: mikePalette.secondary.main,
      '&[disabled]': {
        color: mikePalette.secondary.light,
        opacity: 0.5,
        cursor: 'not-allowed'
      }
    },
    buttonSecondaryBorderWhite: {
      padding: 0,
      paddingRight: 12,
      border: '2px solid ' + mikePalette.secondary.main,
      color: mikePalette.text.primary,
      '&[disabled]': {
        color: mikePalette.secondary.light,
        opacity: 0.5,
        cursor: 'not-allowed'
      }
    }
  })
})

export const iconButtonStyle = css`
  button {
    color: ${mikePalette.secondary.main};
    '&:disabled': {
      color: theme.palette.primary.light;
    }
  }
`

export const iconStyle = css`
  path {
    fill: ${mikePalette.secondary.main};
  }
`

export const iconPrimaryStyle = (disabled = false) => {
  const color = disabled ? mikePalette.primary.light : mikePalette.primary.main
  return css`
    path {
      fill: ${color};
    }
  `
}

export const iconSecondaryStyle = (disabled = false) => {
  const color = disabled
    ? mikePalette.secondary.light
    : mikePalette.secondary.main
  return css`
    path {
      fill: ${color};
    }
  `
}

export const iconCustomSizeStyle = (size = 32) => {
  const height = size
  return css`
    svg {
      height: ${height};
      width: ${height};
    }
  `
}
