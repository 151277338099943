import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import AppPropTypes from '../../shared/appPropTypes'
import DataTableRenders from '../DataTable/renders'
import { DEFAULT_LINKNOTIFY_TIMEOUT } from '../../shared/constants'

const useStyles = makeStyles(theme =>
  createStyles({
    snackbar: {
      backgroundColor: theme.palette.ultimate.main,
      color: theme.palette.ultimate.contrastText
    },
    link: {
      color: theme.palette.text.hint,
      cursor: 'pointer',
      marginRight: theme.spacing(1)
    }
  })
)

const LinkNotificationSnack = ({ open, onCloseCallBack, project, message }) => {
  const classes = useStyles()
  const projectContentNameLink = DataTableRenders.makeProjectNotificationLink(
    '/project'
  )
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      onClose={onCloseCallBack}
      open={open}
      autoHideDuration={DEFAULT_LINKNOTIFY_TIMEOUT}
    >
      <SnackbarContent
        className={classes.snackbar}
        message={message}
        action={
          <div className={classes.link}>
            {project && projectContentNameLink('VIEW', project)}
          </div>
        }
      />
    </Snackbar>
  )
}
LinkNotificationSnack.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseCallBack: PropTypes.func,
  message: PropTypes.string,
  project: AppPropTypes.project
}

export default LinkNotificationSnack
