import { AppIconName } from '@mike/mike-shared-frontend/mike-icon/MikeAppIcon'

const MIKEPREFIX = 'MIKE '
export const ERROR = 'Error'

export function appName(name: string): string {
  const appName = name.startsWith(MIKEPREFIX)
    ? name.slice(MIKEPREFIX.length)
    : name
  return appName
}

export function projectQueryParameter(
  appId: string,
  projectId: string,
  tenantId: string
): string {
  let queryParameter = ''
  switch (appId) {
    case 'Data Link':
      queryParameter = projectId
        ? '/map?projectID=' + projectId + '&tenantId=' + tenantId
        : '?tenantId=' + tenantId
      break
    case 'Flood Screener':
      queryParameter = ''
      break
    case 'Mesh Builder':
      queryParameter = projectId
        ? '/project/' + projectId + '/workspace-list?tenantId=' + tenantId
        : '?tenantId=' + tenantId
      break
    case 'Bathymetry Builder':
      queryParameter = '/project/' + projectId + '?tenantId=' + tenantId
      break
    case 'MIKE 3D World':
      queryParameter = projectId
        ? '/project/' + projectId + '?tenantId=' + tenantId
        : '?tenantId=' + tenantId
      break
    case 'Fast Wave Emulator':
    case 'MetOcean Simulator':
    case 'Metocean Simulator':
      queryParameter = projectId
        ? '/project/' + projectId + '?tenantId=' + tenantId
        : '?tenantId=' + tenantId
      break
    default:
      break
  }
  return queryParameter
}

export function appIconName(appName: string): AppIconName {
  let appIcon
  switch (appName) {
    case 'Data Link':
      appIcon = 'dataLink'
      break
    case 'Mesh Builder':
      appIcon = 'meshBuilder'
      break
    case 'MIKE 3D World':
      appIcon = 'mike3DWorld'
      break
    case 'Flood Screener':
      appIcon = 'floodScreener'
      break
    case 'Company Subscription (giving access to Cloud Admin)':
      appIcon = 'adminCenter'
      break
    case 'Bathymetry Builder':
      appIcon = 'bathymetryBuilder'
      break
    case 'Global Seas':
      appIcon = 'globalSeas'
      break
    case 'Fast Wave Emulator':
    case 'MetOcean Simulator':
    case 'Metocean Simulator':
      appIcon = 'MIKE Fast Wave Emulator'
      break
    default:
      break
  }
  return appIcon
}
