import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IAPIAccess from '../model/IAPIAccess'

import { StateEnum } from '../model/StateEnum'

export type APIAccessState = {
  apiAccessList: IAPIAccess[]
  state: StateEnum
}

const initState = {
  apiAccessList: [],
  state: StateEnum.INIT
} as APIAccessState

const apiAccessSlice = createSlice({
  name: 'apiAccess',
  initialState: initState as APIAccessState,
  reducers: {
    setApiAccessLoadingState: (
      state,
      action: PayloadAction<{ loadingState: boolean }>
    ): APIAccessState => {
      return {
        ...state,
        state: action.payload.loadingState
          ? StateEnum.LOADING
          : StateEnum.LOADED
      }
    },
    setAPIAccesssList: (
      state,
      action: PayloadAction<{ apiAccess: IAPIAccess[] }>
    ): APIAccessState => {
      return {
        ...state,
        apiAccessList: action.payload.apiAccess,
        state: StateEnum.LOADED
      }
    },
    updateAPIAccessItem: (
      state,
      action: PayloadAction<{ apiAccess: IAPIAccess }>
    ): APIAccessState => {
      return {
        ...state,
        apiAccessList: state.apiAccessList.map(apiAccess => {
          if (apiAccess.accountId === action.payload.apiAccess.accountId) {
            return action.payload.apiAccess
          }
          return apiAccess
        })
      }
    },
    deleteAPIAccessList: (
      state,
      action: PayloadAction<{ apiAccessList: IAPIAccess[] }>
    ): APIAccessState => {
      const ids = action.payload.apiAccessList.map(
        apiAccess => apiAccess.accountId
      )
      return {
        ...state,
        apiAccessList: state.apiAccessList.filter(
          apiAccess => !ids.includes(apiAccess.accountId)
        )
      }
    },
    addAPIAccessList: (
      state,
      action: PayloadAction<{ apiAccessList: IAPIAccess[] }>
    ): APIAccessState => {
      return {
        ...state,
        apiAccessList: state.apiAccessList.concat(action.payload.apiAccessList)
      }
    }
  }
})

export const {
  setApiAccessLoadingState,
  setAPIAccesssList,
  deleteAPIAccessList,
  addAPIAccessList,
  updateAPIAccessItem
} = apiAccessSlice.actions

export default apiAccessSlice.reducer
