import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import MikeTooltip from '@mike/mike-shared-frontend/mike-tooltip/MikeTooltip'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      paddingTop: theme.spacing(0.5),
      marginRight: theme.spacing(1)
    },
    longText: {
      maxWidth: '25vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'table-cell',
      paddingRight: 8
    },
    bold: {
      fontWeight: 'bold'
    }
  })
)

const LongName = ({ longName, icon: Icon, variant }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {Icon && (
        <div className={classes.icon}>
          <Icon />
        </div>
      )}
      <MikeTooltip title={longName}>
        <div
          className={
            (classes.longText, variant === 'bold' ? classes.bold : null)
          }
        >
          {longName}
        </div>
      </MikeTooltip>
    </div>
  )
}

LongName.propTypes = {
  icon: PropTypes.any,
  longName: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'bold'])
}

export default React.memo(LongName)
