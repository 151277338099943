export const SET_USER = 'AUTH/SET_USER'
export const setUser = user => ({ type: SET_USER, user })

export const LOGIN = 'AUTH/LOGIN'
export const login = () => ({ type: LOGIN })

export const LOGOUT = 'AUTH/LOGOUT'
export const logout = () => ({ type: LOGOUT })

export const SET_IS_AUTHENTICATED = 'AUTH/IS_AUTHENTICATED'
export const setIsAuthenticated = isAuthenticated => ({
  type: SET_IS_AUTHENTICATED,
  isAuthenticated
})
