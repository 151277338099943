export const LOAD_DATASET_WRITERS = 'DATASET_WRITERS/LOAD'
export const loadDatasetWriters = () => ({
  type: LOAD_DATASET_WRITERS
})

export const LOADING_DATASET_WRITERS = 'DATASET_WRITERS/LOADING'
export const loadingDatasetWriters = (loading = true) => ({
  type: LOADING_DATASET_WRITERS,
  loading
})

export const SET_DATASET_WRITERS = 'DATASET_WRITERS/SET'
export const setDatasetWriters = (
  datasetFileWriters,
  datasetDedicatedWriters
) => ({
  type: SET_DATASET_WRITERS,
  datasetFileWriters,
  datasetDedicatedWriters
})
