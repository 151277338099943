import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { injectIntl, defineMessages } from 'react-intl'
import size from 'lodash/fp/size'
import every from 'lodash/every'

import FormControl from '@material-ui/core/FormControl'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'

import RefreshButton from '../buttons/RefreshButton'
import RestoreButton from '../buttons/RestoreButton'
import ActionsBar from '../ActionsBar'
import AppNav from '../AppNav'
import { useTypedSelector } from '../../reducers'

import RecycleBinItemsDeletedByMeTable, {
  canRestoreItem
} from '../RecycleBinItemsDeletedByMeTable'

const styles = theme => ({
  header: {
    position: 'sticky',
    top: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    zIndex: 1,
    backgroundColor: theme.palette.lightGrey.main
  },
  actionBar: {
    marginLeft: theme.spacing(-1)
  }
})

const messages = defineMessages({
  title: {
    id: 'screens.recycleBin.title'
  }
})

const RecycleBinItemsDeletedByMe = ({
  classes,
  data,
  totalCount,
  intl,
  loading,
  onRefresh,
  onRestore,
  // onSearchTextChange,
  onRestoreOneItem,
  onChangePage,
  onChangeRowsPerPage,
  onHandleRequestSort,
  onSelectionChange,
  searchText,
  selection,
  page
}) => {
  const selectionSize = size(selection)
  const canRestore = selectionSize > 0 && every(selection, canRestoreItem)
  const tenantId = useTypedSelector(state => state.auth.user?.tenantId)

  const [topOffset, setTopOffset] = React.useState(0)
  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height + 8)
    }
  }, [])

  return (
    <>
      <AppNav tenantId={tenantId} />
      <div className={classes.header} ref={measuredRef}>
        <Typography variant={'h2'}>
          {intl.formatMessage(messages.title)}
        </Typography>
        <FormControl htmlFor="bin-search-input" className={classes.actionBar}>
          <ActionsBar gutter="top">
            <RefreshButton onClick={onRefresh} />

            <Fade in={selectionSize > 0}>
              <div>
                <RestoreButton disabled={!canRestore} onClick={onRestore} />
              </div>
            </Fade>
          </ActionsBar>
        </FormControl>
      </div>
      <RecycleBinItemsDeletedByMeTable
        content={data}
        loading={loading}
        searchText={searchText}
        selection={selection}
        totalCount={totalCount}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onHandleRequestSort={onHandleRequestSort}
        onRestoreOneItem={onRestoreOneItem}
        onSelectionChange={onSelectionChange}
        page={page}
        topOffset={topOffset}
      />
    </>
  )
}
RecycleBinItemsDeletedByMe.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  onSearchTextChange: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onHandleRequestSort: PropTypes.func.isRequired,
  onRestoreOneItem: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  selection: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number.isRequired
}

const enhance = compose(withStyles(styles), injectIntl)

export default enhance(RecycleBinItemsDeletedByMe)
