import React from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import withFormik from './withFormik'

const CheckboxField = ({ disabled, label, name, onBlur, onChange, value }) => (
  <FormControlLabel
    checked={Boolean(value)}
    control={<Checkbox />}
    disabled={disabled}
    label={label}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
  />
)
CheckboxField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any.isRequired
}

export default withFormik(CheckboxField)
