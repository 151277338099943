import produce from 'immer'

import {
  LOADING_DATASET_WRITERS,
  SET_DATASET_WRITERS
} from '../actions/datasetWriters'

export default produce(
  (draft, action) => {
    switch (action.type) {
      case LOADING_DATASET_WRITERS:
        draft.loading = action.loading
        return

      case SET_DATASET_WRITERS:
        draft.data = action.datasetFileWriters.concat(
          action.datasetDedicatedWriters
        )
        draft.dedicated = action.datasetDedicatedWriters
        draft.fileWriters = action.datasetFileWriters
        return

      default:
        return
    }
  },
  { loading: false, data: [], dedicated: [], fileWriters: [] }
)
