Object.defineProperty(exports, '__esModule', {
  value: true
})
exports.ReactComponent = void 0

var _react = _interopRequireDefault(require('react'))

function _interopRequireDefault(obj) {
  return obj && obj.__esModule
    ? obj
    : {
        default: obj
      }
}

function _extends() {
  // eslint-disable-next-line
  _extends =
    Object.assign ||
    function(target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i]

        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key]
          }
        }
      }

      return target
    }

  return _extends.apply(this, arguments)
}

const ReactComponent = props =>
  _react.default.createElement(
    'svg',
    // eslint-disable-next-line
    _extends(
      {
        width: 40,
        height: 40,
        fill: 'none'
      },
      props
    ),
    _react.default.createElement('path', {
      d:
        'M24.5858 18.7071L20.2929 14.4142L21.7071 13L28.4142 19.7071L21.7071 26.4142L20.2929 25L24.5858 20.7071H20V18.7071H24.5858Z',
      fill: '#557A8F'
    }),
    _react.default.createElement('path', {
      d: 'M12 18.7071H14V20.7071H12V18.7071Z',
      fill: '#557A8F'
    }),
    _react.default.createElement('path', {
      d: 'M18 18.7071H16V20.7071H18V18.7071Z',
      fill: '#557A8F'
    })
  )

exports.ReactComponent = ReactComponent
