import { IApplications } from '../model/Applications'

export const LOAD_APPS = 'APPLICATIONS/LOAD_APPS'
export const loadApplications = () => ({ type: LOAD_APPS })

export const LOADING_APPS = 'APPLICATIONS/LOADING_APPS'
export const loadingApps = (loading = true) => ({
  type: LOADING_APPS,
  loading
})

export const SET_APPS = 'APPLICATIONS/SET_APPS'
export const setApps = (apps: IApplications) => ({
  type: SET_APPS,
  apps
})
