/* eslint-disable react/prop-types */
import React from 'react'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'

import {
  makeStyles,
  createStyles,
  Typography,
  Dialog,
  IconButton,
  RadioProps
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import DatasetConversion from './DatasetConversion'
import defaultReader from './configuration/defaultReader'
import IDatasetReader from './model/IDatasetReader'
import IDatasetWriter from './model/IDatasetWriter'
import DatasetConversionOptions from './DatasetConversionOptions'
import conversionDialogTranslations from './translations/conversionDialogTranslations'
import conversionTranslations from './translations/conversionTranslations'
import conversionOptionsTranslations from './translations/conversionOptionsTranslations'
import { ITextResources } from './DatasetConversion'
import { ITextResources as IConversionOptionsTextResources } from './DatasetConversionOptions'
import { FILEWRITER } from './model/asIfReaderWriter'
import { adaptedFileName } from './support'
import IProjection from '../../model/IProjection'

export enum dialogMode {
  UPLOAD = 'Upload',
  CONVERSION = 'Conversion',
  DOWNLOAD = 'Download'
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    dialog: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    dialogTitle: {
      padding: theme.spacing(1),
      color: theme.palette.background.paper,
      zIndex: 11,
      borderRadius: '4px 4px 0px 0px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
      height: theme.spacing(8),
      width: '100%'
    },
    titleBar: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    titleHeadings: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    closeButton: {
      padding: 0,
      color: theme.palette.primary.main
    }
  })
})

interface IProps {
  datasetId?: string
  disableConvert?: boolean
  mode: dialogMode.UPLOAD | dialogMode.CONVERSION | dialogMode.DOWNLOAD
  datasetReaders: IDatasetReader[]
  loadingDatasetReaders: boolean
  fileName: string
  initialStep?: number
  open: boolean
  datasetWriters: IDatasetWriter[]
  dedicatedWriters: string[]
  handleClose: () => void
  handleUpload: (
    mode: dialogMode.UPLOAD | dialogMode.CONVERSION | dialogMode.DOWNLOAD,
    importValues?: any,
    datasetId?: string,
    fileName?: string
  ) => void
  projections: IProjection[]
  projectionsLoading: boolean
  onProjectionSearchTextChanged: (searchText: string) => void
  searchCoordinateSystemsById: boolean
}

const DatasetConversionDialog = (props: IProps) => {
  const classes = useStyles()
  const {
    datasetId,
    disableConvert,
    datasetReaders,
    loadingDatasetReaders,
    fileName,
    mode,
    open,
    datasetWriters,
    dedicatedWriters,
    handleClose,
    handleUpload,
    initialStep = 1,
    projections,
    projectionsLoading,
    onProjectionSearchTextChanged,
    searchCoordinateSystemsById
  } = props

  const [textResources, setTextResources] = React.useState({} as ITextResources)
  const [optionsTextResources, setOptionsTextResources] = React.useState(
    {} as IConversionOptionsTextResources
  )
  const [writersToHide, setWritersToHide] = React.useState([] as string[])
  const [name, setName] = React.useState('')
  const [reader, setReader] = React.useState('')
  const [writer, setWriter] = React.useState('')
  const [convert, setConvert] = React.useState('false')
  const [step, setStep] = React.useState(1)
  const [dialogTitle, setDialogTitle] = React.useState('')

  React.useEffect(() => {
    setName(fileName)
  }, [fileName])

  React.useEffect(() => {
    setStep(initialStep ? initialStep : 1)
    setConvert('false')
    setReader('')
    setWriter('')
    if (datasetReaders) {
      if (datasetReaders.length === 1) {
        setReader(datasetReaders[0].name)
      } else {
        const fileExtension = fileName && fileName.split('.').pop()
        if (fileExtension && fileExtension in defaultReader) {
          const readerObj = datasetReaders.find(
            el => el.id === defaultReader[fileExtension]
          )
          if (readerObj) {
            setReader(defaultReader[fileExtension])
          }
        }
      }
    }
  }, [fileName, datasetReaders, initialStep])

  React.useEffect(() => {
    switch (mode) {
      case dialogMode.UPLOAD:
        setDialogTitle(
          step === 1
            ? conversionDialogTranslations.uploadTitle
            : conversionTranslations.submitUpload
        )
        setTextResources({
          cancel: conversionTranslations.cancel,
          submit: conversionTranslations.submitUpload,
          readerDropDown: conversionTranslations.readerDropDown
        })
        setOptionsTextResources({
          submit: conversionOptionsTranslations.upload,
          title: conversionOptionsTranslations.title,
          asIfOption: conversionOptionsTranslations.uploadAsIs,
          convertToDifferentFormatOption:
            conversionOptionsTranslations.convertToDifferentFormat
        })
        setWritersToHide([])
        break

      case dialogMode.CONVERSION:
        setDialogTitle(conversionDialogTranslations.convertTitle)
        setTextResources({
          cancel: conversionTranslations.cancel,
          submit: conversionTranslations.submitConvert,
          readerDropDown: conversionTranslations.readerDropDownConvert
        })
        setWritersToHide([])
        break
      case dialogMode.DOWNLOAD:
        setDialogTitle(
          step === 1
            ? conversionDialogTranslations.downloadTitle
            : conversionTranslations.submitDownload
        )
        setTextResources({
          cancel: conversionTranslations.cancel,
          submit: conversionTranslations.submitDownload,
          readerDropDown: conversionTranslations.readerDropDownConvert
        })
        setOptionsTextResources({
          submit: conversionOptionsTranslations.download,
          title: conversionOptionsTranslations.downloadTitle,
          asIfOption: conversionOptionsTranslations.downloadAsIs,
          convertToDifferentFormatOption:
            conversionOptionsTranslations.downloadInDifferentFormat
        })
        // Dedicated writers cannot be used in download mode
        // as they write to dedicated Platform storage (GIS, TS, MD)
        // File writer + specific reader only writes coordinate system to metadata
        // But metadata is not downloadable - so file writer makes no sense
        setWritersToHide([...dedicatedWriters, FILEWRITER])
        break
      default:
        break
    }
  }, [mode, dedicatedWriters, step])

  React.useEffect(() => {
    if (datasetReaders && reader && fileName) {
      const readerObj = datasetReaders.find(el => el.id === reader)
      if (readerObj) {
        const availableWriters = readerObj.writers.filter(
          (w: string) => !writersToHide.includes(w)
        )
        const theOneAndOnly =
          availableWriters.length === 1 ? availableWriters[0] : ''
        setWriter(theOneAndOnly)
        const fileNameWithAdaptedExtension = adaptedFileName(
          fileName,
          theOneAndOnly
        )
        fileNameWithAdaptedExtension && setName(fileNameWithAdaptedExtension)
      }
    }
  }, [reader, datasetReaders, writersToHide, fileName])

  const defaultUploadValues = {
    description: '',
    name: name,
    reader: reader,
    writer: writer
  }

  React.useEffect(() => {
    setStep(initialStep)
  }, [initialStep])

  const handleOnClose = React.useCallback(() => {
    setStep(1)
    setConvert('false')
    setWritersToHide([])
    setReader('')
    setWriter('')
    handleClose()
  }, [handleClose])

  const handleOnClick = React.useCallback(() => {
    if (convert === 'false') {
      setStep(1)
      handleUpload(mode, null, datasetId, fileName)
    } else {
      if (step === 1) {
        setStep(2)
      }
    }
  }, [convert, handleUpload, step, mode, datasetId, fileName])

  const handleConvert = React.useCallback(
    values => {
      handleUpload(mode, values, datasetId, fileName)
    },
    [handleUpload, mode, datasetId, fileName]
  )

  function handleChange(event: React.ChangeEvent<unknown>) {
    if (event && event.target && (event.target as HTMLInputElement).value) {
      const option = (event.target as HTMLInputElement)
        .value as RadioProps['value']
      if (typeof option === 'string') {
        setConvert(option)
      }
    }
  }

  const DialogTitle = () => {
    return (
      <div className={classes.titleBar}>
        <div className={classes.titleHeadings}>
          <Typography variant="h3">{dialogTitle}</Typography>
        </div>
        <div>
          <IconButton
            aria-label="Close"
            onClick={handleOnClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    )
  }

  return (
    <Dialog
      className={classes.dialog}
      maxWidth="md"
      fullWidth={true}
      onClose={handleOnClose}
      open={open}
      scroll={'paper'}
    >
      <MuiDialogTitle className={classes.dialogTitle}>
        <DialogTitle />
      </MuiDialogTitle>
      {step === 1 ? (
        <DatasetConversionOptions
          disableConvert={disableConvert}
          isZippedFile={fileName.endsWith('.zip')}
          fileName={fileName}
          handleOnClose={handleOnClose}
          handleChange={handleChange}
          handleOnClick={handleOnClick}
          convert={convert}
          textResources={optionsTextResources}
        />
      ) : (
        <DatasetConversion
          datasetReaders={datasetReaders}
          datasetWriters={datasetWriters}
          mode={mode}
          showDatasetReadersLoading={loadingDatasetReaders}
          handleUpload={handleConvert}
          handleCancel={handleOnClose}
          initialValues={defaultUploadValues}
          writersToHide={writersToHide}
          textResources={textResources}
          projections={projections}
          projectionsLoading={projectionsLoading}
          onProjectionSearchTextChanged={onProjectionSearchTextChanged}
          searchCoordinateSystemsById={searchCoordinateSystemsById}
        />
      )}
    </Dialog>
  )
}

export default DatasetConversionDialog
