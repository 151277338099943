import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  fork,
  select
} from 'redux-saga/effects'

import map from 'lodash/map'
import pick from 'lodash/fp/pick'
import { capitalize } from '../helpers/projectContent'

import {
  getProjects,
  postProject,
  postSubProject,
  putProject,
  patchProject,
  delProject
} from '../apis/projects'

import { putProjectMembers } from '../apis/members'

import {
  LOAD_PROJECTS,
  loadingProjects,
  setProjects,
  CREATE_PROJECT,
  creatingProject,
  CREATE_SUBPROJECT,
  UPDATE_PROJECT,
  updatingProject,
  DELETE_PROJECTS,
  deletingProjects
} from '../actions/projects'

import { addError } from '../actions/errors'

import { notify } from '../helpers/saga'

export const getPage = state => state.projects.page
export const getRowsPerPage = state => state.projects.rowsPerPage
export const getMy = state => state.projects.my
export const getSortBy = state => state.projects.sortBy
export const getSortOrder = state => state.projects.sortOrder
export const getNamePrefix = state => state.projects.namePrefix

export default function* watchProjects() {
  yield takeEvery(CREATE_PROJECT, createProject)
  yield takeEvery(CREATE_SUBPROJECT, createSubProject)
  yield takeEvery(UPDATE_PROJECT, updateProject)
  yield takeEvery(DELETE_PROJECTS, deleteProjects)
  yield takeLatest(LOAD_PROJECTS, loadProjects)
}

export function* loadProjects(action) {
  try {
    yield put(loadingProjects(action.my, true))

    // The endpoint supports server side pagination, sorting
    // and filtering by name prefix
    const { data, totalCount, offset, limit } = yield call(
      getProjects,
      action.page,
      action.rowsPerPage,
      capitalize(action.sortBy),
      capitalize(action.sortOrder),
      action.namePrefix,
      action.my
    )

    yield put(
      setProjects({
        newData: data,
        totalCount,
        rowsPerPage: limit !== undefined ? limit : action.rowsPerPage,
        offset: offset !== undefined ? offset : 0,
        namePrefix: action.namePrefix,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder
      })
    )
  } catch (error) {
    yield put(addError(LOAD_PROJECTS, error))
  } finally {
    yield put(loadingProjects(action.my, false))
  }
}

export function* createProject({ data, projectDidCreate }) {
  try {
    yield put(creatingProject())

    const project = yield call(postProject, data)

    yield fork(notify, `Project "${project.name}" was created!`)
  } catch (error) {
    yield put(addError(CREATE_PROJECT, error))
  } finally {
    yield put(creatingProject(false))
    yield call(projectDidCreate)
  }
}

export function* createSubProject({ data, projectDidCreate }) {
  try {
    yield put(creatingProject())

    const project = yield call(postSubProject, data, data.parentProjectId)

    yield fork(notify, `Project "${project.name}" was created!`)
  } catch (error) {
    yield put(addError(CREATE_SUBPROJECT, error))
  } finally {
    yield put(creatingProject(false))
    yield call(projectDidCreate)
  }
}

export function* updateProject({ data, projectDidUpdate }) {
  const { members, accessLevel, ...projectData } = data

  const updatedMembers = map(members, pick(['userId', 'role']))

  const {
    id,
    name,
    capabilities: { canEditAccessLevel, canGrantAccess, canEdit },
    rowVersion
  } = projectData

  try {
    yield put(updatingProject())
    let versionId = rowVersion
    if (canEdit) {
      const response = yield call(putProject, projectData)
      if (response && response.rowVersion) {
        versionId = response.rowVersion
      }
    }

    if (canEditAccessLevel) {
      const response = yield call(patchProject, id, { accessLevel }, versionId)
      if (response && response.rowVersion) {
        versionId = response.rowVersion
      }
    }

    if (canGrantAccess) {
      call(putProjectMembers, id, updatedMembers, versionId)
    }

    yield fork(notify, `Project "${name}" was updated!`)
  } catch (error) {
    if (error && error.httpStatus && error.httpStatus === 409) {
      yield put(
        addError(
          UPDATE_PROJECT,
          'Somebody else was updating the project at the same time, you can refresh your page to see the latest changes.'
        )
      )
    } else {
      yield put(addError(UPDATE_PROJECT, error))
    }
  } finally {
    yield put(updatingProject(false))
    yield call(projectDidUpdate)
  }
}

export function* deleteProjects(action) {
  const { ids } = action

  const page = yield select(getPage)
  const rowsPerPage = yield select(getRowsPerPage)
  const namePrefix = yield select(getNamePrefix)
  const sortBy = yield select(getSortBy)
  const sortOrder = yield select(getSortOrder)
  const my = yield select(getMy)

  try {
    yield put(deletingProjects())

    yield all(ids.map(id => call(delProject, id)))

    yield fork(
      notify,
      ids.length > 1
        ? `${ids.length} projects were deleted!`
        : 'The project was deleted!'
    )
  } catch (error) {
    yield put(addError(DELETE_PROJECTS, error))
  } finally {
    yield put(deletingProjects(false))
    yield call(loadProjects, {
      my,
      page,
      rowsPerPage,
      sortBy,
      sortOrder,
      namePrefix
    })
    yield call(action.projectsDidDelete)
  }
}
