export const EXPORT_TYPE = 'Export'

export const FILE_DATASET_TYPE = 'file'

export const FILE_EXPORTER_ID = 'file'

export const EXPORT_STATES = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  FAILED: 'FAILED'
}

export const DOWNLOAD_TYPE = 'Download'
