import { call, put, takeEvery } from 'redux-saga/effects'
import {
  LOAD_SITE_CPU_USAGE,
  LoadSiteCpuUsageType
} from '../actions/siteCpuUsage'
import { postConsumptionMyList } from '../apis/consumptionLog'
import {
  setCpuQueryParams,
  setCpuUsageData,
  setCpuUsageLoadingState,
  setCpuUsageState,
  setTotalCount
} from '../reducers/siteCpuUsage'
import { StateEnum } from '../model/StateEnum'
import { addError } from '../actions/errors'

export default function* watchSiteCpuUsage() {
  yield takeEvery(LOAD_SITE_CPU_USAGE, fetchSiteCpuUsage)
}

export function* fetchSiteCpuUsage(action: LoadSiteCpuUsageType) {
  yield put(setCpuUsageLoadingState({ loadingState: true }))
  yield put(setCpuQueryParams(action.params))

  const params = action.params
  const result = yield call(postConsumptionMyList, {
    projectId: action.tenantId,
    metricType: 'ComputeTime',
    offset: params.page * params.rowsPerPage,
    limit: params.rowsPerPage,
    orderBy: params.orderBy === 'validFrom' ? 'ValidFrom' : params.orderBy,
    sortOrder: params.sortOrder,
    from: params.startDate,
    to: params.endDate
  })

  try {
    if (result.data.length === 0) {
      if (params.page === 0) {
        yield put(
          setCpuUsageState({
            state: StateEnum.LOADED,
            cpuUsage: [],
            totalCount: 0,
            params
          })
        )
      } else {
        yield put(setCpuUsageLoadingState({ loadingState: false }))
        yield put(
          setTotalCount({ totalCount: params.page * params.rowsPerPage })
        )
      }
    } else {
      if (result.data.length < params.rowsPerPage) {
        yield put(
          setTotalCount({
            totalCount: params.page * params.rowsPerPage + result.data.length
          })
        )
      } else if (params.page === 0) {
        yield put(setTotalCount({ totalCount: -1 }))
      }

      yield put(setCpuUsageLoadingState({ loadingState: false }))
      yield put(setCpuUsageData(result.data.slice(0, params.rowsPerPage)))
    }
  } catch (error) {
    yield put(addError(LOAD_SITE_CPU_USAGE, error))
  }
}
