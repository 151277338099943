export const LOAD_DATASET_READERS = 'DATASET_READERS/LOAD'
export const loadDatasetReaders = () => ({
  type: LOAD_DATASET_READERS
})

export const LOADING_DATASET_READERS = 'DATASET_READERS/LOADING'
export const loadingDatasetReaders = (loading = true) => ({
  type: LOADING_DATASET_READERS,
  loading
})

export const SET_DATASET_READERS = 'DATASET_READERS/SET'
export const setDatasetReaders = (
  datasetFileReaders,
  datasetDedicatedReaders
) => ({
  type: SET_DATASET_READERS,
  datasetFileReaders,
  datasetDedicatedReaders
})
