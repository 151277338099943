import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { compose, lifecycle } from 'recompose'
import { withRouter } from 'react-router-dom'
import { loadCoordinateSystems } from '../actions/coordinateSystems'
import { loadProjectContent } from '../actions/project'

import {
  getProjectContent,
  getLoadingProjectDatasets,
  getLoadingProjectSubprojects,
  getProjectPagination,
  getProjectRowsPerPage,
  getProjectContentTotalCount,
  getProjectContentSortBy,
  getProjectContentSortOrder
} from '../state'

import ProjectContentTable from '../components/ProjectContentTable'

const mapStateToProps = createSelector(
  [
    getProjectContent,
    getLoadingProjectDatasets,
    getLoadingProjectSubprojects,
    getProjectPagination,
    getProjectRowsPerPage,
    getProjectContentTotalCount,
    getProjectContentSortBy,
    getProjectContentSortOrder
  ],
  (
    projectContent,
    loadingDatasets,
    loadingSubprojects,
    page,
    rowsPerPage,
    totalCount,
    sortBy,
    sortOrder
  ) => ({
    projectContent,
    loading: loadingDatasets || loadingSubprojects,
    page,
    rowsPerPage,
    totalCount,
    sortBy,
    sortOrder
  })
)

const enhance = compose(
  withRouter,

  connect(mapStateToProps, dispatch => ({ dispatch })),

  lifecycle({
    componentDidMount() {
      this.props.dispatch(loadProjectContent(this.props.match.params.id))
      this.props.dispatch(loadCoordinateSystems())
    },
    componentDidUpdate(prevProps) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        this.props.dispatch(loadProjectContent(this.props.match.params.id))
      }
    }
  })
)

export default enhance(ProjectContentTable)
