/* eslint-disable no-console */
import {
  GET_EXPORT_INFO_DELAY,
  EXPORT_REMOVE_ON_DONE_DELAY
} from '../shared/constants'
import { call, put, takeEvery, select, delay } from 'redux-saga/effects'

import { mapUserImportDataToConvertApiBody } from '../helpers/import'

import {
  transferUploadConvert,
  TRANSFER_STATUSES,
  getTransfer
} from '../apis/transfer'

import {
  IMPORT_FILE,
  importStart,
  updateProgress,
  importFail,
  importComplete,
  removeImport
} from '../actions/imports'

import { getUser } from '../state'

const TAG = '@saga.imports'

export default function* watchImports() {
  yield takeEvery(IMPORT_FILE, importFile)
}

export function* importFile(action) {
  console.log(TAG, action.id, 'New import...', { action })
  const { id, fileName, fileUrl, projectId, data } = action
  let result
  try {
    console.log(TAG, id, 'Conversion import...')

    result = yield call(
      transferUploadConvert,
      mapUserImportDataToConvertApiBody(projectId, fileName, fileUrl, data)
    )

    console.log(TAG, id, 'Start importing...', { result })
    const title = 'Importing (' + data.name + ')'
    const user = yield select(getUser)

    const createdBy = user.email

    yield put(
      importStart(
        result.id,
        createdBy,
        projectId,
        TRANSFER_STATUSES.IN_PROGRESS,
        data,
        id
      )
    )
    console.log(TAG, id, 'Start import...', { result })

    let info
    while (true) {
      info = yield call(getTransfer, result.id)
      console.log(TAG, result.id, 'Exporting state...', { info })

      if (info && info.status) {
        if (info.status === TRANSFER_STATUSES.IN_PROGRESS) {
          yield put(updateProgress(result.id, 50, title))
        } else if (info.status === TRANSFER_STATUSES.ERROR) {
          throw new Error(info.errorMessage)
        } else if (info.status === TRANSFER_STATUSES.COMPLETED) {
          break
        }
      }

      yield delay(GET_EXPORT_INFO_DELAY)
    }

    console.log(TAG, result.id, 'Import completed!')

    yield put(importComplete(result.id))
    yield delay(EXPORT_REMOVE_ON_DONE_DELAY)
    yield put(removeImport(result.id))
  } catch (error) {
    console.error(TAG, result ? result.id : id, 'Import failed!', error)
    yield put(importFail(result ? result.id : id, error))
  }
}
