import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import { ReactComponent as Refresh } from '@mike/mike-shared-frontend/media/icons/Refresh'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import messages from '../../shared/messages'
import { iconSecondaryStyle, useStyles } from './iconStyles'

const RefreshButton = (buttonProps: MuiButtonProps) => {
  const classes = useStyles()
  return (
    <Button className={classes.button} {...buttonProps}>
      <Refresh className={iconSecondaryStyle(buttonProps.disabled)} />
      <FormattedMessage id={messages.refresh.id} />
    </Button>
  )
}

export default RefreshButton
