import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let _ai

export function initAi(instrumentationKey) {
  if (instrumentationKey) {
    _ai = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAutoRouteTracking: true
      }
    })
    _ai.loadAppInsights()
  } else {
    _ai = {
      setAuthenticatedUserContext: () => {}
    }
  }
}

export function instance() {
  return _ai
}
