import { put, takeEvery, takeLatest, select, all } from 'redux-saga/effects'

import { getImport, getConvert, getProjectDetails } from '../state'

import { removeUpload } from '../actions/uploads'
import { removeExport } from '../actions/exports'
import { removeImport } from '../actions/imports'
import { IMPORT_COMPLETE } from '../actions/imports'
import { CONVERT_COMPLETE } from '../actions/converts'

import { loadProjectDatasets } from '../actions/project'

import { notify, linkNotify } from '../helpers/saga'

import { DISMISS_PROGRESS_ITEM, NOTIFY } from '../actions/ui'

export default function* watchUi() {
  yield takeEvery(NOTIFY, handleNotify)
  yield takeEvery(NOTIFY, handleLinkToProject)
  yield takeEvery(IMPORT_COMPLETE, handleImportComplete)
  yield takeLatest(DISMISS_PROGRESS_ITEM, handleDismissProgressItem)
  yield takeEvery(CONVERT_COMPLETE, handleConvertComplete)
}

/* export function* showProgressSnack() {
  yield put(openProgressSnack(true))
} */

export function* handleNotify(action) {
  yield notify(action.message)
}

export function* handleLinkToProject(action) {
  yield linkNotify(action.message, action.project)
}

export function* handleImportComplete(action) {
  const completedImport = yield select(getImport, action.id)
  const currentProjectDetails = yield select(getProjectDetails)

  // FIXME: before refreshing the project details of company storage table we need to check if the user is still in the page
  if (
    currentProjectDetails &&
    completedImport.projectId === currentProjectDetails.id
  ) {
    yield put(loadProjectDatasets(completedImport.projectId))
  }
}

export function* handleConvertComplete(action) {
  const completedConvert = yield select(getConvert, action.id)
  const currentProjectDetails = yield select(getProjectDetails)

  // FIXME: before refreshing the project details of company storage table we need to check if the user is still in the page
  if (
    currentProjectDetails &&
    completedConvert.projectId === currentProjectDetails.id
  ) {
    yield put(loadProjectDatasets(completedConvert.projectId))
  }
}

export function* handleDismissProgressItem({ id }) {
  yield all([
    put(removeImport(id)),
    put(removeExport(id)),
    put(removeUpload(id))
  ])
}
