import { put, takeEvery, take, all } from 'redux-saga/effects'
import {
  UPLOAD_AND_IMPORT_FILE,
  UPLOAD_AND_IMPORT_MANY_FILES,
  uploadAndImportFile as uploadAndImportFileAction
} from '../actions/uploadAndImport'
import { UPLOAD_COMPLETE, UPLOAD_FAIL, uploadFile } from '../actions/uploads'
import { importFile } from '../actions/imports'
import { addError } from '../actions/errors'
import asIfReaderWriter from '../components/DatasetConversionDialog/model/asIfReaderWriter'

const LOG_TAG = 'saga.uploadAndImport'

export default function* watchUploadAndImport() {
  yield takeEvery(UPLOAD_AND_IMPORT_FILE, uploadAndImportFile)
}

export function* watchUploadAndImportMany() {
  yield takeEvery(UPLOAD_AND_IMPORT_MANY_FILES, uploadAndImportManyFiles)
}

export function* uploadAndImportManyFiles(action) {
  const { projectId, files } = action
  try {
    yield all(
      files.map(file =>
        put(
          uploadAndImportFileAction(projectId, {
            file: file,
            importData: {
              name: file.name,
              reader: asIfReaderWriter.reader,
              writer: asIfReaderWriter.writer
            }
          })
        )
      )
    )
  } catch (error) {
    yield put(addError(UPLOAD_AND_IMPORT_MANY_FILES, error))
  }
}

export function* uploadAndImportFile(action) {
  // eslint-disable-next-line no-console
  console.log(LOG_TAG, action.id, 'New upload and import...', { action })

  const { id, projectId, data } = action
  const { file } = data
  const importData = {
    ...data.importData,
    fileName: file.name
  }

  try {
    yield put(uploadFile(id, data.importData.name, file))

    while (true) {
      const uploadAction = yield take([UPLOAD_COMPLETE, UPLOAD_FAIL])

      if (uploadAction.id === id) {
        if (uploadAction.type === UPLOAD_COMPLETE) {
          yield put(
            importFile(
              id,
              file.name,
              uploadAction.fileUrl,
              projectId,
              importData
            )
          )
        }

        break
      }
    }
  } catch (error) {
    yield put(addError(UPLOAD_AND_IMPORT_FILE, error))
  }
}
