import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MikeTooltip from '@mike/mike-shared-frontend/mike-tooltip/MikeTooltip'

const useStyles = makeStyles({
  longText: {
    maxWidth: '25vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'table-cell',
    paddingRight: 8
  }
})

const LongText = ({ longText }) => {
  const classes = useStyles()

  return (
    <MikeTooltip title={longText}>
      <div className={classes.longText}>{longText}</div>
    </MikeTooltip>
  )
}

LongText.propTypes = {
  longText: PropTypes.string
}

export default React.memo(LongText)
