import { ReportTypeEnum } from './enums'
import { REPORT_IMAGE_MAP } from './consts'

export const getReportRequestBody = (
  tenantId: string,
  reportType: ReportTypeEnum,
  accessToken: string
): Record<string, unknown> => ({
  Runtime: {
    type: 'ContainerRuntimeSpec',
    Containers: [
      {
        Image: REPORT_IMAGE_MAP.get(ReportTypeEnum.CREDITS),
        Command: [
          '/bin/sh',
          '-c',
          'cd /work; dotnet /app/DHI.WaterData.IAM.Jobs.dll report --tenantId $tenantId --reportType $reportType --token $token --environment $environment > ./credit-report.csv;sleep 500'
        ],
        EnvironmentVariables: {
          environment: 'Dev',
          token: accessToken,
          tenantId,
          reportType
        }
      }
    ]
  },
  OutputData: {
    Locations: [
      {
        type: 'PlatformOutputLocation',
        relativePlatformPath: 'report',
        LocalPath: ''
      }
    ]
  },
  RetryLimit: '0'
})
