export const mockDedicatedWriters = [
  {
    name: 'GISWriter',
    description: 'Writes into GIS storage',
    datasetFormat: 'GIS',
    parameters: []
  },
  {
    name: 'TSWriter',
    description: 'Writes into timeseries storage',
    datasetFormat: 'TS',
    parameters: [
      {
        name: 'TimeSeriesProperties',
        description:
          'JSON serialized array of props (name,type = Double,Text,Booelan,Dateime)',
        dataType: 'JSON',
        required: false
      }
    ]
  },
  {
    name: 'MDWriter',
    description: 'Writes into multidimensional storage',
    datasetFormat: 'MD',
    parameters: [
      {
        name: 'UseSpatialIndex',
        dataType: 'boolean',
        required: true
      }
    ]
  }
]
