/* eslint-disable no-console */
import { END } from 'redux-saga'
import { delay, call, put, takeEvery, take, fork } from 'redux-saga/effects'
import {
  StorageURL,
  BlockBlobURL,
  Aborter,
  uploadBrowserDataToBlockBlob,
  AnonymousCredential
} from '@azure/storage-blob'

import { createEventChannelCallback } from '../helpers/saga'
import { UPLOAD_REMOVE_ON_DONE_DELAY } from '../shared/constants'
import { getUploadUrl } from '../apis/blobStorage'

import {
  UPLOAD_FILE,
  uploadStart,
  updateProgress,
  uploadComplete,
  uploadFail,
  removeUpload
} from '../actions/uploads'

const LOG_TAG = 'saga.uploads'

export default function* watchUploads() {
  yield takeEvery(UPLOAD_FILE, uploadFile)
}

export function* uploadFile(action) {
  console.log(LOG_TAG, action.id, 'New upload...', { action })

  const { id, file } = action

  try {
    yield put(uploadStart(id))

    const uploadUrl = yield call(getUploadUrl)
    yield call(uploadToAzureStorage, id, file, uploadUrl)

    yield put(uploadComplete(id, uploadUrl))
    yield delay(UPLOAD_REMOVE_ON_DONE_DELAY)
    yield put(removeUpload(id))
  } catch (error) {
    console.error(LOG_TAG, id, 'Upload failed!', error)
    yield put(uploadFail(id, error))
  }
}

const pipeline = StorageURL.newPipeline(new AnonymousCredential())

export function* uploadToAzureStorage(id, file, uploadUrl) {
  console.log(LOG_TAG, id, 'Start upload', {
    id,
    file,
    uploadUrl
  })

  const blockBlobURL = new BlockBlobURL(uploadUrl, pipeline)

  const [channel, progressHandler] = yield call(
    createEventChannelCallback,
    createEnhancedProgressCallback(file.size)
  )

  yield fork(uploadBrowserDataToBlockBlob, Aborter.None, file, blockBlobURL, {
    progress: progressHandler
  })

  try {
    while (true) {
      const loadedBytes = yield take(channel)
      const progress = Number(((loadedBytes / file.size) * 100).toFixed(1))

      console.log(LOG_TAG, id, 'Uploading...', progress + '%')
      yield put(updateProgress(id, progress, file.name))
    }
  } finally {
    console.log(LOG_TAG, id, 'Upload completed!') // NOTE: finally needed by the channel api
  }
}

export const createEnhancedProgressCallback = fileSize => (
  emit,
  { loadedBytes }
) => emit(fileSize - loadedBytes <= 0 ? END : loadedBytes)
